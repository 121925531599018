import React, { useState } from "react";
import authRequests from "../../requests/auth";
import "./styles/forgot-password.css";
import AccountSignUp from "../Account/AccountSignUp";

const ForgotPassword = (props) => {
    const { setIsSignUp, isSignUp, forgetPassword, setForgetPassword } = props;
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [errorColor, setErrorColor] = useState("red");

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setMessage(null);

        try {
            const customerLoginGoogle = await authRequests.forgotPassword({ email });
            if (customerLoginGoogle.status === 200) {
                setErrorColor("green");
                setMessage("Password reset link sent to your email");
            } else {
                setError("Something went wrong");
            }
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
    };

    return (
        <div className="forgot-form-container">
            {/* {isSignUp && (
                <AccountSignUp/>
            )} */}
            <div className="button-group">
                <button
                    type="button"
                    onClick={() => {
                        setForgetPassword(false);
                        setIsSignUp(true);
                    }}
                    className={`signup-button ${isSignUp ? "active" : ""}`}
                >
                    Sign Up
                </button>
                <button
                    type="button"
                    onClick={() => {
                        setForgetPassword(false);
                        setIsSignUp(false);
                    }}
                    className={`login-button ${forgetPassword ? "" : !isSignUp ? "active" : ""}`}
                >
                    Login
                </button>
            </div>
            <form onSubmit={handleSubmit} className="form">
                <p className="forgot-text">
                    Lost your password? Please enter your username or email address. You will receive a link to create a
                    new password via email.
                </p>
                <input
                    className="auth-input-feild forgot-input"
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    placeholder="Username / Email"
                />
                <button className="auth-button" type="submit" disabled={loading}>
                    {loading ? "Processing..." : "Email Reset Link"}
                </button>
                {message && <p style={{ color: errorColor }}>{message}</p>}
                {error && <p style={{ color: errorColor }}>{error}</p>}
            </form>
        </div>
    );
};

export default ForgotPassword;
