/**
 *  Account Profile
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Common from "../../api/common";
import Sitebar from "./Sitebar";
import { Outlet } from "react-router-dom";

class AccountProfile extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            // className="bg-white"
            <div >
                {/* <div className="inner-intro">
                    <Container>
                        <Row className="intro-title align-items-center">
                            <Col md={6} className="text-left">
                                <div className="intro-title-inner">
                                    <h1>My Account</h1>
                                </div>
                            </Col>
                            <Col md={6} className="text-right">
                                <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                    <li className="home">
                                        <span>
                                            <Link className="bread-link bread-home" to="/">
                                                Home
                                            </Link>
                                        </span>
                                    </li>
                                    <li>
                                        <span>My Account</span>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
                <Outlet />
            </div>
        );
    }
}
export default AccountProfile;
