import ajax from './ajax';
import { API_BASE_URL } from '../config';

const fieldsRequests = {};

fieldsRequests.getProductFields = async function (productId) {
    const url = `${API_BASE_URL}/fields/GetProductFields/${productId}`;

    const productFields = await ajax.get(url);
    return productFields;
}


fieldsRequests.saveAutocompleteValue = async function ({ autocompleteLabel, value, referenceValue = '' }) {
    const url = `${API_BASE_URL}/fields/SaveAutocompleteValue`;
    
    const valueSaved = await ajax.post(
        url,
        {
            autocompleteLabel,
            value,
            referenceValue,
        });
    return valueSaved;
}
    


// get suggestions while user is writing in searchbar
// save the timeout ID to improve the ajax requests performance
let timeout1, timeout2; 

fieldsRequests.getSuggestionsWithAssociatedValue = function(autocompleteLabel, text) {
    return new Promise((resolve, reject) => {
        clearTimeout(timeout1);
        
        const url = `${API_BASE_URL}/fields/GetSuggestionsWithAssociatedValue?autocompleteLabel=${autocompleteLabel}&text=${text}`;
        
        timeout1 = setTimeout(() => {
            ajax.get(url)
            .then(resolve)
            .catch( err => reject(err.message));
        }, 350);
    });
}


fieldsRequests.getSuggestions = async function (autocompleteLabel, text) {
    return new Promise((resolve, reject) => {
        clearTimeout(timeout2);
        const url = `${API_BASE_URL}/fields/GetSuggestions?autocompleteLabel=${autocompleteLabel}&text=${text}`;

        timeout2 = setTimeout(() => {
            ajax.get(url)
                .then(resolve)
                .catch( err => reject(err.message));
        }, 350);
    });
}

export default fieldsRequests;