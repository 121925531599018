import React from "react";

const FooterLogo = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="33" viewBox="0 0 269.348 33.886">
                <g id="Artwork_14" data-name="Artwork 14" transform="translate(102.674 86.943)">
                    <g id="Group_10744" data-name="Group 10744" transform="translate(-61.11 -86.943)">
                        <path
                            id="Path_10215"
                            data-name="Path 10215"
                            d="M64.824,0V11.081a8.375,8.375,0,0,1,6.518-2.966,9.965,9.965,0,0,1,7.5,2.99,10.7,10.7,0,0,1,2.888,7.759V33.32H75.711V19.232a5.452,5.452,0,0,0-1.449-3.945A5.119,5.119,0,0,0,70.4,13.781a5.428,5.428,0,0,0-3.964,1.593,5.225,5.225,0,0,0-1.614,3.858V33.32H58.809V0Z"
                            transform="translate(-58.809)"
                            fill="#fff"
                        ></path>
                        <path
                            id="Path_10216"
                            data-name="Path 10216"
                            d="M114.778,12.018H120.8V36.678h-6.017V34.063a9.135,9.135,0,0,1-7.467,3.165,12.39,12.39,0,0,1-8.895-3.714,12.577,12.577,0,0,1-2.734-4.165,13.564,13.564,0,0,1,0-10,12.82,12.82,0,0,1,2.734-4.188,12.494,12.494,0,0,1,8.895-3.692,9.136,9.136,0,0,1,7.467,3.166Zm-7,5.121a6.756,6.756,0,0,0-5.029,2.033,7.07,7.07,0,0,0-2,5.176,7.072,7.072,0,0,0,2,5.176,7.212,7.212,0,0,0,10.037,0,7.079,7.079,0,0,0,2-5.176,7.078,7.078,0,0,0-2-5.176A6.731,6.731,0,0,0,107.773,17.139Z"
                            transform="translate(-69.341 -3.359)"
                            fill="#fff"
                        ></path>
                        <path
                            id="Path_10217"
                            data-name="Path 10217"
                            d="M136.457,36.913V31.239L147.9,17.92H136.918V12.252h18.891v5.67L144.385,31.239h6.21a5.671,5.671,0,0,1,5.67,5.671h0Z"
                            transform="translate(-81.578 -3.593)"
                            fill="#fff"
                        ></path>
                        <path
                            id="Path_10218"
                            data-name="Path 10218"
                            d="M175.569,12.021v2.418a8.406,8.406,0,0,1,6.589-2.966,8.974,8.974,0,0,1,7.513,3.517,10,10,0,0,1,3.414-2.605,9.827,9.827,0,0,1,4.183-.912,10.1,10.1,0,0,1,7.567,3.033,10.607,10.607,0,0,1,2.954,7.714V36.682H201.77V22.594a5.654,5.654,0,0,0-1.362-3.945,4.649,4.649,0,0,0-3.624-1.506,4.751,4.751,0,0,0-3.624,1.582,5.472,5.472,0,0,0-1.471,3.867v14.09h-6.015V22.594a5.632,5.632,0,0,0-1.373-3.945,4.682,4.682,0,0,0-3.634-1.506,4.75,4.75,0,0,0-3.624,1.582,5.468,5.468,0,0,0-1.471,3.867v14.09h-6.018V12.021Z"
                            transform="translate(-91.283 -3.362)"
                            fill="#fff"
                        ></path>
                        <path
                            id="Path_10219"
                            data-name="Path 10219"
                            d="M252.907,24.354a13.124,13.124,0,0,1-.966,5.022,12.166,12.166,0,0,1-2.789,4.142,12.554,12.554,0,0,1-4.217,2.762,13.935,13.935,0,0,1-10.146,0,12.554,12.554,0,0,1-4.217-2.762,12.154,12.154,0,0,1-2.79-4.142,13.534,13.534,0,0,1,0-10.045,12.389,12.389,0,0,1,2.79-4.165,12.718,12.718,0,0,1,4.217-2.737,13.935,13.935,0,0,1,10.146,0,12.718,12.718,0,0,1,4.217,2.737,12.4,12.4,0,0,1,2.789,4.165A13.148,13.148,0,0,1,252.907,24.354Zm-13.045-7.209a6.768,6.768,0,0,0-5.018,2.033,7.054,7.054,0,0,0-2.005,5.176,7.055,7.055,0,0,0,2.01,5.176,7.21,7.21,0,0,0,10.035,0,7.052,7.052,0,0,0,2.009-5.176,7.05,7.05,0,0,0-2.009-5.176A6.768,6.768,0,0,0,239.862,17.145Z"
                            transform="translate(-108.074 -3.365)"
                            fill="#fff"
                        ></path>
                        <path
                            id="Path_10220"
                            data-name="Path 10220"
                            d="M293.644,24.354a13.139,13.139,0,0,1-.967,5.022,12.164,12.164,0,0,1-2.788,4.142,12.554,12.554,0,0,1-4.217,2.762,13.935,13.935,0,0,1-10.146,0,12.556,12.556,0,0,1-4.217-2.762,12.142,12.142,0,0,1-2.789-4.142,13.535,13.535,0,0,1,0-10.045,12.388,12.388,0,0,1,2.789-4.165,12.721,12.721,0,0,1,4.217-2.737,13.935,13.935,0,0,1,10.146,0,12.725,12.725,0,0,1,4.217,2.737,12.4,12.4,0,0,1,2.788,4.165A13.136,13.136,0,0,1,293.644,24.354ZM280.6,17.145a6.768,6.768,0,0,0-5.018,2.033,7.053,7.053,0,0,0-2.009,5.176,7.054,7.054,0,0,0,2.009,5.176,7.211,7.211,0,0,0,10.036,0,7.054,7.054,0,0,0,2.009-5.176,7.053,7.053,0,0,0-2.009-5.176,6.768,6.768,0,0,0-5.017-2.033Z"
                            transform="translate(-120.02 -3.365)"
                            fill="#fff"
                        ></path>
                        <path
                            id="Path_10221"
                            data-name="Path 10221"
                            d="M314.308,12.022v2.418a8.38,8.38,0,0,1,6.518-2.966,9.966,9.966,0,0,1,7.5,2.99,10.7,10.7,0,0,1,2.888,7.759v14.46H325.2V22.594a5.454,5.454,0,0,0-1.448-3.945,5.125,5.125,0,0,0-3.867-1.506,5.43,5.43,0,0,0-3.964,1.593,5.222,5.222,0,0,0-1.614,3.858V36.682h-6.017V12.022Z"
                            transform="translate(-131.964 -3.362)"
                            fill="#fff"
                        ></path>
                        <path
                            id="Path_10222"
                            data-name="Path 10222"
                            d="M364.266,12.018h6.017V36.678h-6.017V34.063a9.135,9.135,0,0,1-7.467,3.165,12.392,12.392,0,0,1-8.895-3.714,12.593,12.593,0,0,1-2.734-4.165,13.564,13.564,0,0,1,0-10,12.856,12.856,0,0,1,2.734-4.188,12.5,12.5,0,0,1,8.895-3.692,9.137,9.137,0,0,1,7.467,3.166Zm-7.006,5.121a6.76,6.76,0,0,0-5.029,2.033,7.072,7.072,0,0,0-2,5.176,7.07,7.07,0,0,0,2,5.176,7.211,7.211,0,0,0,10.037,0,7.078,7.078,0,0,0,2-5.176,7.079,7.079,0,0,0-2-5.176,6.738,6.738,0,0,0-5.007-2.032Z"
                            transform="translate(-142.499 -3.359)"
                            fill="#fff"
                        ></path>
                    </g>
                    <g id="Group_11084" data-name="Group 11084" transform="translate(-102.674 -86.701)">
                        <path
                            id="Path_10780"
                            data-name="Path 10780"
                            d="M20.531,10.916,16.995,8.252,16.652,8l-.343-.255L14.79,6.6,12.327,4.741,10.643,3.477A9.175,9.175,0,0,0,5.978,6.159l0,0-.059.059-.02.02-.025.023-.059.059,0,0a9.175,9.175,0,0,0-2.682,4.665L4.4,12.67l1.857,2.463L7.4,16.651l.254.343.255.343,2.664,3.536c-.617,1.675-.622,3.081.152,3.854l1.645,1.646c.01-2.641,2.128-6.147,4.812-8.855,2.708-2.681,6.209-4.795,8.847-4.8l-1.645-1.645C23.611,10.294,22.205,10.3,20.531,10.916Z"
                            transform="translate(-0.919 -1.261)"
                            fill="#af40f7"
                        ></path>
                        <path
                            id="Path_10781"
                            data-name="Path 10781"
                            d="M22.345,22.526l0,0,0,0c-.025.025-.047.05-.071.074s-.054.051-.08.077l0,0,0,0c-3.547,3.616-5.255,7.721-3.985,9.483a1.355,1.355,0,0,0,.191.221c1.5,1.513,5.407.1,9-3.154l.082-.081L38.959,39.13,28.846,27.782l.049-.049c3.252-3.595,4.667-7.5,3.154-9a1.365,1.365,0,0,0-.221-.192C30.067,17.271,25.96,18.979,22.345,22.526Z"
                            transform="translate(-5.214 -5.556)"
                            fill="#af40f7"
                        ></path>
                        <path
                            id="Path_10782"
                            data-name="Path 10782"
                            d="M9.655,1.676,8.6.682C7.593-.269,5.015.843,2.786,3.157.493,5.378-.606,7.94.34,8.944L1.334,10A11.459,11.459,0,0,1,4.4,4.738,11.464,11.464,0,0,1,9.655,1.676Z"
                            transform="translate(0 -0.342)"
                            fill="#af40f7"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default FooterLogo;
