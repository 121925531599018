import React, { useState, useEffect } from "react";
import { fabric } from "fabric";
import { toast } from "react-toastify";
import { WP_BASE_URL } from "../../config";
// custom components
import IconLoading from "../../widgets/IconLoading";
import Tooltip from "../ToolTips/uncontrolledTooltip";
// utils
import {
    assingObjectsToCanvas,
    setCustomFontsWhenLoaded,
    setCanvasAtResoution,
    forceRenderUpdate,
} from "../../utils/canvas";
import { getUrlQuery } from "../../utils/url";
import postToParent from "../../utils/postToParent";
// custom hooks
import { useWishlist } from "../../context/wishlistContext";
// requests
import wishlistRequests from "../../requests/wishlist";
// style
import "./styles/wishlistItem.css";
import { isCustomerLogin } from "../../utils/common";
import { useLocation } from "react-router-dom";

const WishListItem = ({ product }) => {
    const [isRemoving, setIsRemoving] = useState(false);

    const { removeFromWishlist } = useWishlist();
    const [userWPId, setUserWPId] = useState(false);

    const { pathname } = useLocation();

    const productLinkUrl = () => {
        const wpLink = `${WP_BASE_URL}/design-your-card/?id=${product.wooProductId}`;
        // vercel link in case we are testing on vercel
        const vercelLink = `/card/${product._id}`;

        return /(vercel\.app|localhost)/.test(window.location) ? vercelLink : wpLink;
    };

    const CANVAS_WIDTH = 220;

    useEffect(() => {
        const isCustomer = isCustomerLogin();
        // const urlQuery = getUrlQuery(); wordpress customer id
        setUserWPId(isCustomer);
    }, [pathname]);

    useEffect(() => {
        if (product.thumbnailWithPlaceholders || !product.fields?.length) {
            return;
        }
        const canvas = new fabric.Canvas("canvas" + product._id, {
            defaultCursor: "pointer",
            selection: false,
        });

        async function resizeAnsSetTextOnCanvas() {
            const { fields } = product;

            const imageDimensions = {
                width: fields[0].originalImageWidth,
                height: fields[0].originalImageHeight,
            };

            await assingObjectsToCanvas(canvas, fields);
            setCanvasAtResoution(canvas, CANVAS_WIDTH, fields[0].canvasWidth, imageDimensions);
            return true;
        }

        resizeAnsSetTextOnCanvas().then((resized) => {
            if (resized) {
                setCustomFontsWhenLoaded(canvas).then(() => {
                    forceRenderUpdate(canvas);
                });
            }
        });
    }, [product.thumbnailWithPlaceholders, product.fields?.length]);

    const handleRemoveFromWishlist = async () => {
        if (isRemoving) return;
        setIsRemoving(true);

        const deleted = await wishlistRequests.deleteWishlistItem({
            user_id: userWPId || "666049e0d0fb1535560f4da4",
            woo_product_id: product.wooProductId,
        });
        if (deleted) {
            if (deleted.status == "success") {
                removeFromWishlist(product);
                
                postToParent({ type: "modify-wishlist-count-ui", wishlistCountData: -1 });
                toast(product.name + " removed from Wishlist", { type: "success", autoClose: 2000 });
            }
        }
        setIsRemoving(false);
    };

    return (
        <div className="wishlist-item-wrapper">
            <div className="wishlist-item-up">
                <a href={productLinkUrl()} target="_parent">
                    <div className="wishlist-thumbnail">
                        <img
                            src={product.thumbnailWithPlaceholders || product.filesId.thumbnail}
                            alt="thumbnail"
                            style={{ width: CANVAS_WIDTH }}
                        />
                        {!product.thumbnailWithPlaceholders && <canvas id={"canvas" + product._id} />}
                    </div>
                </a>
            </div>
            <div className="wishlist-item-down">
                <div className="product-nameprice">
                    <div className="product-name">{product.name}</div>
                    <div className="product-price">${product.price}</div>
                </div>

                <div className="wishlist-item-buttons">
                    <Tooltip target={`tooltip-remove-${product._id}`} text="Remove Item" />
                    <div
                        onClick={handleRemoveFromWishlist}
                        className="wishlist-remove-btn"
                        id={`tooltip-remove-${product._id}`}
                    >
                        {isRemoving ? <IconLoading /> : <i className="fa fa-trash" aria-hidden="true"></i>}
                    </div>

                    <Tooltip target={`tooltip-edit-${product._id}`} text="Edit" />
                    <a
                        href={productLinkUrl()}
                        target="_parent"
                        className="wish-edit-btn"
                        id={`tooltip-edit-${product._id}`}
                    >
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default WishListItem;
