/**
 *  Account Page Site bar
 */
import React, { Component, Fragment } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Col, Nav, NavItem } from "reactstrap";

class Sitebar extends Component {
    logOut = () => {
        localStorage.removeItem("token-jwt");
        const navigate = useNavigate();
        navigate("/login"); // Replace '/some-page' with the path you want to navigate to
    };
    constructor(props) {
        super(props);
        this.state = {
            Ord_Hist: false,
            Prof_act: false,
            Add_act: false,
            Saved_card: false,
        };
    }

    SetActiveClass() {
        this.state = {
            Ord_Hist: false,
            Prof_act: false,
            Add_act: false,
            Saved_card: false,
        };

        let pathname = document.location.href;
        let pathArray = pathname.split("/");
        let pageName = pathArray[pathArray.length - 1];
        if (pageName.toLowerCase() == "orderhistory") {
            this.state.Ord_Hist = true;
        } else if (
            pageName.toLowerCase() == "accountprofile" ||
            pageName.toLowerCase() == "accountprofileedit" ||
            pageName.toLowerCase() == "edit-profile"
        ) {
            this.state.Prof_act = true;
        } else if (pageName.toLowerCase() == "address" || pageName.toLowerCase() == "addressedit") {
            this.state.Add_act = true;
        } else if (pageName.toLowerCase() == "savedcards" || pageName.toLowerCase() == "savedcardsadd") {
            this.state.Saved_card = true;
        }
        return true;
    }

    render() {
        return (
            <Fragment>
                {this.SetActiveClass() ? (
                    <Col className="mt-4" lg={4} style={{padding:"0px 30px"}}>
                        <div className="woocommerce-MyAccount-navigation">
                            <Nav className="flex-column">
                                <NavItem>
                                    <Link
                                        to="/Account/OrderHistory"
                                        className={`${
                                            this.state.Ord_Hist == true ? "active" : ""
                                        } nav-link nav-custo OrderHistory`}
                                    >
                                        {" "}
                                        {/* <i className="fa fa-tachometer"></i> */}
                                        Order{" "}
                                    </Link>
                                </NavItem>
                                {/* <NavItem>
                                    <Link
                                        to="/Account/SavedCards"
                                        className={`${
                                            this.state.Saved_card == true ? "active" : ""
                                        } nav-link nav-custo Address`}
                                    >
                                        {" "}
                                        Payment methods{" "}
                                    </Link>
                                </NavItem> */}
                                <NavItem>
                                    <Link
                                        to="/Account/edit-profile"
                                        className={`${
                                            this.state.Prof_act == true ? "active" : ""
                                        } nav-link nav-custo AccountProfile`}
                                    >
                                        {" "}
                                        {/* <i className="fa fa-user-o"></i> */}
                                        Account details{" "}
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        onClick={() => {
                                            this.logOut();
                                            ("");
                                        }}
                                        className={` nav-link  nav-custo SavedCards`}
                                        // ${
                                        //     this.state.Saved_card == true ? "active" : ""
                                        // }
                                    >
                                        {" "}
                                        Logout
                                    </Link>
                                </NavItem>
                                {/* <NavItem>
                                    <Link
                                        to="/Account/Address"
                                        className={`${
                                            this.state.Add_act == true ? "active" : ""
                                        } nav-link nav-custo Address`}
                                    >
                                        {" "}
                                         Address{" "}
                                    </Link>
                                </NavItem> */}

                                {/* <NavItem>
                                    <Link
                                        to="/Account/SavedCards"
                                        className={`${
                                            this.state.Saved_card == true ? "active" : ""
                                        } nav-link  nav-custo SavedCards`}
                                    >
                                        {" "}
                                         Saved Cards{" "}
                                    </Link>
                                </NavItem> */}
                            </Nav>
                        </div>
                    </Col>
                ) : (
                    <div></div>
                )}
            </Fragment>
        );
    }
}
export default Sitebar;
