import React, { useEffect } from "react";
import { Switch, Route, Routes, useLocation } from "react-router-dom";
import { injectCssOverride } from "./utils/injectCssOverride";
//Component
import HomePage from "./component/home/index";

import "./assets/css/sweetalert.css";
import "./App.css";
import "./Vendor.js";

import ShopPage from "./component/shop";
import ProductDetail from "./component/shop/product-detail";
import WishList from "./component/WishList/WishList";
import Downloads from "./component/Downloads/downloads";
import EditPurchase from "./component/Edit/editPurchase";
import PageNotFound from "./component/Pages/PageNotFound";
import SignUp from "./component/Account/AccountSignUp";

import CustomersLayout from "./component/customers/CustomersLayout.jsx";

import Account from "./component/Account/AccountProfile";
import Address from "./component/Account/Address";
import OrderHistory from "./component/Account/OrderHistory";

import SavedCards from "./component/Account/SavedCards";
import Profile from "./component/Account/Profile";

// import DownloadsTest from './component/Downloads/downloads-test';
// import ProductDetailTest from './component/shop/product-detail-test';

import "font-awesome/css/font-awesome.min.css";
import ResetPassword from "./component/common/ResetPassword.jsx";

import PrivateRoute from "./component/common/PrivateRoute";
import AccountProfileedit from "./component/Account/AccountProfileedit.js";
import CheckOut from "./component/Checkout/checkout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "../src/assets/css/global.css";
import TermsAndConditions from "./component/TermsAndConditions/Terms&Conditions.jsx";
import "./hazmoona.css"

const App = () => {
    const location = useLocation();

    // inject external css
    // useEffect(() => {
    //     injectCssOverride();
    // }, [location]);

    return (
        <GoogleOAuthProvider clientId="611268622699-28354ljs18rs5b81230qbf0l4rj4srh7.apps.googleusercontent.com">
            <div className="site-content" id="site-content">
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/account-signup" element={<SignUp />} />
                    <Route path="/login" element={<SignUp />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route
                        path="/account"
                        element={
                            <PrivateRoute>
                                <Profile />
                            </PrivateRoute>
                        }
                    >
                        <Route path="address" element={<Address />} />
                        <Route path="orderhistory" element={<OrderHistory />} />
                        <Route index path="accountprofile" element={<Account />} />
                        <Route path="profile" element={<Account />} />
                        <Route index path="edit-profile" element={<AccountProfileedit />} />

                        {/* <Route path="savedcards" element={<SavedCards />} /> */}
                    </Route>
                        <Route path="/terms-conditions" element={<TermsAndConditions />} />
                    <Route path="/categories/:categoryFriendlyName" element={<ShopPage />} />
                    <Route path="/card/:productId" element={<ProductDetail />} />
                    * <Route path="/wishlist" element={<WishList />} />
                    <Route path="/checkout" element={<CheckOut />} />
                    <Route path="/download-files" element={<Downloads />} />
                    <Route path="/personalize-check" element={<ProductDetail />} />
                    <Route path="/edit-purchase" element={<EditPurchase />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </div>
        </GoogleOAuthProvider>
    );
};

export default App;
