import React from "react";

const ScissorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 55.169 83.624">
            <g id="Component_21_1" data-name="Component 21 – 1" transform="translate(0.278 0.25)">
                <path
                    id="Path_10440"
                    data-name="Path 10440"
                    d="M1096.6,248.689a8.843,8.843,0,0,1-2.875,1.854c-5.4,2.14-11.908-1.447-14.505-8-1.813-4.58-1.308-9.535,1.26-12.847l-5.79-12.361a1.047,1.047,0,0,1,1.9-.888l6.071,12.963a1.047,1.047,0,0,1-.169,1.143c-2.429,2.71-2.948,7.114-1.323,11.219,2.172,5.476,7.46,8.536,11.787,6.822s6.085-7.567,3.916-13.044c-1.725-4.353-5.442-7.285-9.25-7.3a1.046,1.046,0,0,1-.945-.6l-7.563-16.151a1.047,1.047,0,0,1,1.691-1.193,1.03,1.03,0,0,1,.194.282l7.3,15.6c4.41.327,8.575,3.7,10.515,8.594,2.072,5.228,1.035,10.788-2.216,13.908"
                    transform="translate(-1045.252 -168.072)"
                    stroke="#000"
                    stroke-width="0.5"
                />
                <path
                    id="Path_10441"
                    data-name="Path 10441"
                    d="M1053.123,180.139a1.047,1.047,0,0,1-1.731-.467l-8.976-31.282a6.722,6.722,0,0,1,.3-4.536l2.851-6.524a1.047,1.047,0,0,1,1.907-.025l13.038,27.837a1.047,1.047,0,1,1-1.9.888l-12.057-25.742-1.926,4.405a4.623,4.623,0,0,0-.206,3.119l8.976,31.283a1.046,1.046,0,0,1-.282,1.044"
                    transform="translate(-1031.369 -136.702)"
                    stroke="#000"
                    stroke-width="0.5"
                />
                <path
                    id="Path_10442"
                    data-name="Path 10442"
                    d="M1040.562,216.061c-3.415,3.278-7.829,4.3-11.519,2.655-5.31-2.36-7.3-9.518-4.441-15.957a14.919,14.919,0,0,1,3.3-4.741,11.722,11.722,0,0,1,7.557-3.414l14.016-26.991c.01-.02.021-.039.032-.058l15.222-29.314a1.047,1.047,0,0,1,1.9.1l2.582,6.636a6.719,6.719,0,0,1,.115,4.544l-10.995,33.134a6.7,6.7,0,0,1-9.1,4.013l-6.107,11.761c2.43,3.415,2.733,8.387.732,12.889a14.93,14.93,0,0,1-3.3,4.741m-11.209-16.533a12.848,12.848,0,0,0-2.837,4.081c-2.392,5.384-.878,11.3,3.377,13.194,2.9,1.288,6.43.425,9.219-2.252a12.85,12.85,0,0,0,2.837-4.081c1.793-4.035,1.455-8.456-.862-11.264a1.05,1.05,0,0,1-.122-1.149l6.891-13.27a1.047,1.047,0,0,1,1.412-.447l.577.3a4.617,4.617,0,0,0,5.326-.769,4.558,4.558,0,0,0,1.178-1.873l10.995-33.134a4.622,4.622,0,0,0-.079-3.126l-1.744-4.481-14.186,27.32c-.01.019-.021.038-.032.057l-14.279,27.5a1.046,1.046,0,0,1-.969.563,9.414,9.414,0,0,0-6.7,2.832"
                    transform="translate(-1023.295 -137.119)"
                    stroke="#000"
                    stroke-width="0.5"
                />
                <path
                    id="Path_10443"
                    data-name="Path 10443"
                    d="M1099.521,260.434h0a4.879,4.879,0,0,1-1.581,1.019,5.221,5.221,0,0,1-4.55-.412,7.458,7.458,0,0,1-3.2-3.78,6.771,6.771,0,0,1,1.2-7.346,4.869,4.869,0,0,1,1.581-1.019c2.9-1.15,6.382.73,7.753,4.192a6.771,6.771,0,0,1-1.2,7.346m-6.688-9.009a4.745,4.745,0,0,0-.7,5.065,5.361,5.361,0,0,0,2.285,2.726,3.172,3.172,0,0,0,2.751.29,2.769,2.769,0,0,0,.9-.583,4.744,4.744,0,0,0,.7-5.065c-.947-2.389-3.206-3.742-5.036-3.017a2.788,2.788,0,0,0-.9.582"
                    transform="translate(-1051.688 -184.582)"
                    stroke="#000"
                    stroke-width="0.5"
                />
                <path
                    id="Path_10444"
                    data-name="Path 10444"
                    d="M1069.153,208.335a2,2,0,1,1,1.2,2.566,2.006,2.006,0,0,1-1.2-2.566"
                    transform="translate(-1042.873 -166.799)"
                    stroke="#000"
                    stroke-width="0.5"
                />
                <path
                    id="Path_10445"
                    data-name="Path 10445"
                    d="M1041.486,258.793a5.625,5.625,0,0,1-6.194,1.395c-2.854-1.268-3.945-5.069-2.433-8.472a7.811,7.811,0,0,1,1.724-2.476,5.625,5.625,0,0,1,6.194-1.394,5.219,5.219,0,0,1,2.89,3.539,7.454,7.454,0,0,1-.457,4.933,7.789,7.789,0,0,1-1.724,2.476m-5.452-8.042a5.79,5.79,0,0,0-1.261,1.816c-1.043,2.348-.429,4.908,1.37,5.708a3.584,3.584,0,0,0,3.894-.992,5.791,5.791,0,0,0,1.261-1.816,5.36,5.36,0,0,0,.349-3.54,3.172,3.172,0,0,0-1.718-2.168,3.584,3.584,0,0,0-3.894.992"
                    transform="translate(-1027.097 -184.096)"
                    stroke="#000"
                    stroke-width="0.5"
                />
            </g>
        </svg>
    );
};

export default ScissorIcon;
