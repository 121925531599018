const LikeIcon = ({ liked = false }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.255" height="15.688" viewBox="0 0 16.255 15.688" className={`${liked ? "un" : ""}like-icon`}>
        <g id="Like" transform="translate(0.75 0.75)">
            <path
                id="Icon_ionic-ios-heart"
                data-name="Icon ionic-ios-heart"
                d="M14.158,3.938h-.035a4.036,4.036,0,0,0-3.37,1.844,4.036,4.036,0,0,0-3.37-1.844H7.348A4.01,4.01,0,0,0,3.375,7.946a8.634,8.634,0,0,0,1.7,4.707,29.712,29.712,0,0,0,5.682,5.473,29.712,29.712,0,0,0,5.682-5.473,8.634,8.634,0,0,0,1.7-4.707A4.01,4.01,0,0,0,14.158,3.938Z"
                transform="translate(-3.375 -3.938)"
                fill={liked ? "#FC014F" : "#fff"}
                stroke={liked ? "#FC014F" : "#000"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export default LikeIcon;
