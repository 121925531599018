import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Account/AuthContext";
import { Link } from "react-router-dom";
import "./style.css";
import Offcanvas from "../Offcanvas/offcanvas";
import SimchasDropDown from "../SImchasMenu/SimchasMenu";
import HeartIcon from "../../assets/icons/HeartIcon";
import CartIcon from "../../assets/icons/CartIcon";
import UserIcon from "../../assets/icons/UserIcon";
import NavLogo from "../../assets/logo/nav-logo";
import useViewportWidth from "../../hooks/useViewportWidth";
import CartMenu from "../CartMenu/CartMenu";
import { useCategories } from "../../context/categoriesContext";
import ContactUsModal from "../ContactUsModal/ContactUsModal";
import { useWishlist } from "../../context/wishlistContext";
import { useCart } from "../../context/cartContext";

const NavBar = () => {
    const { authenticated } = useAuth();
    const navigate = useNavigate();
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [isCartMenuOpen, setIsCartMenuOpen] = useState(null);
    const width = useViewportWidth();
    const toggleCart = () => {
        setIsCartOpen(!isCartOpen);
    };
    const { wishlistQty } = useWishlist();
    const { cartQty } = useCart();
    const [modalOpen, setModalOpen] = useState(false);
    const [ocassionIsHover, setOcassionIsHover] = useState(null);
    const [simchasIsHover, setSimchasIsHover] = useState(null);

    return (
        <>
            <div className="header-container">
                <div className="header-sub-container">
                    <div
                        className="logo-wrap"
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <NavLogo width={280} height={37} />
                        <p className="beta-logo">BETA</p>
                    </div>
                    <div className="links-icons-wrap" style={{ display: "flex" }}>
                        <div className="links-wrap">
                            <Link className="link" to="/">
                                Home
                            </Link>
                            <Link
                                className="link ocassions-link"
                                onMouseEnter={() => {
                                    setOcassionIsHover(true);
                                }}
                                onMouseLeave={() => {
                                    setOcassionIsHover(false);
                                }}
                            >
                                Occasions
                                <span
                                    className={`ocassions-drop-down 
                                        ${
                                            ocassionIsHover === true
                                                ? "active"
                                                : ocassionIsHover === false
                                                ? "hidden"
                                                : ocassionIsHover === null
                                                ? "display-none"
                                                : ""
                                        }
                                    `}
                                >
                                    <Link
                                        className="drop-down-link"
                                        style={{ marginBottom: "10px" }}
                                        to="categories/others?sub=חתן-בראשית"
                                    >
                                        חתן-בראשית
                                    </Link>
                                    <Link
                                        className="drop-down-link"
                                        style={{ marginBottom: "10px" }}
                                        to="categories/others?sub=chanukah"
                                    >
                                        Chanukah
                                    </Link>
                                </span>
                            </Link>
                            <Link
                                className={`link simchas-link`}

                                onMouseEnter={() => {
                                    setSimchasIsHover(true);
                                }}
                                onMouseLeave={() => {
                                    setSimchasIsHover(false);
                                }}
                            >
                                Simchas
                                <SimchasDropDown
                                simchasIsHover={simchasIsHover}
                                />
                            </Link>
                            <Link className="link" to="categories/videos">
                                Videos
                            </Link>
                            <a className="a" href="#custom-design">
                                Custom
                            </a>
                            <Link
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                                className="link"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                            >
                                Contact Us
                            </Link>
                        </div>
                        <div className="icons-wrap">
                            <Link to="/wishlist">
                                <div className="nav-icon heart-icon" style={{ marginRight: "5px" }}>
                                    <HeartIcon />
                                    {wishlistQty ? (
                                        <span className="wishlist-length">{wishlistQty}</span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                            </Link>
                            <div>
                                <div
                                    className="nav-icon cart-icon"
                                    onClick={() => {
                                        setIsCartMenuOpen(true);
                                    }}
                                >
                                    <CartIcon />
                                    {cartQty ? <span className="cart-length">{cartQty}</span> : <span></span>}
                                </div>
                                <CartMenu setIsCartMenuOpen={setIsCartMenuOpen} isCartMenuOpen={isCartMenuOpen} />
                            </div>
                            <Link className="auth-link" to={authenticated ? `/account/OrderHistory` : `/login`}>
                                <div className="nav-icon">
                                    <UserIcon />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="responsive-sub-container">
                    <Offcanvas authenticated={authenticated} wishlist={wishlistQty} />
                    <div className="logo-wrap">
                        <NavLogo width={width > 770 ? 253 : 180} height={33} />
                        <p className="beta-logo beta-logo-res">BETA</p>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setIsCartMenuOpen(true);
                        }}
                    >
                        <div style={{ position: "relative" }}>
                            <CartIcon />
                            {cartQty ? <span className="cart-length">{cartQty}</span> : <span></span>}
                        </div>
                    </div>
                </div>
                <CartMenu setIsCartMenuOpen={setIsCartMenuOpen} isCartMenuOpen={isCartMenuOpen} />
            </div>
            <ContactUsModal setModalOpen={setModalOpen} modalOpen={modalOpen} />
        </>
    );
};

export default NavBar;
