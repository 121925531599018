import React from "react";

const RingIcon = () => {
    return (
        <>
            <svg
                id="Component_23_1"
                data-name="Component 23 – 1"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 68.528 83.09"
            >
                <path
                    id="Path_10449"
                    data-name="Path 10449"
                    d="M2078.814,233.278a34.267,34.267,0,0,1-5.238-68.13l.529,3.45a30.771,30.771,0,1,0,9.911.08l.586-3.441a34.267,34.267,0,0,1-5.788,68.041"
                    transform="translate(-2044.55 -150.187)"
                />
                <path
                    id="Path_10450"
                    data-name="Path 10450"
                    d="M2087.118,230.974A23.977,23.977,0,1,1,2111.1,207a24,24,0,0,1-23.977,23.977m0-44.464A20.487,20.487,0,1,0,2107.6,207a20.509,20.509,0,0,0-20.486-20.487"
                    transform="translate(-2052.855 -158.171)"
                />
                <path
                    id="Path_10451"
                    data-name="Path 10451"
                    d="M2095.638,166.17l-13.419-19.937,4.684-8.125h17.472l4.694,8.117-1.012,1.5Zm-9.308-20.087,9.309,13.835,9.311-13.831-2.59-4.488h-13.441Z"
                    transform="translate(-2061.376 -138.109)"
                />
            </svg>
        </>
    );
};

export default RingIcon;
