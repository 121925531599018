/**
 *  Account Profile Edit
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import Common from "../../api/common";
import Sitebar from "./Sitebar";
import customer from "../../requests/customer";
import Footer from "../../layouts/footer/Footer";

class AccountProfileedit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldvalue: {
                firstname: "",
                lastname: "",
                displayname: "",
                gender: "",
                dob: "",
                phoneno: "",
                email: "",
                address: "",
            },
            errors: {},
        };
    }

    async fetchProfile() {
        const customerId = localStorage.getItem("customerId");
        if (!customerId) return;

        try {
            const userProfile = await customer.getCustomer({ customerId });
            if (userProfile) {
                this.setState({ fieldvalue: userProfile });
            }
        } catch (error) {
            console.error("Error in userProfile:", error);
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchProfile();
    }

    handleValidation() {
        const fieldvalue = this.state.fieldvalue;
        let errors = {};
        let formIsValid = true;

        //First Name
        if (!fieldvalue["firstname"]) {
            formIsValid = false;
            errors["firstname"] = "Please Enter First Name";
        }

        if (typeof fieldvalue["firstname"] !== "undefined") {
            if (!fieldvalue["firstname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["firstname"] = "Please Enter Only Letter";
            }
        }

        //Last Name
        if (!fieldvalue["lastname"]) {
            formIsValid = false;
            errors["lastname"] = "Please Enter Last Name";
        }

        if (typeof fieldvalue["lastname"] !== "undefined") {
            if (!fieldvalue["lastname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["lastname"] = "Please Enter Only Letter";
            }
        }

        //display Name
        if (!fieldvalue["displayname"]) {
            formIsValid = false;
            errors["displayname"] = "Please Enter Display Name";
        }

        if (typeof fieldvalue["displayname"] !== "undefined") {
            if (!fieldvalue["displayname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["displayname"] = "Please Enter Only Letter";
            }
        }

        //Male & Female Radio Button
        if (!fieldvalue["gender"]) {
            formIsValid = false;
            errors["gender"] = "Please Select Radio";
        }

        //Last Name
        if (!fieldvalue["phoneno"]) {
            formIsValid = false;
            errors["phoneno"] = "Please Enter Phone NO";
        }

        if (typeof fieldvalue["phoneno"] !== "undefined") {
            if (!fieldvalue["phoneno"].match(/^\d{10}$/)) {
                formIsValid = false;
                errors["phoneno"] = "Please Enter Valid Phone no";
            }
        }

        //Email ID
        if (!fieldvalue["email"]) {
            formIsValid = false;
            errors["email"] = "Please Enter Email ID";
        }

        if (typeof fieldvalue["email"] !== "undefined") {
            if (!fieldvalue["email"].match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)) {
                formIsValid = false;
                errors["email"] = "Please Enter Valid Email Address";
            }
        }

        //Address
        if (!fieldvalue["address"]) {
            formIsValid = false;
            errors["address"] = "Please Enter Address";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async onProfileFormSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            console.log("Form is valid!");

            try {
                const customerId = localStorage.getItem("customerId");
                let customerData = { ...this.state.fieldvalue };
                const updateCustomer = await customer.updateCustomer(customerId, customerData);
            } catch (error) {
                console.error("Error in userProfile:", error);
            }
        } else {
            console.error("Validation failed.");
        }
    }

    handleChange(field, e) {
        let fieldvalue = { ...this.state.fieldvalue };
        fieldvalue[field] = e.target.value;
        this.setState({ fieldvalue });
    }

    render() {
        const { fieldvalue, errors } = this.state;
        return (
            <>
                <div>
                    <div className="section-ptb">
                        <Container>
                            <Row>
                                <Sitebar />
                                <Col lg={8} className="mt-4">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="woocommerce-Address-info ">
                                                <form onSubmit={this.onProfileFormSubmit.bind(this)}>
                                                    <Row className="profile-edit-input-wrap">
                                                        <Col className="form-group">
                                                            <label>
                                                                First name <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control mt-0"
                                                                value={fieldvalue.firstname || ""}
                                                                onChange={this.handleChange.bind(this, "firstname")}
                                                                placeholder="First name"
                                                            />
                                                            <span className="error">{errors.firstname}</span>
                                                        </Col>
                                                        <Col className="form-group">
                                                            <label>
                                                                Last name <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control mt-0"
                                                                value={fieldvalue.lastname || ""}
                                                                onChange={this.handleChange.bind(this, "lastname")}
                                                                placeholder="Last name"
                                                            />
                                                            <span className="error">{errors.lastname}</span>
                                                        </Col>
                                                        <Col sm={12} className="form-group">
                                                            <label>
                                                                Display name <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control mt-0"
                                                                value={fieldvalue.displayname || ""}
                                                                onChange={this.handleChange.bind(this, "displayname")}
                                                                placeholder="Last name"
                                                            />
                                                            <span className="text-below-input">
                                                                This will be how your name will be displayed in the
                                                                account section and in reviews
                                                            </span>
                                                            <span className="error">{errors.displayname}</span>
                                                        </Col>
                                                        <Col sm={12} className="form-group">
                                                            <label>
                                                                Email <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className="form-control mt-0"
                                                                value={fieldvalue.email || ""}
                                                                onChange={this.handleChange.bind(this, "email")}
                                                                placeholder="Email"
                                                            />
                                                            <span className="error">{errors.email}</span>
                                                        </Col>

                                                        <Col sm={12} className="mt-4">
                                                            <p className="password-change-hd">Password Change</p>
                                                            <hr className="mt-0 " />

                                                            <Row className="password-change-input-wrap">
                                                                <Col sm={12}>
                                                                    <label>
                                                                        Current password (leave blank to leave
                                                                        unchanged)
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control mt-0"
                                                                        value={fieldvalue.currentPassword || ""}
                                                                        onChange={this.handleChange.bind(
                                                                            this,
                                                                            "currentPassword"
                                                                        )}
                                                                    />
                                                                    <span className="error">
                                                                        {errors.currentPassword}
                                                                    </span>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label>
                                                                        New password (leave blank to leave unchanged)
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control mt-0"
                                                                        value={fieldvalue.currentPassword || ""}
                                                                        onChange={this.handleChange.bind(
                                                                            this,
                                                                            "newPassword"
                                                                        )}
                                                                    />
                                                                    <span className="error">{errors.newPassword}</span>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label>Confirm new password</label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control mt-0"
                                                                        value={fieldvalue.currentPassword || ""}
                                                                        onChange={this.handleChange.bind(
                                                                            this,
                                                                            "confirmNewPassword"
                                                                        )}
                                                                    />
                                                                    <span className="error">
                                                                        {errors.confirmNewPassword}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <div>
                                                                <button
                                                                    type="submit"
                                                                    className="account-profile-button"
                                                                >
                                                                    Save Changes
                                                                </button>
                                                            </div>
                                                        </Col>
                                                        {/* <div className="form-group">
                                                        <label className="d-block">Gender</label>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input
                                                                type="radio"
                                                                id="male"
                                                                name="gender"
                                                                className="custom-control-input"
                                                                value="Male"
                                                                onChange={this.handleChange.bind(this, "gender")}
                                                                checked={fieldvalue.gender === "Male"}
                                                            />
                                                            <label className="custom-control-label" htmlFor="male">
                                                                Male
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input
                                                                type="radio"
                                                                id="female"
                                                                name="gender"
                                                                className="custom-control-input"
                                                                value="Female"
                                                                onChange={this.handleChange.bind(this, "gender")}
                                                                checked={fieldvalue.gender === "Female"}
                                                            />
                                                            <label className="custom-control-label" htmlFor="female">
                                                                Female
                                                            </label>
                                                        </div>
                                                        <span className="error">{errors.gender}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Date of birth</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={fieldvalue.dob || ""}
                                                            onChange={this.handleChange.bind(this, "dob")}
                                                            placeholder="Date of birth"
                                                        />
                                                        <span className="error">{errors.dob}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Phone</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={fieldvalue.phoneno || ""}
                                                            onChange={this.handleChange.bind(this, "phoneno")}
                                                            placeholder="Phone"
                                                        />
                                                        <span className="error">{errors.phoneno}</span>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Address</label>
                                                        <textarea
                                                            className="form-control"
                                                            rows="3"
                                                            value={fieldvalue.address || ""}
                                                            onChange={this.handleChange.bind(this, "address")}
                                                            placeholder="Address"
                                                        />
                                                        <span className="error">{errors.address}</span>
                                                    </div> */}
                                                    </Row>
                                                </form>
                                            </div>
                                            {/* <div className="woocommerce-Address woocommerce-Address-edit">
                                            <div className="woocommerce-Address-title">
                                                <h5 className="mb-0">Profile Information Edit</h5>
                                            </div>
                                            
                                        </div> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
export default AccountProfileedit;
