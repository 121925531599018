// import Loader from 'react-loader-spinner';
import CircleLoader from "./circleLoader";

const CustomLoader = ({ color }) => {
    return (
        <div className="centered">
            <CircleLoader color={color} />
        </div>
    );
}

export default CustomLoader;