import React from "react";

const ChatBubbleIcon = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.049" height="18.022" viewBox="0 0 18.049 18.022">
                <g id="chat-bubble" transform="translate(-1.975 -1.978)">
                    <g id="message-circle" transform="translate(1.975 1.978)">
                        <circle
                            id="Elipse_24"
                            data-name="Elipse 24"
                            cx="0.898"
                            cy="0.898"
                            r="0.898"
                            transform="translate(8.105 8.102)"
                            fill="#af40f7"
                        ></circle>
                        <circle
                            id="Elipse_25"
                            data-name="Elipse 25"
                            cx="0.898"
                            cy="0.898"
                            r="0.898"
                            transform="translate(11.697 8.102)"
                            fill="#af40f7"
                        ></circle>
                        <circle
                            id="Elipse_26"
                            data-name="Elipse 26"
                            cx="0.898"
                            cy="0.898"
                            r="0.898"
                            transform="translate(4.513 8.102)"
                            fill="#af40f7"
                        ></circle>
                        <path
                            id="Trazado_10928"
                            data-name="Trazado 10928"
                            d="M17.327,4.629a8.981,8.981,0,0,0-14.62,9.879.952.952,0,0,1,.081.575L2,18.881a.892.892,0,0,0,.9,1.078h.18l3.844-.772a1.132,1.132,0,0,1,.575.081,8.981,8.981,0,0,0,9.879-14.62Zm.745,7.508A7.184,7.184,0,0,1,8.194,17.6a2.928,2.928,0,0,0-1.123-.233,3.08,3.08,0,0,0-.5.045l-2.533.512.512-2.533a2.775,2.775,0,0,0-.189-1.625,7.186,7.186,0,1,1,13.713-1.625Z"
                            transform="translate(-1.975 -1.978)"
                            fill="#af40f7"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default ChatBubbleIcon;
