import React, { createContext, useContext, useEffect, useState } from 'react';
// request
import categoriesRequests from '../requests/categories';

const CategoriesContext = createContext();

const CategoriesProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        categoriesRequests.getAllCategoriesAndSubCategories()
            .then( data => { setCategories(data.categories) })
            .catch(setHasError)
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <CategoriesContext.Provider value={{
            categories,
            isLoading,
            hasError
        }}>
            {children}
        </CategoriesContext.Provider>
    );
}


// custom hook
function useCategories() {
    const state = useContext(CategoriesContext);
    return state;
}

export {
    CategoriesProvider,
    useCategories
};