import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Keyboard from 'simple-keyboard';
import layout from "simple-keyboard-layouts/build/layouts/hebrew";
// custom hooks
import { useEditPurchaseHook } from "../../context/editPurchaseContext";
// utils
import { hasHebrewCharacters } from '../../utils/text';
// style
import 'simple-keyboard/build/css/index.css';
import './hebrewInput.css';

let _inputTop = 0;
let keyboardsMap = {}
window.keyboardsMap = keyboardsMap;

function HebrewTextInput({
    onChange,
    value,
    name,
    isUpperCase = false,
    suggestionWasSelected = false,
    lastSuggestionSelected = '',
    associatedValueDisplayed = false,
    hasSuggestionsLikeSmartphone = false,
    valueChangedAtParentComponent = false
}) {
    const [keyboard, setKeyboard] = useState(null);
    const [show, setShow] = useState(false);
    const [disableInput, setDisableinput] = useState(false);
    const inputRef = useRef();
    const { pathname } = useLocation();

    const editPurchaseState = useEditPurchaseHook();

    const handleHebrewTextChange = (txt, ev) => {
        const _keyboard = keyboardsMap[name];
        const inputElmt = inputRef.current;

        // console.log(Math.random().toFixed(4), txt);

        if (ev.target.innerText === 'clear') {
            _keyboard.clearInput();
            onChange({ 
                target: { name, value: '' },
                inputElmt // don't spread inputElmt because returns plain object instead html input
            });
            return;
        }
        onChange({ 
            target: { name, value: txt },
            selectionStart: _keyboard.getCaretPosition(),
            inputElmt // don't spread inputElmt because returns plain object instead html input
        });
    }

    const handleInputChange = ev => {
        const _keyboard = keyboardsMap[name];
        const inputElmt = inputRef.current;
        _keyboard.setInput(ev.target.value);

        onChange({ 
            target: { name, value: ev.target.value },
            selectionStart: inputElmt.selectionStart,
            inputElmt // don't spread inputElmt because returns plain object instead html input
        });
    };

    const handleBlur = (ev) => {
        // the wrapper of this component also has tabIndex=0 so if
        // you clicked inside the component on the blur ev.relatedTarget is truty
        if(!ev.relatedTarget){
            setShow(false);
        }
    }

    const handleFocus = (ev) => {
        if(show) return;
        
        const inputFocusedStyles = ev.target.getBoundingClientRect();
        // hide all previous keyboards
        const keyboards = document.querySelectorAll('.k-toggle');
        keyboards.forEach( k => k.style.display = 'none' );
        // show current
        setShow(true);
        const top = inputFocusedStyles.y;
        const width = inputFocusedStyles.width;
        _inputTop = top;
        const extraTop = 45;

        document.querySelector(`.keyboard-virtual-${name}`).style.top = top + extraTop +'px';
        document.querySelector(`.keyboard-virtual-${name}`).style.width = width +'px';
    }

    // usefull when user select all text and delete it
    const handleSelect = () => {
        if(show) return;
        setShow(true);
    }

    const handleClearBtnX = () => {
        const _keyboard = keyboardsMap[name];
        _keyboard.clearInput();
        onChange({target: { name, value: '' }});
    }

    // init keyboard
    useEffect(() => {
        layout.layout.default[0] = "1 2 3 4 5 6 7 8 9 0 clear {bksp}";
        layout.layout.default[1] = "/ ' ק ר א ט ו ן ם פ ] [ :";
        layout.layout.default[2] = "ש ד ג כ ע י ח ל ך ף ,";
        layout.layout.default[3] = "ז ס ב ה נ מ צ ת ץ . \"";
        layout.layout.default[4] = "{space}";

        let _keyboard = new Keyboard('keyboard-virtual-' + name, {
            ...layout,
            onChange: handleHebrewTextChange,
            inputName: name,
        });
        setKeyboard(_keyboard);
        _keyboard.setInput(value);

        keyboardsMap[name] = _keyboard;

        // set text for space bar
        document.querySelector(`.keyboard-virtual-${name} [data-skbtn="{space}"]`).textContent = 'space';
        document.querySelector(`.keyboard-virtual-${name} [data-skbtn="{bksp}"]`).textContent = 'bksp';
        document.querySelector(`.keyboard-virtual-${name} [data-skbtn="clear"]`).classList.add('clear-btn-keyboard');
    }, [name]);

    // listen blur event for all body
    useEffect(() => {
        const handleClickOutside = ev => {
            const wrapper = document.querySelector("#k-container-" + name);
            if(wrapper && !wrapper.contains(ev.target)){
                setShow(false);
            }
        }

        document.body.addEventListener('click', handleClickOutside);
        return () => document.body.removeEventListener('click', handleClickOutside);
    }, [name]);

    // if user select a suggestion set the virtual keyboard value
    useEffect(() => {
        if(suggestionWasSelected){
            keyboard.setInput(lastSuggestionSelected);
        }
        if(associatedValueDisplayed || valueChangedAtParentComponent){
            keyboard.setInput(value);
        }
    }, [
        suggestionWasSelected,
        associatedValueDisplayed,
        value,
        lastSuggestionSelected,
        valueChangedAtParentComponent,
        hasSuggestionsLikeSmartphone
    ]);

    // set virtual keyboard top if have autocomplete as an smartphone and has suggestions
    useEffect(() => {
        const extraTop = hasSuggestionsLikeSmartphone ? 75 : 45;
        document.querySelector(`.keyboard-virtual-${name}`).style.top = _inputTop + extraTop +'px';

    }, [hasSuggestionsLikeSmartphone, name]);

    // enable or disable input depends if is on edit purchase page
    useEffect(() => {
        if(pathname.includes('edit-purchase')){
            const { inputsTextIds } = editPurchaseState;
            const idx = inputsTextIds.indexOf(name);
            if (idx !== -1) setDisableinput(false);
            else setDisableinput(true);
        }
    }, [editPurchaseState, pathname, name]);

    return(
        <div className="k-container" id={"k-container-" + name}>
            <input
                type="text"
                name={name}
                value={value}
                className="k-input-in-form"
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onSelect={handleSelect}
                tabIndex={0}
                autoComplete='off'
                ref={inputRef}
                id={`hebrew-input-${name}`}
                disabled={disableInput}
                style={{ 
                    direction: hasHebrewCharacters(value) ? 'rtl' : 'ltr', 
                    textTransform: isUpperCase ? 'uppercase' : 'none' 
                }}
            />

            {
                !disableInput && (
                    <div className='k-clear-input-x' onClick={handleClearBtnX}>
                        <i className='fa fa-times-circle'></i>
                    </div>
                )
            }

            <div
                style={{ display: show ? 'block' : 'none' }}
                // style={{ display: 'block' }}
                className={`k-toggle k-toggle-${name}`}
            >
                <div className={`simple-keyboard keyboard-virtual-${name}`}>
                    <div className="k-close">
                        <button onClick={() => setShow(false)}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default HebrewTextInput;