import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
// requests
import cartRequests from "../../requests/cart";
// import purchasesRequests from '../../requests/purchases';
// utils
import canvasObjsToJsonStr from "../../utils/canvasObjsToJsonStr";
import { getUrlQuery } from "../../utils/url";
import postToParent from "../../utils/postToParent";
import { findDimensionsTag } from "../../utils/findDimensionsTag";
// constants
import { CANVAS_SIZE } from "../../utils/constants";
//style
import "./style.css";
import { isCustomerLogin } from "../../utils/common";
import { random } from "tinycolor2";
import { useCart } from "../../context/cartContext";

const AddToCartAndBuyNowButtons = ({ productDetails, isPreviewAvailable }) => {
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState({});
    const [cartState, setCartState] = useState({ loading: false, added: false });
    const [buyLoading, setBuyLoading] = useState(false);
    const { addItemToCart } = useCart();

    useEffect(() => {
        setQueryParams(getUrlQuery());
        // console.log("productDetails", productDetails);
    }, []);

    const handleSaveInCart = async (_event, buyNow = false) => {
        const isCustomer = isCustomerLogin();

        if (cartState.added) {
            postToParent({ type: "click-cart-button" });
            return;
        }

        // if (buyNow) {
        //     setBuyLoading(true);
        // } else {
        //     setCartState({ loading: true, added: false });
        // }

        // alert("irem");

        const { canvas, product, songSelected, QRText } = productDetails;
        const variation = `${songSelected ? 1 : 0}|${QRText ? 1 : 0}`;
        let variationId;

        const customCartData = {
            canvasObjects: JSON.parse(canvasObjsToJsonStr(canvas, true)),
            watermarkImage: product.filesId.pathWithWatermark,
            productId: product._id,
            productFilesId: product.filesId._id,
            productName: product.name,
            pdfSizes: product.pdfSizes || [],
            tags: product.tags || [],
            dimensions: findDimensionsTag(product.tags),

            song: null,
            QRText,
        };

        if (songSelected) {
            customCartData.song = {
                path: songSelected.path,
                name: songSelected.filename || songSelected.name,
                _id: songSelected._id,
            };
        }

        // save the current canvas size (save the canvas witdh in which it was edited)
        for (let obj of customCartData.canvasObjects) {
            obj.canvasWidth = CANVAS_SIZE;
        }

        const canvasImageBase64URL = canvas.toDataURL({
            format: "jpeg",
        });

        if (queryParams["variations[]"]) {
            const _variationId = queryParams["variations[]"].find((v) => v.includes(variation));
            if (_variationId) {
                variationId = _variationId.split("|")[0];
            }
        }
        // console.log("variationId", customCartData);

        customCartData.QRText = QRText;
        const cartResponse = await cartRequests.saveInCart({
            woo_cart_item_key: queryParams.cart_item_key || Math.random().toString(36).substring(7),
            woo_product_id: product.wooProductId,
            product_id: product._id,
            woo_product_variation_id: variationId || "",
            customization_data: JSON.stringify(customCartData),
            customized_item_preview: canvasImageBase64URL,
            customerId: isCustomer,
        });

        // (NOTE: now doing this is wordpress when a purchase is success)
        // save cart item key for future purchase modification
        // if(cartResponse.success){
        //     await purchasesRequests.initEditCount({ wooCartItemKey: cartResponse.cart_item_key });
        // }

        let added = false;
        if (cartResponse.status === "success") {
            added = true;
        }

        if (buyNow) setBuyLoading(false);
        else setCartState({ loading: false, added: added });

        // const cart_item_key = cartResponse.cart_item_key || "";
        // const wooId = product.wooProductId;
        // update cart UI (cart item on wordpress)
        // postToParent({
        //     type: "add-item-to-cart-ui",
        //     newItemData: {
        //         shopLink: `/design-your-card/?id=${wooId}`,
        //         previewLink: cartResponse.preview_url || canvasImageBase64URL,
        //         editItemLink: `/design-your-card?id=${wooId}&cart_item_key=${cart_item_key}`,
        //         hasMusic: songSelected ? "Yes" : "No",
        //         hasQR: QRText ? "Yes" : "No",
        //         name: product.name,
        //         price: product.price,
        //         cartItemKey: cart_item_key,
        //         wooProductId: wooId,
        //     },
        // });
        if (buyNow) {
            navigate("/checkout");
            return;
        }
        if (added) {
            // if is edit card page
            await Swal.fire({
                title: "Card updated",
                icon: "success",
                text: "Reloading page...",
                timer: 2000,
                showConfirmButton: false,
                showCloseButton: false,
            });

            addItemToCart({
                ...cartResponse.data,
                productDetails: { name: product.name, price: product.price },
            });

            // postToParent({
            //     type: "navigate",
            //     to: `design-your-card/?id=${product.wooProductId}&cart_item_key=${cartResponse.cart_item_key}`,
            // });
        }
    };

    const handleBuy = async (e) => {
        await handleSaveInCart(e, true);
    };

    // render
    return (
        <div className="cartbtn-container">
            {queryParams.cart_item_key ? (
                <button onClick={handleSaveInCart} id="cartbtn-cart" disabled={cartState.loading || buyLoading}>
                    {cartState.loading && <Spinner id="cart-loading-btn" color="dark" size="sm" type="grow" />}
                    {cartState.added ? "Saved changes" : "Save edit"}
                </button>
            ) : (
                <button
                    onClick={handleSaveInCart}
                    id="cartbtn-cart"
                    disabled={cartState.loading || buyLoading || !isPreviewAvailable}
                >
                    {cartState.loading && <Spinner id="cart-loading-btn" color="dark" size="sm" type="grow" />}
                    {cartState.added ? "Saved!" : "Save for later"}
                </button>
            )}
            <button
                onClick={handleBuy}
                id="cartbtn-buynow"
                disabled={cartState.loading || buyLoading || !isPreviewAvailable}
            >
                {buyLoading && <Spinner id="buy-loading-btn" color="dark" size="sm" type="grow" />}
                Buy Now
            </button>
        </div>
    );
};

export default AddToCartAndBuyNowButtons;
