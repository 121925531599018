import React, { useEffect, useState } from "react";
import "./style.css";
import SubMenu from "./subMenu/SubMenu";
import BabyBoyIcon from "../../assets/icons/baby-boy-icon";
import KidDushIcon from "../../assets/icons/kid-dush-icon";
import ScissorIcon from "../../assets/icons/scissor-icon";
import BarIcon from "../../assets/icons/Bar-icon";
import RingIcon from "../../assets/icons/Ring-icon";
import BottleIcon from "../../assets/icons/bottle-icon";
import WeddingIcon from "../../assets/icons/wedding";
import FlowerIcon from "../../assets/icons/flower";
import VideoIcon from "../../assets/icons/videos";
import OtherIcon from "../../assets/icons/others";
import { Link, useNavigate } from "react-router-dom";
import { useCategories } from "../../context/categoriesContext";

const links = [
    {
        icon: <BabyBoyIcon />,
        text: "BabyBoy",
        slug: "/baby-boy",
        arrow: true,
        submenu: [
            {
                icon: (
                    <svg
                        id="Component_19_3"
                        data-name="Component 19 – 3"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="25"
                        height="25"
                        viewBox="0 0 75.552 77.797"
                        fill="#009eff"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect id="Rectangle_872" data-name="Rectangle 872" width="75.552" height="77.797" />
                            </clipPath>
                        </defs>
                        <g id="Group_10918" data-name="Group 10918">
                            <g id="Group_10918-2" data-name="Group 10918" clip-path="url(#clip-path)">
                                <path
                                    id="Path_10503"
                                    data-name="Path 10503"
                                    d="M53.47,57.834c-17.676,0-32.233-12.81-32.45-28.555a1.519,1.519,0,0,1,1.518-1.54H49.2a1.625,1.625,0,0,0,1.623-1.623V1.544A1.519,1.519,0,0,1,52.294.026l.062,0C52.727.012,53.1,0,53.47,0c17.9,0,32.461,12.972,32.461,28.917S71.369,57.834,53.47,57.834M24.13,30.777c1.093,13.38,13.9,24.02,29.34,24.02,16.224,0,29.424-11.609,29.424-25.879,0-14.155-12.987-25.693-29.031-25.877V26.116a4.665,4.665,0,0,1-4.66,4.66Z"
                                    transform="translate(-10.379)"
                                />
                                <path
                                    id="Path_10504"
                                    data-name="Path 10504"
                                    d="M12.04,48a1.52,1.52,0,0,1-1.5-1.27c-.35-2.112-.557-3.183-.932-5.126l-.218-1.13a3.632,3.632,0,0,0-4.248-2.877,2.6,2.6,0,0,0-2.061,3.042,1.776,1.776,0,0,0,2.079,1.408,1.054,1.054,0,0,0,.473-.332,1.519,1.519,0,0,1,2.421-1.83,2.792,2.792,0,0,1,.487,2.774,3.891,3.891,0,0,1-2.808,2.37A4.819,4.819,0,0,1,.1,41.207a5.642,5.642,0,0,1,4.471-6.6,6.671,6.671,0,0,1,7.8,5.287l.217,1.128c.368,1.9.59,3.053.947,5.2A1.52,1.52,0,0,1,12.04,48"
                                    transform="translate(0 -17.029)"
                                />
                                <path
                                    id="Path_10505"
                                    data-name="Path 10505"
                                    d="M40.154,132.943a10.63,10.63,0,1,1,10.63-10.63,10.642,10.642,0,0,1-10.63,10.63m0-18.222a7.592,7.592,0,1,0,7.592,7.592,7.6,7.6,0,0,0-7.592-7.592"
                                    transform="translate(-14.579 -55.146)"
                                />
                                <path
                                    id="Path_10506"
                                    data-name="Path 10506"
                                    d="M50.6,129.5a3.261,3.261,0,1,1-3.261-3.261A3.261,3.261,0,0,1,50.6,129.5"
                                    transform="translate(-21.766 -62.334)"
                                />
                                <path
                                    id="Path_10507"
                                    data-name="Path 10507"
                                    d="M110.489,132.943a10.63,10.63,0,1,1,10.63-10.63,10.642,10.642,0,0,1-10.63,10.63m0-18.222a7.592,7.592,0,1,0,7.592,7.592,7.6,7.6,0,0,0-7.592-7.592"
                                    transform="translate(-49.307 -55.146)"
                                />
                                <path
                                    id="Path_10508"
                                    data-name="Path 10508"
                                    d="M120.938,129.5a3.261,3.261,0,1,1-3.261-3.261,3.261,3.261,0,0,1,3.261,3.261"
                                    transform="translate(-56.495 -62.334)"
                                />
                                <path
                                    id="Path_10509"
                                    data-name="Path 10509"
                                    d="M79.087,72.36c-2.184,0-3.274-1.437-4.07-2.486-.722-.952-1.03-1.284-1.651-1.284s-.927.333-1.649,1.284c-.8,1.049-1.885,2.486-4.069,2.486s-3.274-1.437-4.069-2.486c-.722-.952-1.03-1.284-1.65-1.284s-.927.333-1.648,1.284c-.8,1.049-1.886,2.486-4.069,2.486s-3.273-1.437-4.069-2.486c-.721-.951-1.028-1.284-1.647-1.284s-.927.333-1.648,1.283c-.8,1.049-1.885,2.487-4.068,2.487s-3.273-1.437-4.069-2.487c-.721-.951-1.028-1.283-1.647-1.283s-.927.333-1.648,1.284c-.8,1.049-1.885,2.486-4.069,2.486s-3.273-1.437-4.069-2.486c-.721-.951-1.029-1.284-1.648-1.284a1.519,1.519,0,1,1,0-3.037c2.184,0,3.273,1.437,4.069,2.486.721.951,1.029,1.284,1.649,1.284s.927-.333,1.648-1.284c.8-1.049,1.885-2.486,4.069-2.486s3.273,1.437,4.068,2.486c.721.951,1.028,1.284,1.648,1.284s.927-.333,1.648-1.284c.8-1.049,1.884-2.486,4.068-2.486s3.272,1.437,4.068,2.486c.721.951,1.028,1.284,1.648,1.284s.927-.333,1.648-1.284c.8-1.049,1.886-2.486,4.069-2.486S65.2,66.99,66,68.039c.721.951,1.029,1.284,1.649,1.284s.927-.333,1.649-1.284c.8-1.049,1.885-2.486,4.069-2.486s3.275,1.437,4.071,2.486c.722.951,1.03,1.284,1.651,1.284s.929-.333,1.651-1.284c.8-1.049,1.886-2.486,4.071-2.486a1.519,1.519,0,0,1,0,3.037c-.621,0-.929.333-1.651,1.284-.8,1.049-1.886,2.486-4.07,2.486"
                                    transform="translate(-12.893 -32.368)"
                                />
                            </g>
                        </g>
                    </svg>
                ),
                text: "BabyBoy",
                slug: "/baby-boy",
                color: "#009eff",
                isIcon: true,
            },
            { isIcon: false, text: "Shulem Zucher", slug: "shulem-zucher" },
            { isIcon: false, text: "Vachnacht + Bris", slug: "vachnacht--bris" },
            { isIcon: false, text: "Vachnacht", slug: "vachnacht" },
            { isIcon: false, text: "Zucher + Vachnacht + Bris", slug: "zucher--vachnact--bris" },
            { isIcon: false, text: "Shabbos Zucher/Vachnacht + Bris", slug: "shabbos-zuchervachnact--bris" },
            { isIcon: false, text: "Bris", slug: "bris" },
            { isIcon: false, text: "Pidyon Haben", slug: "pidyon-haben" },
        ],
    },
    {
        icon: <KidDushIcon />,
        text: "Kidush",
        slug: "/kiddush",
        arrow: false,
        submenu: [],
    },
    {
        icon: <ScissorIcon />,
        text: "Upsherin",
        slug: "/upsherin",
        arrow: false,
        submenu: [],
    },
    {
        icon: <BarIcon />,
        text: "Bar Mitzwah",
        slug: "/bar-mitzvah",
        arrow: true,
        submenu: [
            {
                icon: (
                    <svg
                        id="Component_22_1"
                        data-name="Component 22 – 1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 82.601 62.545"
                        fill="#de5b3b"
                    >
                        <path
                            id="Path_10446"
                            data-name="Path 10446"
                            d="M1585.685,198h-46.874V151.124h46.874Zm-43.314-3.56h39.754V154.683h-39.754Z"
                            transform="translate(-1528.748 -151.124)"
                        />
                        <path
                            id="Path_10447"
                            data-name="Path 10447"
                            d="M1603.528,247.4h-82.6V228.244h82.6Zm-79.2-3.4h75.8v-12.35h-75.8Z"
                            transform="translate(-1520.927 -184.85)"
                        />
                        <path
                            id="Path_10448"
                            data-name="Path 10448"
                            d="M1569.03,184.551h-4.752a8.706,8.706,0,0,0,.543-2.557,8.075,8.075,0,0,0-.362-2.874h.588V176.9a.915.915,0,0,1-.678-.95,1,1,0,0,1,.271-.7.888.888,0,0,1,.679-.294,1,1,0,0,1,1,1,.923.923,0,0,1-.724.95v2.217h1.72v-3.3a.915.915,0,0,1-.678-.95,1,1,0,0,1,.271-.7.887.887,0,0,1,.679-.294,1,1,0,0,1,.995,1,.922.922,0,0,1-.724.95v3.3h1.81v-2.715a.916.916,0,0,1-.678-.951,1,1,0,0,1,.272-.7.885.885,0,0,1,.679-.295,1.006,1.006,0,0,1,1,1,.924.924,0,0,1-.724.951v2.715h.362a14.356,14.356,0,0,0-.294,2.693,15,15,0,0,0,.2,2.737l.5,5.159,4.3-4.526-2.987-1.04a17.215,17.215,0,0,0,.951-3.349q.315-1.765.543-3.485l.045-.453c.03-.029.083.053.158.249a11.846,11.846,0,0,1,.294,1.833l3.123,1.04a1.408,1.408,0,0,1,.724.679,3.727,3.727,0,0,1,.407,2.557,1.909,1.909,0,0,1-.588,1.063l-6.155,5.883c-.242.212-.045.362.588.453a6.778,6.778,0,0,0,2.873-.181,8.136,8.136,0,0,0,2.919-1.448l4.933-3.711-3.213-1.267a1.375,1.375,0,0,0,.52-.7,7.565,7.565,0,0,0,.429-1.357q.182-.792.318-1.72t.226-1.788q.09,0,.407.5l3.168,1a1.754,1.754,0,0,1,1.018.792,3.31,3.31,0,0,1,.475,1.4,3.805,3.805,0,0,1-.158,1.583,2.844,2.844,0,0,1-.883,1.335l-14.889,11.088Z"
                            transform="translate(-1539.886 -161.072)"
                        />
                    </svg>
                ),
                text: "Bar Mitzwah ",
                color: "#de5b3b",
                slug: "/bar-mitzvah",
                isIcon: true,
            },
            { isIcon: false, text: "Bar Mitzwah", slug: "bar-mitzvah" },
            { isIcon: false, text: "Bas Mitzwah", slug: "bar-mitzvah" },
            { isIcon: false, text: "Bar Mitzwah Kiddush", slug: "bar-mitzvah-kiddush" },
        ],
    },
    {
        icon: <RingIcon />,
        text: "Tenoyem",
        slug: "/tenoyem",
        arrow: true,
        submenu: [
            {
                icon: (
                    <svg
                        id="Component_23_1"
                        data-name="Component 23 – 1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 68.528 83.09"
                        fill="#a400ff"
                    >
                        <path
                            id="Path_10449"
                            data-name="Path 10449"
                            d="M2078.814,233.278a34.267,34.267,0,0,1-5.238-68.13l.529,3.45a30.771,30.771,0,1,0,9.911.08l.586-3.441a34.267,34.267,0,0,1-5.788,68.041"
                            transform="translate(-2044.55 -150.187)"
                        />
                        <path
                            id="Path_10450"
                            data-name="Path 10450"
                            d="M2087.118,230.974A23.977,23.977,0,1,1,2111.1,207a24,24,0,0,1-23.977,23.977m0-44.464A20.487,20.487,0,1,0,2107.6,207a20.509,20.509,0,0,0-20.486-20.487"
                            transform="translate(-2052.855 -158.171)"
                        />
                        <path
                            id="Path_10451"
                            data-name="Path 10451"
                            d="M2095.638,166.17l-13.419-19.937,4.684-8.125h17.472l4.694,8.117-1.012,1.5Zm-9.308-20.087,9.309,13.835,9.311-13.831-2.59-4.488h-13.441Z"
                            transform="translate(-2061.376 -138.109)"
                        />
                    </svg>
                ),
                text: "Tenoyem",
                color: "#a400ff",
                slug: "/tenoyem",
                isIcon: true,
            },
            { isIcon: false, text: "Chosen's Side", slug: "chusens-side" },
            { isIcon: false, text: "Kallah's Side", slug: "kallahs-side" },
            { isIcon: false, text: "Chusen", slug: "chusen" },
            { isIcon: false, text: "Both Sides", slug: "both-sides" },
            { isIcon: false, text: "Vort", slug: "vort" },
        ],
    },
    {
        icon: <BottleIcon />,
        text: "Bavarfen",
        slug: "/bavarfen",
        arrow: true,
        submenu: [
            {
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 55.333 79.289">
                        <g
                            id="Component_24_1"
                            data-name="Component 24 – 1"
                            fill="#2e00ff"
                            transform="translate(1.523 1.5)"
                        >
                            <path
                                id="Path_10468"
                                data-name="Path 10468"
                                d="M61.279,747.095l8.447-8.83c.017-.018.028-.038.044-.057a1.607,1.607,0,0,0,.107-.137,1.453,1.453,0,0,0,.079-.125c.025-.045.048-.09.069-.138a1.387,1.387,0,0,0,.06-.158c.008-.024.02-.045.027-.07s.006-.045.011-.068a1.563,1.563,0,0,0,.027-.166c.006-.05.01-.1.011-.149s0-.1-.006-.152a1.454,1.454,0,0,0-.019-.154c-.009-.05-.021-.1-.035-.149s-.027-.095-.045-.141a2.026,2.026,0,0,0-.137-.273,1.447,1.447,0,0,0-.09-.127,1.557,1.557,0,0,0-.1-.121c-.017-.018-.029-.038-.047-.056s-.043-.032-.063-.05a1.541,1.541,0,0,0-.124-.1,1.6,1.6,0,0,0-.14-.089c-.039-.022-.079-.041-.121-.06a1.472,1.472,0,0,0-.177-.067c-.021-.007-.039-.017-.06-.023s-.059-.011-.09-.019-.054-.012-.082-.016a4.634,4.634,0,0,0-3.451.594c-.639.337-.845.429-1.322.3s-.61-.31-1-.922a4.542,4.542,0,0,0-6.479-1.736c-.639.338-.844.429-1.321.3s-.61-.31-.995-.922a4.542,4.542,0,0,0-6.477-1.736c-.639.338-.844.43-1.321.3s-.609-.31-.993-.921a4.63,4.63,0,0,0-2.69-2.241c-.026-.01-.053-.018-.079-.027s-.056-.02-.086-.029-.042-.005-.063-.01a1.522,1.522,0,0,0-.186-.03c-.045,0-.09-.008-.136-.008a1.611,1.611,0,0,0-.322.029c-.026,0-.053,0-.079.011s-.045.017-.069.025a1.472,1.472,0,0,0-.151.053c-.048.02-.1.041-.142.064s-.085.049-.126.076-.088.059-.13.092-.074.066-.11.1-.072.072-.105.111-.063.081-.093.124-.056.084-.081.129a1.5,1.5,0,0,0-.066.135,1.544,1.544,0,0,0-.059.157c-.007.022-.019.042-.024.065s-.007.049-.012.074a1.52,1.52,0,0,0-.027.165,1.494,1.494,0,0,0-.01.154,1.364,1.364,0,0,0,.006.148,1.483,1.483,0,0,0,.024.172c0,.024,0,.048.01.072l2.842,11.63"
                                transform="translate(-17.851 -728.523)"
                                fill="none"
                                stroke="#2e00ff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                            <path
                                id="Path_10469"
                                data-name="Path 10469"
                                d="M37.8,754.068a46.876,46.876,0,0,0-9.808-1.836c-.494-.011-.984-.041-1.472-.028a15.985,15.985,0,0,0-14.989,11.84L2.8,796.635a8.775,8.775,0,0,0,6.2,10.746l24.782,6.641a8.775,8.775,0,0,0,10.747-6.205l8.733-32.591a15.833,15.833,0,0,0-7.5-18.006c-.79-.432-2.69-1.4-3.207-1.645"
                                transform="translate(-2.5 -738.057)"
                                fill="none"
                                stroke="#2e00ff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                        </g>
                    </svg>
                ),
                text: "Bavarfen",
                color: "#2e00ff",
                slug: "/bavarfen",
                isIcon: true,
            },
            { isIcon: false, text: "Chusen", slug: "chusen" },
            { isIcon: false, text: "Chusen,s Father", slug: "chusens-father" },
            { isIcon: false, text: "Shabbos Schedule", slug: "shabbos-schedule" },
        ],
    },
    {
        icon: <WeddingIcon />,
        text: "Wedding",
        slug: "/wedding",
        arrow: true,
        submenu: [
            {
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="#ffd100 "
                        viewBox="0 0 80.029 81.723"
                    >
                        <g id="Component_25_1" data-name="Component 25 – 1" transform="translate(1.864 1.75)">
                            <path
                                id="Path_10467"
                                data-name="Path 10467"
                                d="M514.213,776.357h0a13.836,13.836,0,0,1-12.5-15.054l3.648-24.794,24.878,2.306-.972,25.042A13.836,13.836,0,0,1,514.213,776.357Z"
                                transform="translate(-501.653 -729.294)"
                                fill="none"
                                stroke="#ffd100"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3.5"
                            />
                            <line
                                id="Line_15"
                                data-name="Line 15"
                                x1="2.79"
                                y2="30.104"
                                transform="translate(9.769 47.063)"
                                fill="none"
                                stroke="#ffd100"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3.5"
                            />
                            <line
                                id="Line_16"
                                data-name="Line 16"
                                x1="19.46"
                                y1="1.804"
                                transform="translate(0.04 76.266)"
                                fill="none"
                                stroke="#ffd100"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3.5"
                            />
                            <path
                                id="Path_10466"
                                data-name="Path 10466"
                                d="M582.9,763.845h0a13.836,13.836,0,0,1-17.95-7.788l-7.941-23.77,23.238-9.176L590.686,745.9A13.836,13.836,0,0,1,582.9,763.845Z"
                                transform="translate(-527.197 -723.112)"
                                fill="none"
                                stroke="#ffd100"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3.5"
                            />
                            <line
                                id="Line_13"
                                data-name="Line 13"
                                x2="11.103"
                                y2="28.121"
                                transform="translate(55.701 40.733)"
                                fill="none"
                                stroke="#ffd100"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3.5"
                            />
                            <line
                                id="Line_14"
                                data-name="Line 14"
                                x1="18.178"
                                y2="7.177"
                                transform="translate(57.716 65.265)"
                                fill="none"
                                stroke="#ffd100"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3.5"
                            />
                        </g>
                    </svg>
                ),
                text: "Wedding",
                color: "#ffd100",
                slug: "/wedding",
                isIcon: true,
            },
            { isIcon: false, text: "Chusen", slug: "chusen" },
            { isIcon: false, text: "Both Sides", slug: "both-sides" },
            { isIcon: false, text: "Save The Date", slug: "save-the-date" },
        ],
    },
    {
        icon: <FlowerIcon />,
        text: "Shiva Bruches",
        arrow: false,
        slug: "/sheva-bruches",
        submenu: [],
    },
    {
        icon: <VideoIcon />,
        text: "Videos",
        slug: "/videos",
        arrow: false,
        submenu: [],
    },
    {
        icon: <OtherIcon />,
        text: "Others",
        slug: "/others",
        arrow: true,
        submenu: [
            {
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="rgb(0, 230, 27)"
                        viewBox="0 0 83.629 71.853"
                    >
                        <g id="Component_28_1" data-name="Component 28 – 1" transform="translate(1.693 1.5)">
                            <path
                                id="Path_10461"
                                data-name="Path 10461"
                                d="M2056.913,785.6l-26.807-18.882c-5.21-3.67-5.21-9.619,0-13.289l26.807-18.883c5.21-3.67,13.656-3.67,18.865,0l26.808,18.883c5.209,3.67,5.209,9.619,0,13.289L2075.778,785.6C2070.569,789.269,2062.122,789.269,2056.913,785.6Z"
                                transform="translate(-2026.198 -731.793)"
                                fill="none"
                                stroke="rgb(0, 230, 27)"
                                stroke-miterlimit="10"
                                stroke-width="3"
                            />
                            <path
                                id="Path_10463"
                                data-name="Path 10463"
                                d="M2105.888,795.742c1.427,3.228.326,6.89-3.3,9.446l-26.808,18.883c-5.209,3.67-13.656,3.67-18.866,0l-26.806-18.883c-3.793-2.672-4.825-6.554-3.093-9.883"
                                transform="translate(-2026.198 -764.266)"
                                fill="none"
                                stroke="rgb(0, 230, 27)"
                                stroke-miterlimit="10"
                                stroke-width="3"
                            />
                            <path
                                id="Path_10464"
                                data-name="Path 10464"
                                d="M2105.515,807.58c1.954,3.394.977,7.428-2.93,10.181l-26.808,18.882c-5.209,3.67-13.656,3.67-18.865,0l-26.807-18.882c-3.773-2.657-4.813-6.511-3.122-9.828"
                                transform="translate(-2026.198 -770.542)"
                                fill="none"
                                stroke="rgb(0, 230, 27)"
                                stroke-miterlimit="10"
                                stroke-width="3"
                            />
                        </g>
                    </svg>
                ),
                text: "Others",
                slug: "/others",
                color: "rgb(0, 230, 27)",
                isIcon: true,
            },
            { isIcon: false, text: "חתן-בראשית", slug: "חתן-בראשית" },
            { isIcon: false, text: "Chanukah", slug: "chanukah" },
        ],
    },
];

const SimchasDropDown = ({ simchasIsHover }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    return (
        <div>
            <span
                className={`simchas-drop-down  ${
                    simchasIsHover === true
                        ? "active"
                        : simchasIsHover === false
                        ? "hidden"
                        : simchasIsHover === null
                        ? "display-none"
                        : ""
                }`}
            >
                {links.map((link, index) => (
                    <div
                        key={index}
                        className={link.arrow ? "drop-down-link-icon-wrap" : ""}
                        onMouseEnter={() => link.arrow && setHoveredIndex(index)}
                        onMouseLeave={() => link.arrow && setHoveredIndex(null)}
                    >
                        <Link className="drop-down-link" to={`categories${link?.slug}`}>
                            <span className="drop-down-icon">{link.icon}</span>
                            {link.text}
                        </Link>
                        {link.arrow && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="right-arrow"
                                width="20px"
                                height="20px"
                                viewBox="0 0 48 48"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={4}
                                    d="m19 12l12 12l-12 12"
                                ></path>
                            </svg>
                        )}
                        {hoveredIndex === index && <SubMenu subMenu={link.submenu} parentSlug={link?.slug} />}
                    </div>
                ))}
            </span>
        </div>
    );
};

export default SimchasDropDown;
