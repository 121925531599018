import React, { useEffect, useRef, useState } from "react";
import SimchaInvitaion from "../../assets/simchaInvitaion/simcha-invitation.png";
import SimchaResImg from "../../assets/simchaInvitaion/simcha-res-img.png";
import "./styles/simcha-invitation.css";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";
import Attachment from "../../assets/icons/Attachment";
import CustomModalSendIcon from "../../assets/CustomModalSendIcon";

const SimchaInvitation = () => {
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (e) => {
        // Handle file input change here if needed
        console.log("Selected file:", e.target.files[0]);
    };
    const [customModalOpen, setCustomModalOpen] = useState(false);

    useEffect(() => {
        console.log("modalOpen", customModalOpen);
    }, [customModalOpen]);
    return (
        <>
            <div className="simcha-main-container" id="custom-design">
                <div className="simcha-container">
                    <div className="simcha-text-hd-wrap">
                        <p className="simcha-hd">It’s not every day that you make a Simcha.</p>
                        <div className="simcha-text-wrap">
                            <p className="simcha-text">
                                You want an invitation as unique as your occasion and a templated invite just won’t cut
                                it.
                            </p>
                            <p className="simcha-text">
                                The maven in you wants an invitation that is custom designed for your special day.
                            </p>
                        </div>
                        <p className="simcha-sub-hd">We get it. And we’ll get it done.</p>
                        <button
                            className="simcha-button text-button"
                            data-bs-toggle="modal"
                            data-bs-target="#customModal"
                            onClick={() => {
                                setCustomModalOpen(true);
                            }}
                        >
                            Can I Have It Custom?
                        </button>
                    </div>
                    <div className="simcha-img-wrap">
                        <img className="simcha-img" src={SimchaResImg} />
                        <img className=" simcha-res-img" src={SimchaResImg} />

                        <button
                            onClick={() => {
                                setCustomModalOpen(true);
                            }}
                            className="simcha-button img-button"
                            data-bs-toggle="modal"
                            data-bs-target="#customModal"
                        >
                            Can I Have It Custom?
                        </button>
                    </div>
                </div>
            </div>
            {/* {customModalOpen && ( */}
            <div
                className={`modal fade ${customModalOpen ? "show" : ""}`}
                id="customModal"
                data-bs-backdrop="true"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!customModalOpen}
            >
                <div className="modal-dialog scrollable reach-out-modal-container">
                    <div className="modal-content">
                        <div
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close-btn"
                            onClick={() => {
                                setCustomModalOpen(false);
                            }}
                        >
                            <ModalCancelIcon />
                        </div>
                        <div className="modal-body ">
                            <p className="reach-out-hd custom-modal-hd">Custom Design</p>
                            <div className="reach-modal-input-wrap custom-modal-input-wrap">
                                <input className="reach-input" type="text" placeholder="Name" />
                                <input className="reach-input" type="email" name="email" placeholder="Email" />
                                <div className="textarea-icon-wrap">
                                    <textarea
                                        placeholder="Hi, I need a poster for..."
                                        className="reach-out-text-area custom-modal-textarea"
                                    ></textarea>
                                    <div className="reach-out-btn-wrap custom-modal-btn-wrap">
                                        <div className="date-and-file-wrap">
                                            <button className="custom-modal-attchment-btn" onClick={handleButtonClick}>
                                                <Attachment />
                                                <span>Add Attachment</span>
                                            </button>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                className="check"
                                                style={{ display: "none" }}
                                                onChange={handleFileInputChange}
                                            />
                                            <input type="date" className="inputDate" />
                                        </div>
                                        <div className="custom-modal-submit-btn-wrap">
                                            <button className="custom-modal-submit-btn">
                                                Submit <CustomModalSendIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-modal-not-wrap">
                                    <p className="custom-modal-note">
                                        {" "}
                                        <span className="note-hd">Please Note: </span>Your invitation will be custom
                                        designed for you and you will be the first one to use it. Once your Simcha has
                                        passed, it will be available for purchase in the template section.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </>
    );
};

export default SimchaInvitation;
