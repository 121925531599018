import React from "react";

const WhatsappIcon = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="whatsapp"
                width="18.435"
                height="18.521"
                viewBox="0 0 18.435 18.521"
            >
                <path
                    id="Trazado_10756"
                    data-name="Trazado 10756"
                    d="M1776.041,7.1a9.2,9.2,0,0,0-8.976-7.1,9.372,9.372,0,0,0-1.769.181,9.2,9.2,0,0,0-6.268,13.417.509.509,0,0,1,.04.414q-.591,2.153-1.178,4.3c-.064.235-.064.235.172.174,1.488-.39,2.973-.776,4.46-1.171a.431.431,0,0,1,.333.038A9.19,9.19,0,0,0,1776.041,7.1m-7.689,9.636a6.577,6.577,0,0,1-1.053.1,7.739,7.739,0,0,1-4.149-1.1.554.554,0,0,0-.472-.071c-.815.226-1.636.428-2.452.644-.172.052-.188.014-.145-.141.216-.776.42-1.548.643-2.315a.634.634,0,0,0-.083-.574,7.519,7.519,0,0,1,.271-8.549,7.633,7.633,0,0,1,13.625,2.8,7.621,7.621,0,0,1-6.186,9.2"
                    transform="translate(-1757.854 0.004)"
                    fill="#af40f7"
                    fill-rule="evenodd"
                ></path>
                <path
                    id="Trazado_10757"
                    data-name="Trazado 10757"
                    d="M1874.6,127.064a3.133,3.133,0,0,1-.955-.146,9.282,9.282,0,0,1-4.968-3.67,5.268,5.268,0,0,1-1.075-2.145,2.576,2.576,0,0,1,.832-2.484,1.5,1.5,0,0,1,1.22-.169.475.475,0,0,1,.215.245c.267.635.53,1.261.792,1.9a.442.442,0,0,1-.027.362,2.881,2.881,0,0,1-.568.757.377.377,0,0,0-.05.527,6.3,6.3,0,0,0,3.126,2.686.372.372,0,0,0,.487-.118c.225-.278.462-.555.675-.842a.374.374,0,0,1,.519-.137c.446.2.891.414,1.338.626.1.042.2.094.3.141.452.226.447.231.408.729a1.953,1.953,0,0,1-1.672,1.694,1.928,1.928,0,0,1-.593.047"
                    transform="translate(-1862.983 -113.457)"
                    fill="#af40f7"
                    fill-rule="evenodd"
                ></path>
            </svg>
        </div>
    );
};

export default WhatsappIcon;
