import React from "react";

const CartIcon = () => {
    return (
        <div>
            {" "}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 20.035 20.052"
            >
                <g id="Cart" transform="translate(0.75 0.75)">
                    <path
                        id="Path_10477"
                        data-name="Path 10477"
                        d="M31.5,31.5A1.5,1.5,0,1,1,30,30,1.5,1.5,0,0,1,31.5,31.5Z"
                        transform="translate(-15.368 -13.698)"
                    />
                    <path
                        id="Path_10483"
                        data-name="Path 10483"
                        d="M31.5,31.5A1.5,1.5,0,1,1,30,30,1.5,1.5,0,0,1,31.5,31.5Z"
                        transform="translate(-22.101 -13.698)"
                    />
                    <path
                        id="Path_10478"
                        data-name="Path 10478"
                        d="M1.5,1.5H4.87L7.128,12.781a1.685,1.685,0,0,0,1.685,1.356H17a1.685,1.685,0,0,0,1.685-1.356l1.348-7.069H5.713"
                        transform="translate(-1.5 -1.5)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                </g>
            </svg>
        </div>
    );
};

export default CartIcon;
