import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// requests
import purchasesRequests from '../requests/purchases';
// utils
import { getUrlQuery } from '../utils/url';

const EditPurchaseContext = createContext();

const MAX_COUNT = 3; // maximun edit 

// action reducer types
const EDIT_INPUT_TEXT = 'edit-input-text';
const EDIT_SONGS = 'edit-songs';
const EDIT_QR = 'edit-qr';
const RESET = 'edit-reset';
const SET_TO_MAX_COUNT = 'edit-max-count';
const SET_COUNT = 'edit-count';

const initialState = {
    inputsTextIds: [], 
    songsEnabled: false, 
    qrEnabled: false, 
    editCount: 0,
    maxEditCount: MAX_COUNT,
    isTimeValid: true
}

function editPurchaseReducer(state, action) {
    const { editCount, inputsTextIds } = state;

    switch (action.type) {
        case EDIT_INPUT_TEXT: {
            const { fieldId } = action.payload;
            return {
                ...state,
                inputsTextIds: [...inputsTextIds, fieldId],
                editCount: editCount + 1
            }
        }

        case EDIT_SONGS: {
            const { enabled } = action.payload;
            return {
                ...state,
                songsEnabled: enabled,
                editCount: editCount + 1
            }
        }

        case EDIT_QR: {
            const { enabled } = action.payload;
            return {
                ...state,
                qrEnabled: enabled,
                editCount: editCount + 1
            }
        }

        case SET_COUNT: {
            const { count, isTimeValid } = action.payload;
            return {
                ...state, 
                editCount: count,
                isTimeValid
            }
        }

        case SET_TO_MAX_COUNT: {
            return {
                ...state, 
                editCount: MAX_COUNT,
            }
        }

        case RESET: {
            return { ...initialState }
        }
    
        default:
            return state;
    }
}


const EditPurchaseProvider = ({ children }) => {
    const [state, dispath] = useReducer(editPurchaseReducer, initialState);
    const { pathname } = useLocation();

    useEffect(() => {
        const { cart_item_key, order_id } = getUrlQuery();

        if(!pathname.includes('edit-purchase')){
            dispath({  type: RESET });
        }
        else{
            // check if user can edit
            if(cart_item_key && order_id){
                purchasesRequests.getEditCount({ wooCartItemKey: cart_item_key, orderId: order_id })
                    .then( data => {
                        if(!data){
                            dispath({ type: SET_TO_MAX_COUNT })
                        }
                        else{
                            if(data.fieldsEditedCount >= MAX_COUNT){
                                dispath({ type: SET_TO_MAX_COUNT })
                            }else{
                                dispath({ 
                                    type: SET_COUNT, 
                                    payload: { 
                                        count: data.fieldsEditedCount || 0,
                                        isTimeValid: data.isTimeValid
                                    } 
                                })
                            }
                        }
                    })
                    .catch( err => {
                        console.error("can't fetch product edit count", err);
                        dispath({ type: SET_TO_MAX_COUNT })
                    })
            }
        }
    }, [pathname]);

    return (
        <EditPurchaseContext.Provider value={{
            state,
            dispath
        }}>
            {children}
        </EditPurchaseContext.Provider>
    );
}


// custom hook
function useEditPurchaseHook() {
    const { state, dispath } = useContext(EditPurchaseContext);
    
    function enableTextField(fieldId) {
        dispath({
            type: EDIT_INPUT_TEXT,
            payload: { fieldId }
        });
    }

    function enableSongs(enabled) {
        dispath({ 
            type: EDIT_SONGS, 
            payload: { enabled } 
        });
    }

    function enableQR(enabled) {
        dispath({ 
            type: EDIT_QR, 
            payload: { enabled }
        });
    }

    function reset() {
        dispath({  type: RESET });
    }

    return {
        enableTextField,
        enableSongs,
        enableQR,
        reset,
        // I use the state.prop instead ...state for using the vscode autocompletation on components
        inputsTextIds: state.inputsTextIds, 
        songsEnabled: state.songsEnabled, 
        qrEnabled: state.qrEnabled, 
        editCount: state.editCount,
        maxEditCount: state.maxEditCount,
        isTimeValid: state.isTimeValid
    }
}


export { 
    EditPurchaseProvider,
    useEditPurchaseHook
};