import React from "react";
import "./style.css";
import Price from "../../assets/convenienceSimchas/price";
import Friendly from "../../assets/convenienceSimchas/friendly";
import Selection from "../../assets/convenienceSimchas/selection";
import useViewportWidth from "../../hooks/useViewportWidth";

const ConvenienceSimcha = () => {
    const width = useViewportWidth();
    return (
        <div className="convenience-simcha-container">
            <div className="convenience-simcha-sub-container">
                <p className="convenience-hd">Inviting Convenience into your Simcha</p>
                <p className="convenience-bold-text">
                    Hazmoona was founded with one goal: To help you spread joy with convenience and class.
                </p>
                <p className="convenience-text">
                    A Simcha is a stressful time. There are so many technicalities to arrange and details to take care
                    of. Leave the invitations to Hazmoona without sacrificing on your standards. Just select the
                    template you love, download it, and send it to your family and friends.
                </p>
                <p className="convenience-sub-hd">LET THERE BE JOY!</p>

                <div className="card-container">
                    <div className="card-wrap">
                        <div className="img-wrap">
                            <Selection
                                width={width > 1024 ? "100px" : width > 550 ? "50px" : "40px"}
                                height={width > 1024 ? "100px" : width > 550 ? "50px" : "40px"}
                            />
                        </div>
                        <p className="card-text"> Large Selection</p>
                    </div>
                    <div className="card-wrap">
                        <div className="img-wrap">
                            <Friendly
                                width={width > 1024 ? "100px" : width > 550 ? "50px" : "40px"}
                                height={width > 1024 ? "100px" : width > 550 ? "50px" : "40px"}
                            />
                        </div>
                        <p className="card-text"> User Friendly</p>
                    </div>
                    <div className="card-wrap">
                        <div className="img-wrap">
                            <Price
                                width={width > 1024 ? "100px" : width > 550 ? "50px" : "40px"}
                                height={width > 1024 ? "100px" : width > 550 ? "50px" : "40px"}
                            />
                        </div>
                        <p className="card-text"> Reasonably Priced</p>
                    </div>
                </div>
                <div className="pick-shick-container">
                    <p className="pick-shick-hd">Invite them and Delight them</p>
                    <a href="#chooseCategorysec" className="pick-shick-button">Pick, Click And Shick</a>
                </div>
            </div>
        </div>
    );
};

export default ConvenienceSimcha;
