import React, { useRef, useState } from "react";
import WrappedIfield from "./wrapped-ifield"; // Assuming this is your file path
import { CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import { format } from "date-fns";
import { API_BASE_URL } from "../../config";
import { getCustomerID } from "../../utils/token";

export default function AppForm({total, products}) {
    const [cardToken, setCardToken] = useState("");
    const [cvvToken, setCvvToken] = useState("");
    const [issuer, setIssuer] = useState("");
    const [amount, setAmount] = useState(2.36);
    const [firstName, setFirstName] = useState("John");
    const [lastName, setLastName] = useState("Doe");
    const [address, setAddress] = useState("123 Somewhere");
    const [city, setCity] = useState("Anywhere");
    const [addressState, setAddressState] = useState("NY");
    const [zip, setZip] = useState("98765");
    const [mobile, setMobile] = useState("1234567890");
    const [email, setEmail] = useState("test@test.com");
    const [expiryDate, setExpiryDate] = useState(""); // MM/YY format
    const [gatewayResponse, setGatewayResponse] = useState("");
    const cardRef = useRef();
    const cvvRef = useRef();

    const handleCardToken = (data) => {
        setCardToken(data.xToken);
    };

    const handleCvvToken = (data) => {
        setCvvToken(data.xToken);
    };

    const handleExpiryDateChange = (e) => {
        let input = e.target.value.replace(/\D/g, "");
        if (input.length > 2) {
            input = input.slice(0, 2) + "/" + input.slice(2);
        }
        setExpiryDate(input);
    };
    const submitToGateway = async (e) => {
        e.preventDefault();
        setGatewayResponse("");

        try {
            await cardRef.current.getToken();
            await cvvRef.current.getToken();

            if (!cardToken || !cvvToken) {
                throw new Error("Failed to retrieve card or CVV token");
            }

            let request = {
                xSoftwareName: "Test-React-iFields",
                xSoftwareVersion: "1.0",
                xVersion: "5.0.0",
                xCommand: "cc:sale",
                xAmount: 31,
                xCardnum: cardToken,
                xBillFirstName: firstName,
                xBillLastName: lastName,
                xBillStreet: address,
                xBillCity: city,
                xBillZip: zip,
                xBillState: addressState,
                xBillMobile: mobile,
                xEmail: email,
                xExp: expiryDate.replace("/", ""),
                xCvv: cvvToken,
                xAllowDuplicate: "TRUE"
            };

            const response = await fetch(`${API_BASE_URL}/cart/process-payment`, {
                method: "POST",
                body: JSON.stringify({ card: request, customer: getCustomerID(), products: products, }),
                headers: { "Content-Type": "application/json" },
            });

            const responseBody = await response.json();
            setGatewayResponse(responseBody);
        } catch (error) {
            console.error("Error during payment submission:", error);
            setGatewayResponse({ message: error.message });
        }
    };

    return (
        <div>
            <section className="hero is-primary">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">Checkout</h1>
                        <h4 className="subtitle">Please enter your credit card information</h4>
                    </div>
                </div>
            </section>
            <div className="main">
                <form onSubmit={submitToGateway}>
                    <div className="columns">
                        <div className="column">
                            <section className="box card-box">
                                <div className="field d-none">
                                    <label className="label">Amount</label>
                                    <input
                                        className="input"
                                        type="text"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <p className="label">Card Numbers</p>
                                    <WrappedIfield
                                        ifieldType={CARD_TYPE}
                                        onIssuer={setIssuer}
                                        onToken={handleCardToken}
                                        ref={cardRef}
                                    />
                                </div>

                                <div className="field">
                                    <p className="label">Expiry Date</p>
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="MM/YY"
                                        value={expiryDate}
                                        onChange={handleExpiryDateChange}
                                        maxLength={5}
                                        required
                                    />
                                </div>

                                <div className="field">
                                    <p className="label">CVV</p>
                                    <WrappedIfield ifieldType={CVV_TYPE} onToken={handleCvvToken} ref={cvvRef} />
                                </div>

                                <div className="field">
                                    <p className="label"> Name on card </p>
                                    <input className="input" type="text" />
                                </div>

                                <div className="field">
                                    <p className="label">Zid </p>
                                    <input className="input" type="text" />
                                </div>
                            </section>
                        </div>
                        <div className="column">
                            <section className="box result-box">
                                <div className="field">
                                    <p className="label">Card Token</p>
                                    <p className="token-field">{cardToken}</p>
                                </div>
                                <div className="field">
                                    <p className="label">CVV Token</p>
                                    <p className="token-field">{cvvToken}</p>
                                </div>
                                <div className="field">
                                    <p className="label">Gateway Response</p>
                                    <p className="token-field">{JSON.stringify(gatewayResponse)}</p>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="total-price-container">
                        <p className="total-price">{`Total \$${total}`}</p>
                    </div>

                    <div className="place-order-container">
                        <button className="place-order-btn" type="submit">
                            Place Order
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
