import axios from "axios";
import { API_BASE_URL } from "../config";
import { getToken } from "../utils/token";

const payment = {};

const setDefaultOptions = ({ headers = {}, token, cancelToken }) => {
    const defaultHeaders = {
        "Content-Type": "application/json",
        ...headers,
    };
    if (token) {
        const authToken = getToken();
        if (authToken) {
            defaultHeaders["Authorization"] = `Bearer ${authToken}`;
        }
    }
    return {
        headers: defaultHeaders,
        ...(cancelToken && { cancelToken }),
    };
};

payment.processPayment = async function (paymentData) {
    // const url = `${API_BASE_URL}/cart/process-payment`;
    const url = `${API_BASE_URL}/cart/process-payment`;
    
    try {
        const response = await axios.post(url, paymentData, setDefaultOptions({ token: true }));
        return response.data;
    } catch (error) {
        console.error("Payment request failed: ", error.response?.data || error.message);
        throw error;
    }
};

export default payment;
