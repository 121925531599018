function _getUrlParams(href) {
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    const query = {};

    params.forEach((value, key) => {
        // if key already exists in query object is because is an array[]
        if (query[key]) {
            query[key].push(value);
        } else {
            if (key.includes("[]")) {
                // init array
                query[key] = [value];
            } else {
                query[key] = value;
            }
        }
    });

    return query;
}

function getUrlQuery(justHref = false) {
    // get iframe href if the page is inside a iframe
    let href;
    if (document.querySelector("iframe")) {
        href = document.querySelector("iframe").src;
    } else {
        href = window.location.href;
    }
    if (justHref) return href;
    return _getUrlParams(href);
}

function getUrlQueryFromWordpress() {
    const href = window.parent?.location?.href;
    console.log("the href");
    if (!href) return {};

    return _getUrlParams(href);
}

export { getUrlQuery, getUrlQueryFromWordpress };
