// import React from 'react';
// import { Row, Col,Container } from 'reactstrap';
// import { Link } from 'react-router-dom';

// class Footer extends React.Component {
//     componentDidMount()
//     {
//         window.addEventListener('scroll',this.Checkscroll);
//     }
//     componentWillUnmount()
//     {
//         window.removeEventListener('scroll',this.Checkscroll);
//     }

//     Checkscroll()
//     {

//         var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
//         document.body.scrollTop;

//         if(scrollTop > 350)
//         {
//             if(document.getElementById("back-to-top") != null)
//             {
//                 document.getElementById("back-to-top").setAttribute("style","display:block");
//             }
//         }
//         else
//         {

//             if(document.getElementById("back-to-top") != null)
//             {
//                 document.getElementById("back-to-top").setAttribute("style","display:none");
//             }
//         }

//     }
//     ClicktoTop()
//     {
//         window.scroll({top: 0, left: 0, behavior: 'smooth' })
//     }
//     render() {
//         let backtotop = {display : 'none'}
//         return (
//         <div>
//             <footer className="site-footer">
//                 <div className="footer-wrapper">
//                     <div className="site-info">
//                         <div className="footer-widget">
//                             <Container>
//                                 <div className="row align-items-center">
//                                     <Col md={6} className="float-left">
//                                         <p> © Copyright 2023 <Link to="#">CiyaShop</Link> All Rights Reserved.</p>
//                                     </Col>
//                                     <Col md={6} className="float-right">
//                                         <div className="payments text-right">
//                                             <img src={require(`../../assets/images/payments.png`)} className="img-fluid" alt="sometxt" />
//                                         </div>
//                                     </Col>
//                                 </div>
//                                 <div className="clearfix" />
//                             </Container>
//                         </div>
//                     </div>
//                 </div>
//             </footer>
//             <div id="back-to-top" style={backtotop} onClick={this.ClicktoTop}>
//                 <Link to="#" className="top arrow">
//                         <i className="fa fa-angle-up"></i>
//                 </Link>
//             </div>
//         </div>
//         )
//     }
// };
// export default Footer;

import React from "react";
import "./style.css";
import FooterLogo from "../../assets/logo/footer-logo";

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-sub-container">
                <div className="footer-text-wrap">
                    <p>© 2023 Hazmoona. All rights reserved.</p>
                </div>
                <div className="footer-img-wrap">
                    <FooterLogo />
                </div>
            </div>
        </div>
    );
};

export default Footer;
