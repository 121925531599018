/**
 * Shop Page Side Bar Filter
 */
import React, { memo, useLayoutEffect, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { hexToCSSFilter } from "hex-to-css-filter";
// utils
import { getUrlQuery } from "../../utils/url";
import { hasHebrewCharacters } from "../../utils/text";
// hooks
import { useCategories } from "../../context/categoriesContext";
import "./styles/sideFilters.css";
import useLocalStorage from "../../hooks/useLocalStorage";

const whiteFilter = hexToCSSFilter("#ffffff").filter.replace(";", "");

const CateogryFilterItem = memo(
    ({
        categoryName,
        subCategories,
        categoryColor,
        friendlyUrlName,
        categoryIcon,
        onSubCategoryChange,
        onTagChecked,
        tagsByCategory,
    }) => {
        const params = useParams();
        const { categoryFriendlyName } = params;
        // console.log("category: ", params, categoryFriendlyName);
        const ulRef = useRef();
        const { categories } = useCategories();
        const [subCategoriesChecked, setSubCategoriesChecked] = useState({});
        const [tagsChecked, setTagsChecked] = useState({});
        const [userWPId, setUserWPId] = useState(0);
        const location = useLocation();

        const isActive = friendlyUrlName === categoryFriendlyName;
        const hasSubcategories = !!subCategories?.length;
        const hasTags = !!tagsByCategory?.length;
        // console.log("hasSubcategories ====>" ,subCategories)

        const setActiveClassName = () => (isActive ? "item-active" : "");

        // animations and UI changes
        useLayoutEffect(() => {
            // expand list animation
            const ul = ulRef.current;

            if (isActive) {
                if (!hasSubcategories || !hasTags) {
                    ul.style.height = 0;
                    ul.style.removeProperty("height");
                } else {
                    ul.style.transition = ".2s";
                    ul.style.height = ul.scrollHeight + "px";
                }
            } else {
                ul.style.height = 0;
                ul.style.removeProperty("height");
            }
            const currentCategory = categories.find((c) => c.friendlyUrlName === categoryFriendlyName);
            // console.log("currentCategory",userWPId)
            document.getElementById("site-content").style.backgroundColor =
                currentCategory?.backgroundColor || "#f2f2f2";
        }, [categoryFriendlyName, hasSubcategories, hasTags]);
        // reset all checkboxes to unchecked when changing main category
        useEffect(() => {
            const _subCategoriesChecked = {};
            const _tagsChecked = {};
            for (const key of Object.keys(subCategoriesChecked)) {
                _subCategoriesChecked[key] = false;
            }
            for (const key of Object.keys(tagsChecked)) {
                _tagsChecked[key] = false;
            }
            setSubCategoriesChecked((prev) => ({ ...prev, ..._subCategoriesChecked }));
            setTagsChecked((prev) => ({ ...prev, ..._tagsChecked }));
        }, [categoryFriendlyName]);

        // set checkbox to checked if there is a subcategory selected by wordpress nav
        useEffect(() => {
            if (categories.length) {
                const { sub } = getUrlQuery();
                if (sub) {
                    const currentCategory = categories.find((c) => c.friendlyUrlName === categoryFriendlyName);
                    if (currentCategory) {
                        const currentSub = currentCategory?.subCategories?.find((c) => c.friendlyUrlName === sub);
                        setSubCategoriesChecked({ [currentSub?._id]: true });
                        onSubCategoryChange(currentSub._id);
                    }
                }
            }
        }, [categories, params]);

        // save user_id
        useEffect(() => {
            const { user_id } = getUrlQuery();
            setUserWPId(user_id || 0);
        }, []);

        const handleSubcatCheckbox = (subCatId) => {
            setSubCategoriesChecked((prev) => ({
                ...prev,
                [subCatId]: !prev[subCatId],
            }));
        };

        const handleTagCheckbox = (tagName) => {
            setTagsChecked((prev) => ({
                ...prev,
                [tagName]: !prev[tagName],
            }));
        };

        const handleClick = () => {
            localStorage.setItem("logoColor", categoryColor);
        };

        return (
            <div className="category-filter-item">
                <Link
                    to={`/categories/${friendlyUrlName}?user_id=${userWPId}`}
                    className={`principal-category-filter-link ${setActiveClassName()}`}
                    style={{ backgroundColor: isActive ? categoryColor : "#fff" }}
                    onClick={() => {
                        handleClick();
                    }}
                >
                    <div className="cat-logo-flex">
                        {categoryIcon && (
                            <img
                                src={categoryIcon}
                                alt="icon"
                                style={{
                                    filter: isActive ? whiteFilter : "none",
                                }}
                            />
                        )}
                        <div>{categoryName}</div>
                    </div>
                    {isActive && (hasSubcategories || hasTags) && (
                        <small className="align-self-center mt-1">
                            <small>
                                <i className="fa fa-chevron-up" />
                            </small>
                        </small>
                    )}
                </Link>

                {
                    <ul
                        className={`subcategories-ul ${setActiveClassName()} ${
                            hasSubcategories || hasTags ? "hasSubcategories" : ""
                        }`}
                        tabIndex={0}
                        ref={ulRef}
                    >
                        {subCategories.map((subCat) => {
                            return (
                                <div className={`subcategory-check-container`} key={subCat._id}>
                                    <label
                                        className={`form-check-label ${
                                            hasHebrewCharacters(subCat.name) ? "hebrew-label-font" : ""
                                        }`}
                                        htmlFor={subCat._id}
                                    >
                                        {subCat.name}
                                    </label>
                                    <input
                                        type="checkbox"
                                        onClick={() => onSubCategoryChange(subCat._id)}
                                        onChange={() => handleSubcatCheckbox(subCat._id)}
                                        value={subCat._id}
                                        id={subCat._id}
                                        checked={!!subCategoriesChecked[subCat._id]}
                                    />
                                </div>
                            );
                        })}
                        {isActive &&
                            tagsByCategory?.map((tagCategory, index) => (
                                <React.Fragment key={tagCategory._id}>
                                    {(hasSubcategories || index !== 0) && <hr className="mx-4 border-dark" />}
                                    {tagCategory.tags.map((item) => (
                                        <div key={item._id} className="subcategory-check-container">
                                            <label
                                                className={`form-check-label ${
                                                    hasHebrewCharacters(item.tag) ? "hebrew-label-font" : ""
                                                }`}
                                                htmlFor={item._id}
                                            >
                                                {item.tag}
                                            </label>
                                            <input
                                                id={item._id}
                                                type="checkbox"
                                                value={item._id}
                                                name={tagCategory.name}
                                                onClick={() => onTagChecked(item.tag)}
                                                onChange={() => handleTagCheckbox(item.tag)}
                                                checked={!!tagsChecked[item.tag]}
                                            />
                                        </div>
                                    ))}
                                </React.Fragment>
                            ))}
                    </ul>
                }
            </div>
        );
    }
);

export default CateogryFilterItem;
