import React, { Component } from "react";
import Hero from "../Hero/hero.jsx";
import Category from "../Categories/category.jsx";
import ConvenienceSimcha from "../ConvenienceSimcha/ConvenienceSimcha.jsx";
import Footer from "../../layouts/footer/Footer.js";

class HomePage extends Component {
    // componentDidMount() {
    //     window.scrollTo(0, 0);
    // }


    render() {
        return (
            <>
                <Hero />
                <Category />
                <ConvenienceSimcha/>
                <Footer/>
            </>
        );
    }
}

export default HomePage;
