import React, { useEffect, useState } from 'react';
// custom components
import Loader from '../Loader/customLoader';
import { Spinner } from 'reactstrap'
// utils
import { getUrlQuery } from '../../utils/url';
import {
    initCanvas,
    createPDFAndDownloadV2,
    generateVideoAndGetURL,
    getSmallerCanvasBase64ImageForJPEG,
    // getPosterbase64Image
} from '../../utils/handleCreateFiles';
import { forceRenderUpdate } from '../../utils/canvas';
import { downloadVideo, downloadImage } from '../../utils/downloadManager';
import saveAutocompleteValues from '../../utils/saveAutoCompleteValues';
import isMobile from '../../utils/detectMobile';
// import emulateMessageDebugger from '../../utils/emulateDebugOnIframe';
// requests
import orderRequests from '../../requests/orders';
//style
import './style/downloads.css';
// images
import imageIcon from '../../assets/images/icons/image-icon.svg';
import pdfIcon from '../../assets/images/icons/file-icon.svg';
import videoIcon from '../../assets/images/icons/videocam-icon.svg';
// import mockdata from '../../mocks/order_data_mockup';
// import mockdata from '../../mocks/mockdata';
// import mockdata from '../../mocks/mockdata2';

const Downloads = () => {
    const [orderItemData, setOrderItemData] = useState({});
    // const [pdfSizes, setPdfSizes] = useState([]);
    const [pdfTagSize, setPdfTagSize] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);
    const [videoUrlCreated, setVideoUrlCreated] = useState('');
    const [isCreatingVideo, setIsCreatingVideo] = useState(false);
    const [previewImageState, setPreviewImageState] = useState({ loading: true, imageUrl: '' });

    // get order data
    useEffect(() => {
        const { order_id, order_item_id, cart_item_key } = getUrlQuery();

        if (order_item_id) {
            orderRequests.getOrderItem({
                woo_cart_item_key: cart_item_key,
                woo_order_id: order_id,
                woo_order_item_id: order_item_id
            }).then(data => {
                setOrderItemData(data);
                setDataLoading(false);

                const { QRText, canvasObjects, productFilesId, productName } = data.customization_data;
                // init canvas with original image
                initCanvas({
                    canvasObjects,
                    QRText,
                    product: { productFilesId, productName }
                }).then(async (tempCanvas) => {
                    await forceRenderUpdate(tempCanvas, true, false, true);

                    console.log('canvas initialized');
                    const previewImgBase64 = tempCanvas.toDataURL({
                        format: 'jpeg'
                    });

                    setPreviewImageState({ loading: false, imageUrl: previewImgBase64 });
                    
                    saveAutocompleteValues(tempCanvas)
                        .then(() => console.log('autocomplete values saved'))
                        .catch((err) => console.log('Saving autocomplete values failed', err));
                })
            })

            // // for testing on localhost
            // const data = mockdata.data;
            // setOrderItemData(data);
            // setDataLoading(false);
            // const { QRText, canvasObjects, productFilesId, productName } = data.customization_data;
            // // init canvas with original image
            // initCanvas({
            //     canvasObjects,
            //     QRText,
            //     product: { productFilesId, productName }
            // }).then(async (tempCanvas) => {
            //     await forceRenderUpdate(tempCanvas, true, false, true);

            //     console.log('canvas initialized');
            //     const previewImgBase64 = tempCanvas.toDataURL({
            //         format: 'jpeg'
            //     });

            //     setPreviewImageState({ loading: false, imageUrl: previewImgBase64 });
            // })
        }
    }, []);

    // set poster sizes based on tags
    useEffect(() => {
        if(orderItemData?.customization_data){
            const tags = orderItemData?.customization_data?.tags || [];
            if(tags.length){
                const tagDimension = tags.join(' ').match(/\d+\.?\d+x\d+\.?\d+/i); // dimensions like 8.5x11 or 11x8.5
                if(tagDimension){
                    const [width, height] = tagDimension[0].split(/x/i).map(Number);
                    setPdfTagSize({ width, height });
                }
            }
        }
    }, [orderItemData]);

    const downloadAs = async (format) => {
        if(previewImageState.loading){
            return;
        }
        const productName = orderItemData?.customization_data?.productName || 'file';

        if(format === 'jpeg' || format === 'png'){
            try {
                const base64Image = await getSmallerCanvasBase64ImageForJPEG();
    
                if(isMobile.iOS()){
                    downloadImage(base64Image, productName, 'jpeg');
                }else{
                    let img = base64Image;
                    const a = document.createElement('a');
                    a.download = productName + '.' + 'jpg'
                    a.href = img;
                    a.click();
                }
                return;

            } catch (err) {
                console.error(err.message);                
            }
        }

        if(format === 'video'){
            if(isCreatingVideo) return;

            try {
                if(!videoUrlCreated){
                    setIsCreatingVideo(true);
                    const imgNameExt = orderItemData?.customization_data?.watermarkImage || 'image.jpg';
                    const song = orderItemData?.customization_data?.song || null;
                    const videoUrl = await generateVideoAndGetURL(imgNameExt, song);
                    // actually download
                    await downloadVideo(videoUrl, productName);
                    setVideoUrlCreated(videoUrl);
                    setIsCreatingVideo(false);
                }
                else{
                    // actually download
                    await downloadVideo(videoUrlCreated, productName);
                }
                
            } catch (err) {
                console.error('Error creating video', err.message);
                setIsCreatingVideo(false);
            }

        }
    }

    const downloadAsPdf = () => {
        const productName = orderItemData?.customization_data?.productName || 'download';
        createPDFAndDownloadV2(productName, pdfTagSize);
    }

    // dp = download page
    return (
        <div className="dp__container downloads-class page-class">
            <div className="dp__title">Download your card</div>

            <div className="dp__buttons">
                <div id="dp__download-overlay" style={{ display: previewImageState.loading ? 'flex' : 'none' }}>
                    <Loader width={100} height={100} />
                </div>
                {/* pdf button */}
                <div className="dp__btn-item">
                    <div
                        className={`dp__format-button ${previewImageState.loading ? 'disabled' : ''}`}
                        onClick={downloadAsPdf}
                    >
                        <img src={pdfIcon} alt="pdf icon" className="dp_icon_btn_img" />
                        <div className="dp__format-text">PDF</div>
                    </div>
                    <div className="dp__btn-item-description">
                        for print
                    </div>
                </div>
                {/* jpg button */}
                <div className="dp__btn-item">
                    <div
                        onClick={() => downloadAs('jpeg')}
                        className={`dp__format-button ${previewImageState.loading ? 'disabled' : ''}`}
                    >
                        <img src={imageIcon} alt="png icon" width={25} />
                        <div className="dp__format-text">JPG</div>
                    </div>
                    <div className="dp__btn-item-description">
                        for screens
                    </div>
                </div>

                {/* video button */}
                {
                    orderItemData?.customization_data?.song && (
                        <div className="dp__btn-item">
                            <div
                                className={`dp__format-button ${(previewImageState.loading || isCreatingVideo) ? 'disabled' : ''}`}
                                onClick={() => downloadAs('video')}
                            >
                                <img src={videoIcon} alt="video icon" className="dp_icon_btn_img" />
                                <div className="dp__format-text">MP4</div>

                                {
                                    isCreatingVideo && (
                                        <div id="video-load-overlay"> <Spinner type="border" style={{ color: '#af40f7' }} /> </div>
                                    )
                                }
                            </div>

                            <div className="dp__btn-item-description">
                                Video
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Downloads;