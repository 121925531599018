/**
 *  WishList Page Set
 */
import React, { useEffect, useRef } from "react";
import { WP_BASE_URL } from "../../config";
import "react-toastify/dist/ReactToastify.min.css";
// custom hooks
import { useWishlist } from "../../context/wishlistContext";
// custom components
import WishListItem from "./WishListItem";
import CircleLoader from "../Loader/circleLoader";
// utils
import postToParent from "../../utils/postToParent";
// styles
import "./styles/wishlist.css";

const WishList = () => {
    const { wishlistItems, wishlistQty, isLoading } = useWishlist();
    
    const containerRef = useRef();
    const heightChangedRef = useRef(false);

    const backHomeLinkUrl = () => {
        // link in case we are testing on vercel or localhost
        const vercelLink = "/";
        return /(vercel\.app|localhost)/.test(window.location) ? vercelLink : WP_BASE_URL;
    };

    // THIS CODE IS ONLY FOR USING WITH WORDPRESS
    // REMOVE IT IF WORDPRESS IS NOT USED ANYMORE
    // adjust the wishlist list height - wordpress .ast-container
    // this is a quick fix, need to improve wordpress css or simply don't use wordpress anymore
    useEffect(() => {
        function adjustHeight() {
            if (!isLoading && wishlistQty > 0 && !heightChangedRef.current) {
                // wait a little bit for loading thumbnails
                setTimeout(() => {
                    const height = Math.ceil(containerRef.current.getBoundingClientRect().height);
                    postToParent({
                        type: "modify-wishlist-height",
                        newHeight: height,
                    });
                }, 1500);

                heightChangedRef.current = true;
            }
        }

        adjustHeight();
    }, [isLoading, wishlistQty]);

    if (isLoading)
        return (
            <div className="site-content wishlist-page">
                <div className="flex-center">
                    <CircleLoader />
                </div>
            </div>
        );

    return (
        <div className="wishlist-page">
            <div className="wishlist-hd">
                <p>Wishlist</p>
            </div>
            {wishlistQty > 0 ? (
                <div className="content-wrapper wishlist-wrapper" ref={containerRef}>
                    {Object.keys(wishlistItems).map((itemId) => (
                        <WishListItem product={wishlistItems[itemId]} key={itemId} />
                    ))}
                </div>
            ) : (
                <div className="wishlist-empty">
                    <h3>you don't have items on your Wishlist yet.</h3>
                    <div className="wishlist-return-home">
                        <a href={backHomeLinkUrl()} target="_parent">
                            Back Home
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WishList;
