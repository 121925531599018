import { hasHebrewCharacters, reverseNumbers } from './text';
/**
 * Get all the custom properties added for each canvas object
 */

function canvasObjsToJsonStr(canvas, reverseHebrewWithNumbers = false) {
    const objs = [];

    const keysToCopy = [
        'canvasWidth',
        'canvasHeight',
        'originalImageWidth',
        'originalImageHeight',
        'originalTextLength',
        'originalLinesLength',
        'maxFontSize',
        'maxWidth',
        'multiLine',
        'label', 
        'autocompleteLabel', 
        'productId', 
        'lockScalingY', 
        'oldVisible',
        '_id',
        'isUpperCase',
        'beforeText',
        'afterText',
        'originalText'
    ]

    const extractValues = originalObj => {
        const obj = {};
        keysToCopy.forEach( key => { obj[key] = originalObj[key] });
        return obj;
    }

    for (const originalObj of canvas.getObjects()) {
        const valuesExtracted = extractValues(originalObj);
        // for some reason when you want to spread objects (...{}) from canvas.getObjects()
        // the properties you added are missing, so a fix for this is copy the original object array
        let _obj = JSON.parse(JSON.stringify(originalObj));
        // reverse numbers in hebrew text (because fabricjs doesn't have full support for rtl text and numbers at the same string)
        const txt = _obj.text;
        if(reverseHebrewWithNumbers && hasHebrewCharacters(txt) && /[0-9]/.test(txt)){
            _obj.text = reverseNumbers(txt);
            console.log('text with numbers, reversed');
        }

        objs.push({
            ..._obj,
            ...valuesExtracted
        })
    }

    return JSON.stringify(objs);
}

export default canvasObjsToJsonStr;