import axios from "axios";
import { API_BASE_URL, WP_API_BASE_URL } from "../config";
// utils
import { JSON2FormData } from "../utils/formData";
import { setDefaultOptions } from "../utils/common";

const orderRequests = {};

orderRequests.getOrderItem = async function ({ woo_order_id, woo_order_item_id, woo_cart_item_key }) {
    const formData = JSON2FormData({
        woo_order_id,
        woo_order_item_id,
        woo_cart_item_key,
    });

    try {
        const response = await fetch(`${WP_API_BASE_URL}/get_order_item.php`, {
            method: "POST",
            body: formData,
            credentials: "include",
        });

        const json = await response.json();
        return json.data;
    } catch (err) {
        console.log("Error getting order item in wordpress");
        console.log(err);
        return false;
    }
};

orderRequests.updateOrderItem = async function ({
    woo_order_id,
    woo_order_item_id,
    woo_cart_item_key,
    customization_data,
    customized_item_preview,
}) {
    const formData = JSON2FormData({
        woo_order_id,
        woo_order_item_id,
        woo_cart_item_key,
        customization_data,
        customized_item_preview,
    });

    try {
        const response = await fetch(`${WP_API_BASE_URL}/update_order_item.php`, {
            method: "POST",
            body: formData,
            credentials: "include",
        });

        const json = await response.json();
        return json.data;
    } catch (err) {
        console.log("Error updating order item in wordpress");
        console.log(err);
        return false;
    }
};

orderRequests.getOrderItems = async function ({customerId}){
    const url = `${API_BASE_URL}/orders/all?customerId=${customerId}`;

    try {
        const response = await axios.get(url, setDefaultOptions({ token: true }));

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
}

export default orderRequests;
