import ajax from "./ajax";
import { API_BASE_URL } from "../config";

const productRequests = {};

productRequests.getProduct = async function ({ productId, wooProductId }) {
    const url = `${API_BASE_URL}/products/GetProduct?productId=${productId || ""}&wooProductId=${wooProductId || ""}`;

    const product = await ajax.get(url);
    return product;
};

productRequests.getProductsByCategoriesIds = async function (categoriesIds) {
    const url = `${API_BASE_URL}/products/GetProductsByCategoriesIds`;

    const products = await ajax.post(url, { categoriesIds });
    return products;
};

productRequests.getProductsByWooId = async function (wooIdsArray) {
    const url = `${API_BASE_URL}/products/GetProductsByWooId`;

    const products = await ajax.post(url, { wooIds: wooIdsArray });
    return products;
};

productRequests.getProductsByCategoryFriendlyName = async function (friendlyName) {
    const url = `${API_BASE_URL}/products/GetProductsByCategoryFriendlyName/${friendlyName}`;

    const products = await ajax.get(url);
    return products;
};

export default productRequests;
