/**
 * 
 * @param {HTMLInputElement} inputElmt 
 * @param {string} action hide | show
 * @param {number} currentCaretPosition
 */
function setCursorCaret(inputElmt, action, currentCaretPosition = 0) {
    if(!inputElmt) return;

    if(action === "hide"){
        inputElmt.style.caretColor = "transparent";
        return;
    }

    if(action === "show"){
        inputElmt.focus();
        inputElmt.selectionStart = currentCaretPosition;
        inputElmt.selectionEnd = currentCaretPosition;
        inputElmt.style.caretColor = "auto";
    }
}

export {
    setCursorCaret
}