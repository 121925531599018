/**
 * @typedef {{ type: "color-change"; color: string }} ChangeColorData
 * @typedef {{ type: "navigate"; to: string }} NavigateData
 * @typedef {{ type: "update-url-params"; to: string }} NavigateData
 * @typedef {{ type: "add-item-to-cart-ui"; newItemData: object }} CartItemData
 * @typedef {{ type: "modify-wishlist-count-ui"; wishlistCountData: number }} WishlistItemUIData
 * @typedef {{ type: "click-cart-button" }} ClickCartButtonData
 * @typedef {{ type: "modify-wishlist-height", newHeight: number }} WishlistHeightUIData
 * @param {ChangeColorData | NavigateData | CartItemData | WishlistItemUIData | ClickCartButtonData | WishlistHeightUIData} data
 */
export default function postToParent(data) {
    try {
        window.parent.postMessage(data, "*");
    } catch (err) {
        console.log("Post message to parent Error", err);
        console.log("Post message data", data);
    }
}