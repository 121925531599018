import React from 'react'

const Selection = ({width,height}) => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Group_11038"
                data-name="Group 11038"
                width={width}
                height={height}
                viewBox="0 0 97.229 82.047"
            >
                <g id="Group_11029" data-name="Group 11029" transform="translate(0 0)">
                    <path
                        id="Path_10701"
                        data-name="Path 10701"
                        d="M112.64,193.982h-7.384a4.761,4.761,0,0,1-4.756-4.756V177.315a4.761,4.761,0,0,1,4.756-4.756h7.384a4.761,4.761,0,0,1,4.756,4.756v11.912A4.761,4.761,0,0,1,112.64,193.982Zm-7.384-19.731a3.067,3.067,0,0,0-3.064,3.064v11.912a3.067,3.067,0,0,0,3.064,3.064h7.384a3.067,3.067,0,0,0,3.064-3.064V177.315a3.067,3.067,0,0,0-3.064-3.064Z"
                        transform="translate(-100.5 -172.559)"
                    ></path>
                </g>
                <g id="Group_11030" data-name="Group 11030" transform="translate(19.531 0)">
                    <path
                        id="Path_10702"
                        data-name="Path 10702"
                        d="M187.135,193.984h-1.088a4.76,4.76,0,0,1-4.755-4.755V177.316a4.76,4.76,0,0,1,4.755-4.755h7.383a4.762,4.762,0,0,1,4.757,4.755v5.2a.846.846,0,0,1-1.692,0v-5.2a3.067,3.067,0,0,0-3.065-3.063h-7.383a3.066,3.066,0,0,0-3.063,3.063v11.913a3.066,3.066,0,0,0,3.063,3.063h1.088a.846.846,0,1,1,0,1.692Z"
                        transform="translate(-181.292 -172.561)"
                    ></path>
                </g>
                <g id="Group_11031" data-name="Group 11031" transform="translate(39.058 0)">
                    <path
                        id="Path_10703"
                        data-name="Path 10703"
                        d="M274.212,193.984h-7.2a.846.846,0,0,1,0-1.692h7.2a3.066,3.066,0,0,0,3.063-3.063V177.316a3.066,3.066,0,0,0-3.063-3.063h-7.383a3.067,3.067,0,0,0-3.065,3.063v8.736a.846.846,0,1,1-1.692,0v-8.736a4.762,4.762,0,0,1,4.757-4.755h7.383a4.76,4.76,0,0,1,4.755,4.755v11.913A4.76,4.76,0,0,1,274.212,193.984Z"
                        transform="translate(-262.072 -172.561)"
                    ></path>
                </g>
                <g id="Group_11032" data-name="Group 11032" transform="translate(58.588 0)">
                    <path
                        id="Path_10704"
                        data-name="Path 10704"
                        d="M355,193.984h-7.385a4.76,4.76,0,0,1-4.755-4.755V177.316a4.76,4.76,0,0,1,4.755-4.755H355a4.76,4.76,0,0,1,4.755,4.755v11.913a4.746,4.746,0,0,1-1.641,3.591.846.846,0,1,1-1.107-1.28,3.055,3.055,0,0,0,1.057-2.312V177.316A3.066,3.066,0,0,0,355,174.253h-7.385a3.066,3.066,0,0,0-3.063,3.063v11.913a3.066,3.066,0,0,0,3.063,3.063H355a2.985,2.985,0,0,0,.317-.017.846.846,0,0,1,.184,1.682A4.645,4.645,0,0,1,355,193.984Z"
                        transform="translate(-342.862 -172.561)"
                    ></path>
                </g>
                <g id="Group_11033" data-name="Group 11033" transform="translate(0 24.223)">
                    <path
                        id="Path_10705"
                        data-name="Path 10705"
                        d="M112.64,294.184h-7.384a4.761,4.761,0,0,1-4.756-4.756V277.517a4.761,4.761,0,0,1,4.756-4.756h7.384a4.761,4.761,0,0,1,4.756,4.756v11.912A4.761,4.761,0,0,1,112.64,294.184Zm-7.384-19.731a3.067,3.067,0,0,0-3.064,3.064v11.912a3.067,3.067,0,0,0,3.064,3.064h7.384a3.067,3.067,0,0,0,3.064-3.064V277.517a3.067,3.067,0,0,0-3.064-3.064Z"
                        transform="translate(-100.5 -272.761)"
                    ></path>
                </g>
                <g id="Group_11034" data-name="Group 11034" transform="translate(19.531 24.223)">
                    <path
                        id="Path_10706"
                        data-name="Path 10706"
                        d="M193.43,294.184h-7.383a4.76,4.76,0,0,1-4.755-4.755V277.516a4.76,4.76,0,0,1,4.755-4.755h3.578a.846.846,0,1,1,0,1.692h-3.578a3.066,3.066,0,0,0-3.063,3.063v11.913a3.066,3.066,0,0,0,3.063,3.063h7.383a3.053,3.053,0,0,0,3.052-2.769c.009-.09.013-.186.013-.294v-2.456a.846.846,0,0,1,1.692,0v2.456c0,.164-.007.314-.021.458A4.738,4.738,0,0,1,193.43,294.184Z"
                        transform="translate(-181.292 -272.761)"
                    ></path>
                </g>
                <g id="Group_11035" data-name="Group 11035" transform="translate(0 48.446)">
                    <path
                        id="Path_10707"
                        data-name="Path 10707"
                        d="M112.64,394.387h-7.384a4.761,4.761,0,0,1-4.756-4.756V377.72a4.761,4.761,0,0,1,4.756-4.756h7.384a4.761,4.761,0,0,1,4.756,4.756v11.912A4.761,4.761,0,0,1,112.64,394.387Zm-7.384-19.731a3.067,3.067,0,0,0-3.064,3.064v11.912a3.067,3.067,0,0,0,3.064,3.064h7.384a3.067,3.067,0,0,0,3.064-3.064V377.72a3.067,3.067,0,0,0-3.064-3.064Z"
                        transform="translate(-100.5 -372.964)"
                    ></path>
                </g>
                <g id="Group_11036" data-name="Group 11036" transform="translate(19.531 48.445)">
                    <path
                        id="Path_10708"
                        data-name="Path 10708"
                        d="M193.43,394.384h-7.383a4.76,4.76,0,0,1-4.755-4.755V377.718a4.762,4.762,0,0,1,4.755-4.757h7.383a4.725,4.725,0,0,1,3.312,1.342.846.846,0,1,1-1.18,1.213,3.041,3.041,0,0,0-2.132-.863h-7.383a3.067,3.067,0,0,0-3.063,3.065v11.911a3.066,3.066,0,0,0,3.063,3.063h7.383a3.041,3.041,0,0,0,2.169-.9.846.846,0,0,1,1.2,1.195A4.721,4.721,0,0,1,193.43,394.384Z"
                        transform="translate(-181.292 -372.961)"
                    ></path>
                </g>
                <g id="Group_11037" data-name="Group 11037" transform="translate(25.172 9.863)">
                    <path
                        id="Path_10709"
                        data-name="Path 10709"
                        d="M250.392,285.542l-.362,0a27.449,27.449,0,0,1-13.777-3.654L212.269,270.17a8.528,8.528,0,0,1,7.482-15.327l4.4,2.151L205.67,224.979a7.747,7.747,0,0,1,13.417-7.749l8.086,14.009a7.7,7.7,0,0,1,2.374-2.21l.1-.065a7.692,7.692,0,0,1,5.877-.77,7.846,7.846,0,0,1,2.094.906,7.274,7.274,0,0,1,2.825-2.91l.113-.061a7.25,7.25,0,0,1,5.641-.633,7.652,7.652,0,0,1,2.309,1.146,7.008,7.008,0,0,1,2.518-2.431,6.776,6.776,0,0,1,5.637-.626,8.156,8.156,0,0,1,4.441,3.756l12.745,22.075a22.416,22.416,0,0,1,1.748,17.926,25.841,25.841,0,0,1-11.579,14.789l-.1.055A27.612,27.612,0,0,1,250.392,285.542Zm-34.364-29.869a6.9,6.9,0,0,0-2.239.376,6.838,6.838,0,0,0-.778,12.6l24.055,11.75.034.021a25.721,25.721,0,0,0,12.949,3.427,26.03,26.03,0,0,0,13.041-3.137l.1-.053a24.123,24.123,0,0,0,10.789-13.806,20.742,20.742,0,0,0-1.594-16.587l-12.745-22.076a6.467,6.467,0,0,0-3.5-2.994,5.4,5.4,0,0,0-6.559,3.1l-.464,1.188-.914-.889a6.061,6.061,0,0,0-2.481-1.469,5.582,5.582,0,0,0-4.349.5l-.087.046a5.567,5.567,0,0,0-2.506,3.016l-.4,1.163-.942-.793a6.11,6.11,0,0,0-2.342-1.219,6.015,6.015,0,0,0-4.575.591l-.086.055a6.007,6.007,0,0,0-2.463,2.812l-.665,1.555-9.677-16.765a6.055,6.055,0,0,0-10.486,6.056l21.256,36.815-9.382-4.583A6.778,6.778,0,0,0,216.028,255.673Z"
                        transform="translate(-204.629 -213.357)"
                    ></path>
                </g>
            </svg>
        </div>
    );
};

export default Selection;