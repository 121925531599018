import React from "react";

const BarIcon = () => {
    return (
        <div>
            <svg
                id="Component_22_1"
                data-name="Component 22 – 1"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 82.601 62.545"
            >
                <path
                    id="Path_10446"
                    data-name="Path 10446"
                    d="M1585.685,198h-46.874V151.124h46.874Zm-43.314-3.56h39.754V154.683h-39.754Z"
                    transform="translate(-1528.748 -151.124)"
                />
                <path
                    id="Path_10447"
                    data-name="Path 10447"
                    d="M1603.528,247.4h-82.6V228.244h82.6Zm-79.2-3.4h75.8v-12.35h-75.8Z"
                    transform="translate(-1520.927 -184.85)"
                />
                <path
                    id="Path_10448"
                    data-name="Path 10448"
                    d="M1569.03,184.551h-4.752a8.706,8.706,0,0,0,.543-2.557,8.075,8.075,0,0,0-.362-2.874h.588V176.9a.915.915,0,0,1-.678-.95,1,1,0,0,1,.271-.7.888.888,0,0,1,.679-.294,1,1,0,0,1,1,1,.923.923,0,0,1-.724.95v2.217h1.72v-3.3a.915.915,0,0,1-.678-.95,1,1,0,0,1,.271-.7.887.887,0,0,1,.679-.294,1,1,0,0,1,.995,1,.922.922,0,0,1-.724.95v3.3h1.81v-2.715a.916.916,0,0,1-.678-.951,1,1,0,0,1,.272-.7.885.885,0,0,1,.679-.295,1.006,1.006,0,0,1,1,1,.924.924,0,0,1-.724.951v2.715h.362a14.356,14.356,0,0,0-.294,2.693,15,15,0,0,0,.2,2.737l.5,5.159,4.3-4.526-2.987-1.04a17.215,17.215,0,0,0,.951-3.349q.315-1.765.543-3.485l.045-.453c.03-.029.083.053.158.249a11.846,11.846,0,0,1,.294,1.833l3.123,1.04a1.408,1.408,0,0,1,.724.679,3.727,3.727,0,0,1,.407,2.557,1.909,1.909,0,0,1-.588,1.063l-6.155,5.883c-.242.212-.045.362.588.453a6.778,6.778,0,0,0,2.873-.181,8.136,8.136,0,0,0,2.919-1.448l4.933-3.711-3.213-1.267a1.375,1.375,0,0,0,.52-.7,7.565,7.565,0,0,0,.429-1.357q.182-.792.318-1.72t.226-1.788q.09,0,.407.5l3.168,1a1.754,1.754,0,0,1,1.018.792,3.31,3.31,0,0,1,.475,1.4,3.805,3.805,0,0,1-.158,1.583,2.844,2.844,0,0,1-.883,1.335l-14.889,11.088Z"
                    transform="translate(-1539.886 -161.072)"
                />
            </svg>
        </div>
    );
};

export default BarIcon;
