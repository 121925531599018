// product detail page
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fabric } from 'fabric';
//  custom hooks
import { useEditPurchaseHook } from '../../context/editPurchaseContext';
// request
import orderRequests from '../../requests/orders';
import productRequests from '../../requests/products';
import purchasesRequests from '../../requests/purchases';
// utils
import {
    setCanvasAtResoution,
    assingObjectsToCanvas,
    setCustomFontsWhenLoaded,
    forceRenderUpdate
} from '../../utils/canvas';
import { hasHebrewCharacters } from '../../utils/text';
import { getUrlQuery } from '../../utils/url';
import canvasObjsToJsonStr from '../../utils/canvasObjsToJsonStr';
import saveAutocompleteValues from '../../utils/saveAutoCompleteValues';
import { reverseNumbers } from '../../utils/text';
import { findDimensionsTag } from '../../utils/findDimensionsTag';
import { setCursorCaret } from '../../utils/inputCursorCaret';
// custom components
import InputsList from '../shop/inputs-list';
import CustomLoader from '../Loader/customLoader';
import SongsList from '../shop/songsList';
import QrCodeInput from '../shop/qrCode';
import CardPreview from '../shop/preview';
import { Spinner } from 'reactstrap';
import EditPurchaseWarningModal from '../Modals/editPurchaseWarningModal';
import EditPurchaseRestriction from '../Modals/editPurchaseRestrictionModal';
// scripts
import { calculateFontSize } from '../../utils/fontResize';
// constants
import { CANVAS_SIZE } from '../../utils/constants';
import { WP_BASE_URL } from '../../config';
// style
import './editPurchase.css';
// import mockupData from '../../mocks/order_data_mockup';


const EditPurchase = () => {
    const history = useNavigate();
    const editPurchaseState = useEditPurchaseHook();

    const [isLoading, setIsLoading] = useState(true);
    const [isCheckingPermission, setIsCheckingPermission] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [product, setProduct] = useState({});
    const [canvas, setCanvas] = useState();
    const [inputsValues, setInputsValues] = useState({});
    const [songSelected, setSongSelected] = useState(null);
    const [associatedValueDisplayed, setAssociatedValueDisplayed] = useState(false);
    const [QRText, setQRText] = useState('');
    const [enablePreview, setEnablePreview] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [canUserEdit, setCanUserEdit] = useState(false);
    const [wPSongData, setWPSongData] = useState(null);

    const changeAndResizeText = async (txtObj, newText, isAssociatedValue = false) => {
        const newFontSize = await calculateFontSize(txtObj, newText);

        const isHebrew = hasHebrewCharacters(newText);
        const text = isHebrew ? reverseNumbers(newText) : newText;
        let extraCount = 0;

        txtObj.set({
            text,
            fontSize: newFontSize / txtObj.scaleX,
            direction: isHebrew ? 'rtl' : 'ltr',
            borderColor: '#af52de'
        });

        // if text isn't multiLine and has 2 lines, reduce fontSize to fit in one line
        while( !txtObj.multiLine && txtObj._textLines.length > 1 ){
            // reduce font size 1%
            txtObj.fontSize *= 0.99;
            canvas.renderAll();
            console.log("reducing to single line");
        }

        // if text is multiLine and has more lines than original, reduce fontSize to fit
        // in original lines quantity
        while( txtObj.multiLine && txtObj._textLines.length > txtObj.originalLinesLength ){
            // reduce font size 1%
            txtObj.fontSize *= 0.99;
            canvas.renderAll();
            console.log("reducing lines to fit multiple lines");
        }

        // reduce font if box width is bigger than max width (specially for multiline boxes)
        // using a variable called "extraCount" to checking box width, because if you reduce the box width
        // and the text is still very large (fontSize) the box width  won't change 
        // so we need to remember the last fontSize 
        extraCount = txtObj.fontSize;

        while(txtObj.width > txtObj.maxWidth){
            extraCount--;
            txtObj.fontSize = extraCount;
            canvas.renderAll();
            txtObj.width = txtObj.maxWidth;
            canvas.renderAll();
            console.log("reducing box width and font size to fit maxWidth");
        }

        if(!isAssociatedValue){
            txtObj.borderColor = '#af52de';
            canvas.setActiveObject(txtObj);
        }
        canvas.renderAll();

        setInputsValues( previousState => {
            return {
                ...previousState,
                [txtObj._id]: {
                    ...previousState[txtObj._id],
                    text: newText
                }
            }
        });
    }


    const handleChange = async (ev) => {
        const { name: fieldId, value: fieldText } = ev.target;
        const { inputElmt, selectionStart } = ev;

        const currentCaretPosition = selectionStart;
        setCursorCaret(inputElmt, 'hide');

        const { associatedValue } = ev;
        const canvasObjs = canvas.getObjects();

        const txtObj = canvasObjs.find( obj => obj._id === fieldId);
        await changeAndResizeText(txtObj, fieldText, associatedValue?.isAssociatedValue);

        // if the field has an associated value like hall name with hall address
        // or shul name with shul address, update it.
        if(associatedValue){
            setAssociatedValueDisplayed(true);

            const associatedTxtObj = canvasObjs.find( obj => obj.label === associatedValue.autocompleteLabel);
            if(associatedTxtObj){
                await changeAndResizeText(associatedTxtObj, associatedValue.value, true);
            }
        }
        else{
            setAssociatedValueDisplayed(false);
        }
        // show input cursor again
        setCursorCaret(inputElmt, 'show', currentCaretPosition);
    }


    useEffect(() => {
        const { id: wooProductId, order_id, order_item_id, cart_item_key } = getUrlQuery();
        // init canvas
        const _canvas = new fabric.Canvas('canvas-wrapper', {
            selection: false,
            imageSmoothingEnabled: false,
        });
        setCanvas(_canvas);

        // check if user already edited the purchase, if he did it, do not permit update
        async function checkIfUserCanUpdate() {
            try {
                setIsCheckingPermission(true);
                const purchaseCount = await purchasesRequests.getEditCount({ 
                    wooCartItemKey: cart_item_key,
                    orderId: order_id
                });
                setIsCheckingPermission(false);

                if(!purchaseCount) return false;
                if(!purchaseCount.isTimeValid) return false;
                if(purchaseCount.fieldsEditedCount < editPurchaseState.maxEditCount) return true;
                
                return false;
            } catch (err) {
                console.log("Can't get edit count", err.message);
                setIsCheckingPermission(false);
                return false;
            }
        }


        // get product data from wordpress
        async function getProductDataFromWPOrder() {
            const _WProduct = await orderRequests.getOrderItem({
                woo_order_id: order_id,
                woo_order_item_id: order_item_id,
                woo_cart_item_key: cart_item_key
            });
            // const _WProduct = mockupData.data;

            const _product = await productRequests.getProduct({ productId: _WProduct.customizer_product_id, wooProductId });
            setProduct(_product);
            setWPSongData(_WProduct.customization_data?.song);
            setIsLoading(false);
            return {
                productMongoOriginalData: _product,
                productWordpressCustomData: _WProduct.customization_data
            };
        }

        async function resizeAndSetTextOnCanvas(productWordpressCustomData) {
            const _productFields = productWordpressCustomData.canvasObjects;
            if(_productFields.length === 0) return false;

            const imageDimensions = {
                width: _productFields[0].originalImageWidth,
                height: _productFields[0].originalImageHeight
            }

            const _inputsValues = {};
            for(let f of _productFields){
                _inputsValues[f._id] = f;
            }

            setInputsValues(_inputsValues);

            await assingObjectsToCanvas(_canvas, _productFields);
            setCanvasAtResoution(
                _canvas,
                CANVAS_SIZE,
                _productFields[0].canvasWidth,
                imageDimensions
            );
            return true;
        }


        function setCanvasBackgroundImage(_productData, callback) {
            _canvas.setDimensions({ width: CANVAS_SIZE, height: CANVAS_SIZE });

            return new Promise((resolve) => {
                fabric.Image.fromURL( _productData.filesId.pathWithWatermark, function (img) {
                    const scaleFactor = img.width / _canvas.width;
                    const naturalCanvasHeight = img.height / scaleFactor;
                    _canvas.setDimensions({ width: _canvas.width, height: naturalCanvasHeight });

                    _canvas.setBackgroundImage(img, _canvas.renderAll.bind(_canvas), {
                        scaleX: _canvas.width / img.width,
                        scaleY: _canvas.height / img.height
                    });

                    callback();
                    resolve();
                }, {
                    crossOrigin: 'anonymous',
                    objectCaching: false,
                });
            })
        }
        
        // run functions
        checkIfUserCanUpdate()
        .then( valid => {
                setCanUserEdit(valid);

                getProductDataFromWPOrder()
                    .then(({ productMongoOriginalData, productWordpressCustomData }) => {
                        resizeAndSetTextOnCanvas(productWordpressCustomData)
                            .then(() => {
                                setCustomFontsWhenLoaded(_canvas)
                                    .then(() => forceRenderUpdate(_canvas));
        
                                setCanvasBackgroundImage(productMongoOriginalData, () => {
                                    forceRenderUpdate(_canvas, true);
                                    setEnablePreview(true);
                                });
                            });
                    })
            })

        // ######################### for testing with mock data #############################
        // ######################### for testing with mock data #############################
        // ######################### for testing with mock data #############################
        // setCanUserEdit(true);
        // getProductDataFromWPOrder()
        //     .then(({ productMongoOriginalData, productWordpressCustomData }) => {
        //         resizeAndSetTextOnCanvas(productWordpressCustomData)
        //             .then(() => {
        //                 setCustomFontsWhenLoaded(_canvas)
        //                     .then(() => forceRenderUpdate(_canvas));

        //                 setCanvasBackgroundImage(productMongoOriginalData, () => {
        //                     forceRenderUpdate(_canvas, true);
        //                     setEnablePreview(true);
        //                 });
        //             });
        //     })
        // ######################### for testing with mock data #############################
    }, []);


    const handleUpdate = async () => {
        const { order_id, order_item_id, cart_item_key } = getUrlQuery();

        if(loadingUpdate) return;
        // redirect to user orders list
        
        if(!canUserEdit){
            window.parent.location.href = `${WP_BASE_URL}/my-account/view-order/${order_id}`;
            return;
        }

        try {
            setLoadingUpdate(true);

            const dataOrderToUpdate = {
                canvasObjects: JSON.parse(canvasObjsToJsonStr(canvas, true)),
                watermarkImage: product.filesId.pathWithWatermark,
                productId: product._id,
                productFilesId: product.filesId._id,
                productName: product.name,
                pdfSizes: product.pdfSizes || [],
                tags: product.tags || [],
                dimensions: findDimensionsTag(product.tags),
                song: null,
                QRText,
            }

            if(songSelected){
                dataOrderToUpdate.song = {
                    path: songSelected.path,
                    name: songSelected.filename || songSelected.name,
                    _id: songSelected._id,
                }
            }

            // save the current canvas size (save the canvas witdh in which it was edited)
            for(let obj of dataOrderToUpdate.canvasObjects){
                obj.canvasWidth = CANVAS_SIZE
            }

            const canvasImageBase64URL = canvas.toDataURL({
                format: 'jpeg'
            });

            await saveAutocompleteValues(canvas);
            // update order in woocomerce
            await orderRequests.updateOrderItem({
                woo_order_id: order_id,
                woo_cart_item_key: cart_item_key,
                woo_order_item_id: order_item_id,
                customization_data: JSON.stringify(dataOrderToUpdate),
                customized_item_preview: canvasImageBase64URL
            });
            // disable future edits for this product
            await purchasesRequests.updateEditCount({
                wooCartItemKey: cart_item_key,
                orderId: order_id,
                fieldsEditedCount: editPurchaseState.editCount
            });

            setLoadingUpdate(false);
            // redirect to download page
            window.parent.location.href = `${WP_BASE_URL}/my-account/view-order/${order_id}`;
            return
            // window.parent.location.href = `${WP_BASE_URL}/download-your-card/?id=${wooProductId}&order_id=${order_id}&order_item_id=${order_item_id}&cart_item_key=${cart_item_key}`;

        } catch (err) {
            console.log("can't update order on wordpress", err.message);
            setLoadingUpdate(false);
        }
    }

    // window.canvas = canvas; // just for development

    //  classname edp => edit purchase
    return (
        <div className="edp-container edit-purchase-class page-class">
            {
                isCheckingPermission ? null : canUserEdit ? (
                    <EditPurchaseWarningModal isOpen={showModal} handleClose={() => setShowModal(false)} />
                ) : (
                    <EditPurchaseRestriction isOpen={showModal} handleClose={() => setShowModal(false)} />
                )
            }
            <div className="pd-flex-container">
                <div className="pd-left-column">
                    <div className="pd-go-back" onClick={() => history(-1)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        <span>Back</span>
                    </div>

                    <div id="test-resize"></div>

                    <div className="pd-canvas-container">
                        <canvas id='canvas-wrapper'></canvas>
                        {
                            isLoading && <CustomLoader width={40} height={40} />
                        }
                    </div>
                </div>

                <div className="pd-right-column">
                    {
                        isLoading ? (
                            <CustomLoader width={40} height={40} />
                        ) : (
                            <>
                                <div className="pd-nameprice">
                                    <h4>{product.name}</h4>
                                    <h3><b>${product.price}</b></h3>
                                </div>


                                <InputsList
                                    onChange={handleChange}
                                    inputValues={inputsValues}
                                    associatedValueDisplayed={associatedValueDisplayed}
                                    isOnEditPurchasePage
                                />

                                <hr id='pd-hr' />

                                <div className="pd-bottom-btn">
                                    <div className="pd-checkboxes">
                                        {
                                            wPSongData && (
                                                <SongsList
                                                    productData={product}
                                                    onSelectSong={setSongSelected}
                                                    setIsEditing={() => {}}
                                                    isEditing={null}
                                                />
                                            )
                                        }

                                        <div className="pd-qr-preview">
                                            <QrCodeInput
                                                canvas={canvas}
                                                setQRText={setQRText}
                                                setIsEditing={() => {}}
                                                isEditing={null}
                                            />

                                            <CardPreview canvas={canvas} enabled={enablePreview} />
                                        </div>
                                    </div>

                                    <button onClick={handleUpdate} id="save-order-edit-btn" disabled={loadingUpdate}>
                                        { canUserEdit ? 'Save changes and download' : 'Return to order page'}
                                        {loadingUpdate && <Spinner className='loader-edit-order' size="sm" type="border" />}
                                    </button>
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    );
}

export default EditPurchase;