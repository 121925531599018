import React, { useEffect, useState } from 'react';
// images
import playIcon from '../../assets/images/icons/play-icon.svg';
import pauseIcon from '../../assets/images/icons/pause-icon.svg';
// custom elements
import FakeRadioButton from '../Inputs/fakeRadioButton';
// style
import './styles/songItem.css';


const SongItem = ({ songObj, handleChange, selectedSongId, restartAnimation }) => {
    const { _id, filename, path } = songObj;
    const [paused, setPaused] = useState(true);
    const [duration, setDuration] = useState(0);

    const _handlePlayPause = (forcePause = false) => {
        const audios = [...document.querySelectorAll('.audio')];
        const currentAudio = document.getElementById('audio'+_id);
        for(let a of audios){ a.pause() }

        if(forcePause){
            currentAudio.pause();
            return;
        }
        if(paused) currentAudio.play();
        else currentAudio.pause();
    }

    const handlePlayPauseState = () => {
        _handlePlayPause();
        handleChange(songObj);
        setPaused(!paused);
    }

    const handleCheckbox = (checked) => {
        let obj = checked ? songObj : null;
        let forcePause = (_id === selectedSongId) && paused;
        _handlePlayPause(forcePause);
        handleChange(obj);
        setPaused(forcePause || !paused);
    }

    // set state to pause when select another song 
    useEffect(() => {
        if(_id !== selectedSongId){
            setPaused(true);
        }
    }, [selectedSongId]);

    // save song duration
    useEffect(() => {
        const currentAudio = document.getElementById('audio'+_id);
        currentAudio.onloadeddata = () => {
            setDuration(currentAudio.duration);
        }
    }, [paused]);

    // progress bar animation
    useEffect(() => {
        const svgLine = document.getElementById('audio-line'+_id);
        if(duration > 0){
            svgLine.style.animation = `${duration}s linear 0s infinite normal none ${paused ? 'paused' : 'running'} show100`;
            svgLine.style.animationName = 'fill-song-bar';
        }
    }, [paused, duration, restartAnimation]);

    return (
        <div className="song-item-container">
            <div onClick={handlePlayPauseState} className="song-playpause-btn">
                {
                    _id !== selectedSongId ? (
                        <img src={playIcon} alt="play" className="play-img"/>
                    ) : (
                        paused ? <img src={playIcon} alt="play" className="play-img"/> : <img src={pauseIcon} alt="pause" className="pause-img" />
                    )
                }
            </div>

            <div className="song-bar-and-name">
                <div className={`song-name-title-div ${filename?.length >= 25 ? 'txt-long' : ''}`}>
                    <span className='song-name-title-span'>{filename}</span>
                </div>
                <figure className='figure-chart' data-percent="100">
                    <svg width="157" height="4">
                        <line 
                            className="audio-line" 
                            id={'audio-line' + _id}
                            x1="0" 
                            y1="2" 
                            x2="157" 
                            y2="2" 
                        ></line>
                    </svg>
                </figure>
            </div>

            <FakeRadioButton 
                checked={_id === selectedSongId}
                className="song-radio-btn"
                onCheck={ _checked => handleCheckbox(_checked)}
            />

            <audio 
                id={'audio'+_id}
                src={path} 
                className='audio'
                style={{ height: 0, width: 0, opacity: 0 }}
                loop
                preload="metadata"
            />
        </div>
    );
}
 
export default SongItem;