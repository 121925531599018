import React, { createContext, useContext, useEffect, useState, useReducer } from "react";
import { getStorage, setStorage } from "../utils/localStorage";
import cartRequests from "../requests/cart";
import { isCustomerLogin } from "../utils/common";

const CartContext = createContext();

// Action reducer types
const ADD_TO_CART = "add-to-cart";
const REMOVE_FROM_CART = "remove-from-cart";
const RESTORE_CART = "restore-cart";
const VOID_CART = "void-cart";

// Cart state structure
// {
//     items: { [itemId]: item, ... },
//     totalItemsQty: number
// }
function cartReducer(state, action) {
    switch (action.type) {
        case ADD_TO_CART: {
            const { itemId, item } = action.payload;
            if (state.items[itemId]) {
                return state; // If item already exists, return current state
            }
            return {
                items: {
                    ...state.items,
                    [itemId]: item,
                },
                totalItemsQty: state.totalItemsQty + 1,
            };
        }

        case RESTORE_CART: {
            const cartData = action.payload;
            return { ...cartData };
        }

        case REMOVE_FROM_CART: {
            const { itemId } = action.payload;
            const stateCopy = { ...state };
            delete stateCopy.items[itemId];

            return {
                ...stateCopy,
                totalItemsQty: state.totalItemsQty - 1,
            };
        }

        case VOID_CART:
            return { items: {}, totalItemsQty: 0 };

        default:
            return state;
    }
}

const CartProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(null);
    const [state, dispatch] = useReducer(cartReducer, { items: {}, totalItemsQty: 0 });

    async function getItemsFromCart() {
        const customerId = isCustomerLogin();

        if (!customerId) return [];
        const response = await cartRequests.getFromCart(customerId);
        return response.data || [];
    }

    // function checking() {
    //     console.log("done")
    // }
    useEffect(() => {
        getItemsFromCart()
            .then((data) => {
                data?.map((item) => {
                    const { _id } = item;
                    dispatch({
                        type: ADD_TO_CART,
                        payload: { itemId: _id, item },
                    });
                });
            })
            .catch((err) => {
                console.log("Error getting and parsing cart items", err.message);
            })
            .finally(() => {
                setIsLoading(false);
                setHasError(null);
            });
    }, []);

    // useEffect(() => {
    //     const cartData = getStorage("cart-data");
    //     if (cartData) {
    //         dispatch({
    //             type: RESTORE_CART,
    //             payload: cartData,
    //         });
    //     }
    //     setHasError(null);
    //     setIsLoading(false);
    // }, []);

    // useEffect(() => {
    //     setStorage("cart-data", state);
    // }, [state]);

    return (
        <CartContext.Provider
            value={{
                isLoading,
                hasError,
                state,
                dispatch,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

function useCart() {
    const { state, dispatch } = useContext(CartContext);

    function addItemToCart(item) {
        const { _id } = item;
        dispatch({
            type: ADD_TO_CART,
            payload: { itemId: _id, item },
        });
    }

    function removeFromCart(item) {
        const { _id } = item;
        dispatch({
            type: REMOVE_FROM_CART,
            payload: { itemId: _id },
        });
    }

    function voidCart() {
        dispatch({
            type: VOID_CART,
        });
    }

    return {
        addItemToCart,
        removeFromCart,
        voidCart,
        cartItems: state.items,
        cartQty: state.totalItemsQty,
    };
}

export { CartProvider, useCart };
