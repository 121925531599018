import React from "react";

const SendIcon = () => {
    return (
        <div>
            <svg width="40" height="40" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="#F7F7F7" />
                <path
                    d="M18 19C18.1101 19.047 18.2423 19.0705 18.3525 19.1175C29.4995 24.2179 40.6244 29.3183 51.7714 34.3952C51.8816 34.4422 52.0138 34.5127 52.19 34.6068C51.7053 34.8183 51.2868 35.0298 50.8682 35.2179C40.0517 40.1537 29.2351 45.1131 18.4186 50.049C18.2864 50.1195 18.1542 50.143 18.022 50.19C18.022 46.1473 18.022 42.1046 18.022 38.0854C18.0881 38.0619 18.1983 38.0384 18.2864 38.0384C19.2116 37.8973 20.1369 37.7798 21.0621 37.6388C22.8025 37.3802 24.5648 37.1452 26.3052 36.8867C28.0455 36.6281 29.8079 36.3931 31.5482 36.1345C33.3106 35.876 35.073 35.6409 36.8353 35.3824C38.5537 35.1473 40.272 34.8888 41.9903 34.6538C42.0123 34.6538 42.0564 34.6303 42.0784 34.6303C41.814 34.5362 41.5937 34.4892 41.3514 34.4657C39.589 34.2072 37.8046 33.9486 36.0423 33.7136C34.3019 33.455 32.5396 33.22 30.7992 32.9615C29.0589 32.7264 27.3185 32.4679 25.5782 32.2093C23.8158 31.9508 22.0535 31.7157 20.2911 31.4572C19.5421 31.3397 18.771 31.2222 18 31.1046C18 27.0854 18 23.0427 18 19Z"
                    fill="#A24DEE"
                />
            </svg>
        </div>
    );
};

export default SendIcon;
