import React, { useState, useEffect, useMemo } from "react";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";
import EyeIcon from "../../assets/icons/EyeIcon";
import EditIcon from "../../assets/icons/PencilIcon";
import { Link, useNavigate } from "react-router-dom";
import cartRequests from "../../requests/cart";
import { isCustomerLogin } from "../../utils/common";
import "./style.css";

import { useCart } from "../../context/cartContext";

const CartMenu = ({ isCartMenuOpen, setIsCartMenuOpen }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { cartItems, cartQty, removeFromCart } = useCart();

    const navigate = useNavigate();

    const handleEdit = (item) => {
        navigate(`/card/${item.product_id}/?cart_item_key=${item._id}`);
    };

    const handleDelete = async (wooCartItemKey, id) => {
        try {
            const customerId = isCustomerLogin();
            await cartRequests.deleteFromCart(customerId, wooCartItemKey);
            removeFromCart({ _id: id });
        } catch (error) {
            console.error("Error deleting cart item:", error);
        }
    };

    return (
        <div>
            {Object.keys(cartItems ?? {}).length !== 0 ? (
                <div
                    className={`${isCartMenuOpen === true
                            ? "active"
                            : isCartMenuOpen === null
                                ? "display-none"
                                : isCartMenuOpen === false
                                    ? "hidden"
                                    : ""
                        } cart-menu-container`}
                >
                    <div
                        className="close-cart-menu-icon"
                        onClick={() => {
                            setIsCartMenuOpen(false);
                        }}
                    >
                        <ModalCancelIcon />
                    </div>

                    <div className="cart-menu-sub-container">
                            <div className="shopping-cart-hd-wrap">
                                <p className="shopping-cart-hd">Shopping Cart</p>
                            </div>
                            <div className="d-flex flex-column pb-5">
                                {Object.keys(cartItems)?.map((key, index) => {
                                    const item = cartItems[key];

                                    return (
                                        <div key={index} className="cart-menu-item-wrap">
                                            <img
                                                className="cart-menu-img"
                                                src={item.customizeItemPreview}
                                                alt="Item preview"
                                            />
                                            <div className="cart-menu-data-wrap">
                                                <div className="cart-menu-text">
                                                    <p className="cart-menu-item-data">
                                                        <p className="cart-menu-item-name">{item.productDetails.name}</p>
                                                        <span className="cart-menu-item-hd">Music: </span>No
                                                    </p>
                                                    <p className="cart-menu-item-data">
                                                        <span className="cart-item-hd">QR: </span>No
                                                    </p>
                                                    <p className="cart-menu-item-data">
                                                        <span className="cart-menu-item-hd">Size: </span>Vertical (8.5x11)
                                                    </p>
                                                    <p className="cart-menu-item-price">${item.productDetails.price}</p>
                                                </div>
                                                <div className="cart-menu-icon-wrap">
                                                    <div className="cart-menu-eye-icon">
                                                        <EyeIcon width={"10px"} height={"10px"} />
                                                    </div>
                                                    <div
                                                        className="cart-menu-edit-icon"
                                                        onClick={() => {
                                                            handleEdit(item);
                                                        }}
                                                    >
                                                        <EditIcon width={"10px"} height={"10px"} />
                                                    </div>
                                                    <div
                                                        className="cart-menu-delete-icon"
                                                        onClick={() => {
                                                            handleDelete(item.wooCartItemKey, item._id);
                                                        }}
                                                    >
                                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    <div className="cart-menu-btn-wrap">
                        <Link className="cart-menu-btn" to="/checkout">
                            Continue to checkout
                        </Link>
                    </div>
                </div>
            ) : (
                <div
                    className={`${!isCartMenuOpen ? "hidden" : "active"} cart-menu-container`}
                    style={{ height: "200px" }}
                >
                    <div
                        className="close-cart-menu-icon"
                        onClick={() => {
                            setIsCartMenuOpen(false);
                        }}
                    >
                        <ModalCancelIcon />
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <p>No products in the cart.</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CartMenu;
