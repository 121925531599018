function JSON2FormData(json) {
    const formData = new FormData();

    for (let [key, value] of Object.entries(json)) {
        formData.append(key, value);
    }

    return formData;
}

function tryParseToJSON(textLikeJson) {
    try {
        const json = JSON.parse(textLikeJson);
        return json;

    } catch (err) {
        console.error("can't parse to json", err.message);
        return null;
    }
}

export {
    JSON2FormData,
    tryParseToJSON
}