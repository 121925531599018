import { WP_API_BASE_URL } from "../config";
import { API_BASE_URL } from "../config";
// utils
import { JSON2FormData } from "../utils/formData";
import axios from "axios";
import { setDefaultOptions } from "../utils/common";
const wishlistRequests = {};

wishlistRequests.getUserWishlist = async function ({ customerId }) {
    const url = `${API_BASE_URL}/wishlist/getUserWishlist?customerId=${customerId}`;

    try {
        const response = await axios.get(url, setDefaultOptions({ token: true }));
        return response.data.data;
    } catch (error) {
        console.error("Failed to add item to wishlist: ", error);
        throw error;
    }
};

wishlistRequests.deleteWishlistItem = async function ({ user_id, woo_product_id }) {
    const url = `${API_BASE_URL}/wishlist/removeItemFromWishlist`
    //    const  url = `https://hazmoona.com/api/customizer/delete_wishlist_item.php`

    const wooProductId = woo_product_id;
    const customerId = user_id;

    try {
        const response = await axios.post(url, { customerId, wooProductId }, setDefaultOptions({ token: true }));

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

wishlistRequests.addItemToWishlist = async function ({ user_id, woo_product_id }) {
    const url = `${API_BASE_URL}/wishlist/addItemToWishlist`;

    const wooProductId = woo_product_id;
    const customerId = user_id;

    try {
        const response = await axios.post(url, { customerId, wooProductId }, setDefaultOptions({ token: true }));

        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

export default wishlistRequests;
