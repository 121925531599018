import { UncontrolledTooltip } from 'reactstrap';

function Tooltip({ target, text }) {
    return (
        <UncontrolledTooltip
            target={target }
            delay={{ show: 200, hide: 0 }}
            innerClassName="rounded-tooltip"
        >
            {text}
        </UncontrolledTooltip>
    )
}


export default Tooltip;