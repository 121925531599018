import React from "react";

const PhoneIcon = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20.696" height="19.598" viewBox="0 0 20.696 19.598">
                <g id="phone" transform="matrix(0.966, 0.259, -0.259, 0.966, 4.111, -0.202)">
                    <path
                        id="Trazado_10758"
                        data-name="Trazado 10758"
                        d="M13.017,1491.035a5.64,5.64,0,0,1-1.763-.273,17.065,17.065,0,0,1-9.174-6.61,9.489,9.489,0,0,1-1.986-3.884,4.577,4.577,0,0,1,1.535-4.472,2.782,2.782,0,0,1,2.255-.31.849.849,0,0,1,.4.442q.738,1.7,1.461,3.419a.781.781,0,0,1-.051.652,5.185,5.185,0,0,1-1.049,1.367.676.676,0,0,0-.093.957,11.59,11.59,0,0,0,5.771,4.841.691.691,0,0,0,.9-.21c.414-.506.857-1,1.248-1.523a.706.706,0,0,1,.954-.251c.826.374,1.648.757,2.47,1.135l.55.26c.836.406.824.415.754,1.313-.139,1.76-1.488,2.625-3.086,3.059a3.653,3.653,0,0,1-1.093.086"
                        transform="translate(0 -1475.159)"
                        fill="#af40f7"
                        fill-rule="evenodd"
                    ></path>
                </g>
            </svg>
        </div>
    );
};

export default PhoneIcon;
