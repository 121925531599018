import React, { useEffect, useState, useRef } from 'react';
import HebrewInput from './HebrewInput';
// requests
import fieldRequests from '../../requests/fields';

import './autocompleteInputLikeSmartphone.css';


const AutocompleteInputLikeSmartphone = ({ onChange, value, name, autocompleteLabel, isUpperCase = false}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [lastSuggestionSelected, setLastSuggestionSelected] = useState('');
    const [showSuggestionsBar, setShowSuggestionsBar] = useState(false);
    const [valueChangedAtParentComponent, setValueChangedAtParentComponent] = useState(false);
    const firstRender = useRef(true);

    const onChangeHandler = ev => {
        onChange(ev);
        setValueChangedAtParentComponent(false);
    }

    // get suggestions via ajax
    useEffect(() => {
        if(firstRender.current){
            firstRender.current = false;
            return;
        }

        if(!value) return;

        if(value.length < 3 || valueChangedAtParentComponent){
            setSuggestions([]);
            return;
        };

        let words = value.split(' ');
        let valueToSearch = words.pop();

        if(!valueToSearch || valueToSearch?.length < 3) return;

        fieldRequests.getSuggestions(autocompleteLabel, valueToSearch)
            .then( _suggestions => {
                setSuggestions(_suggestions);
                setShowSuggestionsBar(true);
            })
    }, [value]);

    // hide suggestion bar when click outside
    useEffect(() => {
        const handleClickOutside = ev => {
            const suggestionBarElmt = document.querySelector(`#suggestion-bar-${name}`);
            if(suggestionBarElmt && !suggestionBarElmt.contains(ev.target)){
                setShowSuggestionsBar(false);
            }
        }

        document.body.addEventListener('click', handleClickOutside);
        return () => document.body.removeEventListener('click', handleClickOutside);
    }, [name])


    const onSuggestionSelect = (suggestionSelected) => {
        const words = value.split(' ');
        const lastWords = words.pop();

        const newValueClean = value.substring(0, value.length - lastWords.length);
        const newValueWithSuggestion = newValueClean + suggestionSelected;

        setValueChangedAtParentComponent(true);
        setLastSuggestionSelected(newValueWithSuggestion);
        setShowSuggestionsBar(false);
        setSuggestions([]);

        onChange({ target: { name, value: newValueWithSuggestion } });
        // focus input
        document.querySelector(`#hebrew-input-${name}`).focus();
    }

    return (
        <div className="autocomplete-as-smartphone-wrapper">
            <HebrewInput
                onChange={onChangeHandler}
                name={name}
                value={value}
                lastSuggestionSelected={lastSuggestionSelected}
                hasSuggestionsLikeSmartphone={ suggestions.length > 0 }
                valueChangedAtParentComponent={valueChangedAtParentComponent}
                isUpperCase={isUpperCase}
            />

            {
                showSuggestionsBar && (suggestions.length > 0) && (
                    <div className="suggestions-row-wrapper" id={`suggestion-bar-${name}`}>
                        {suggestions.map( (s, idx) => (
                            <React.Fragment key={`${s._id}-${name}`}>
                                <div onClick={() => onSuggestionSelect(s.value)} className="suggestion-text">
                                    {s.value}
                                </div>
                                { suggestions.length - 1 !== idx && <div className="suggestion-separator" /> }
                            </React.Fragment>
                        ))}
                    </div>
                )
            }
        </div>
    );
}

export default AutocompleteInputLikeSmartphone;