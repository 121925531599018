import React, { useState, useEffect } from "react";
import "./styles/resetPassword.css";
import axios from "axios";
import authRequests from "../../requests/auth";
import { useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../../layouts/footer/Footer";

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [errorColor, setErrorColor] = useState("red");
    const navigate = useNavigate();
    

    const [message, setMessage] = useState(null);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!password || !confirmPassword) {
            setErrorColor("red");
            setError("Password fields cannot be empty.");
            return;
        }

        if (password !== confirmPassword) {
            setErrorColor("red");
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await authRequests.resetPassword({ token, password });

            if (response) {
                setError("Password successfully reset");
                setErrorColor("green");
                navigate("/login");
            }
        } catch (error) {
            setMessage(error || "Failed to reset password.");
            console.log("Failed to reset password.");
        }
    };

    const handleRecaptcha = (value) => {
        setFormData({
            ...formData,
            recaptchaToken: value,
        });
    };
    return (
        <>
            <div className="reset-container">
                <div className="reset-sub-container">
                    <p className="reset-text">Enter a new password below.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="reset-input-wrap">
                            <label className="name-input-wrap">
                                New Password *
                                <input
                                    className="reset-input"
                                    type="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                            </label>
                            {/* <br /> */}
                            <label className="name-input-wrap">
                                Re-enter new password *
                                <input
                                    className="reset-input"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />
                            </label>
                        </div>
                        {/* <br /> */}
                        <div className="reset-recaptcha">
                        <ReCAPTCHA sitekey="6LeU1M4pAAAAAP8wDgyrh268wX5qcsyoa9ilfEiL" onChange={handleRecaptcha} />

                        </div>
                        <button type="submit" className="reset-button">
                            Save
                        </button>
                        {error && <p style={{ color: errorColor }}>{error}</p>}
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ResetPassword;
