/**
 *  Account Page Order History
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import OrderData from "../../api/userOrder";
import Sitebar from "./Sitebar";
import Footer from "../../layouts/footer/Footer";
import orderRequests from "../../requests/orders";
import { isCustomerLogin } from "../../utils/common";
import productRequests from "../../requests/products";
import customer from "../../requests/customer";

class OrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            Order: [],
            products: {},
            ViewOrder: "",
            customer: null,
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    }
    onViewOrder(data) {
        if(this.state.products[data._id] == null){
            this.fetchProducts(data);
        }

        this.setState({
            ...this.state,
            ViewOrder: data,
        });
        this.toggle();
    }
    onLeaveOrder() {
        this.setState({
            ...this.state,
            ViewOrder: null,
        });
        this.toggle();
    }
    async fetchOrders(){
        const customerId = isCustomerLogin();

        const obj = await orderRequests.getOrderItems({customerId});
        
        this.setState({
            ...this.state,
            Order: obj.data,
        });
    }
    async fetchProducts(order){
        const items = order.items;
        const result = [];

        for(let i = 0; i < items.length; i++){
            const product = await productRequests.getProduct({productId: items[i].productId});
            result.push(product);
        }

        this.setState({
            ...this.state,
            products: {
                ...this.state.products,
                [order._id]: result,
            }
        })
    }
    async fetchProfile() {
        const customerId = localStorage.getItem("customerId");
        if (!customerId) return;

        try {
            const userProfile = await customer.getCustomer({ customerId });
            if (userProfile) {
                this.setState({ 
                    ...this.state,
                    customer: userProfile,
                 });
            }
        } catch (error) {
            console.error("Error in userProfile:", error);
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.fetchOrders();

        this.fetchProfile();
    }
    getTotalPrice(order){
        let total = 0;

        for(let i = 0;i < order.items.length; i++){
            total += order.items[i].price;
        }

        return total;
    }
    formatDate(date) {
        const mdate = new Date(date);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return mdate.toLocaleDateString('en-US', options);
    }
    render() {
        const OrderHistory = this.state.Order; // []
        const ViewOrderdata = this.state.ViewOrder;
        return (
            <>
                <div>
                    <div className="section-ptb">
                        <Container>
                            <Row>
                                <Sitebar />
                                <Col lg={8} className="mt-4 pr-5">
                                    <Row>
                                        <Col lg={!ViewOrderdata ? 12 : 10}>
                                            {!ViewOrderdata ? (
                                                <div className="table-responsive">
                                                    {OrderHistory.length > 0 ? (
                                                        <table class="table orderhistory-table mb-0">
                                                            <thead class="thead-light">
                                                                <tr>
                                                                    <th scope="col border-right-0">Order</th>
                                                                    <th scope="col border-right-0">Date</th>
                                                                    {/* <th scope="col border-right-0">Status</th> */}
                                                                    <th scope="col border-right-0">Total</th>
                                                                    <th scope="col border-left-0">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {OrderHistory?.map((Ordervalue) => (
                                                                    <tr>
                                                                        <td className="link">{Ordervalue.orderId}</td>
                                                                        <td>{this.formatDate(Ordervalue.updatedAt)}</td>
                                                                        {/* <td>{Ordervalue.status}</td> */}
                                                                        <td>
                                                                            <strong>
                                                                                $
                                                                                {this.getTotalPrice(Ordervalue).toLocaleString(
                                                                                    navigator.language,
                                                                                    { minimumFractionDigits: 2 }
                                                                                )}
                                                                            </strong>
                                                                            <br />
                                                                            <span className="light-text">
                                                                                {Ordervalue.items.length} {Ordervalue.items.length == 1 ? "item" : "items"}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                className="action-button"
                                                                                onClick={() =>
                                                                                    this.onViewOrder(Ordervalue)
                                                                                }
                                                                                href="#"
                                                                            >
                                                                                View
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <div style={{height:"60vh"}}>
                                                            <div className="outer-div">
                                                                <div className="inner-div">
                                                                    <div className="no-order-text">
                                                                        <span>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="20px"
                                                                                height="20px"
                                                                                viewBox="0 0 24 24"
                                                                            >
                                                                                <path
                                                                                    fill="currentColor"
                                                                                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8z"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span>No order has been made yet.</span>
                                                                    </div>
                                                                    <button className="browse-product-btn">
                                                                        Browse Products
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <h4>Order details</h4>
                                                   {this.state.products[this.state.ViewOrder._id] != null && this.state.products[this.state.ViewOrder._id].map(product => (
                                                     <Link to={`/card/${product?._id}`} className="single-order-box">
                                                     <img
                                                         className="order-image"
                                                         src={product.filesId.pathWithWatermark}
                                                         alt=""
                                                     />
                                                     <div className="order-content">
                                                         <div className="text-div">
                                                             <h4 className="mb-0">{product.name}</h4>
                                                             <div>
                                                                 <span>
                                                                     <strong>Music:</strong> No
                                                                 </span>
                                                                 <br />
                                                                 <span>
                                                                     <strong>QR:</strong> Yes
                                                                 </span>
                                                                 <br />
                                                             </div>
                                                             <h4 className="bolder">${product.price}</h4>
                                                         </div>
                                                         <div className="btn-div">
                                                             <button className="edit-btn">
                                                                 {" "}
                                                                 <svg
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     width="14px"
                                                                     height="14px"
                                                                     viewBox="0 0 24 24"
                                                                 >
                                                                     <path
                                                                         fill="currentColor"
                                                                         d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36M20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75z"
                                                                     />
                                                                 </svg>{" "}
                                                                 Edit
                                                             </button>
                                                             <button className="download-btn">
                                                                 <svg
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     width="14px"
                                                                     height="14px"
                                                                     viewBox="0 0 24 24"
                                                                 >
                                                                     <path
                                                                         fill="currentColor"
                                                                         d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-8 4v-5h2v3h12v-3h2v5z"
                                                                     />
                                                                 </svg>{" "}
                                                                 Download
                                                             </button>
                                                         </div>
                                                     </div>
                                                 </Link>
                                                   ))}
                                                    <table class="table orderhistory-table mb-0">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th scope="col border-right-0">Product</th>
                                                                <th scope="col border-right-0">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {[
                                                                { title: "SubTotal", value: this.getTotalPrice(this.state.ViewOrder) },
                                                                { title: "Discount", value: 0 },
                                                                { title: "Total", value: this.getTotalPrice(this.state.ViewOrder) },
                                                            ]?.map((data) => (
                                                                <tr>
                                                                    <td>{data.title}</td>
                                                                    <td>
                                                                        <strong>${data.value}</strong>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <div className="order-billing-box">
                                                        <div className="billing-heading">
                                                            <h4>Billing Address</h4>
                                                        </div>
                                                        <div className="billing-para">
                                                            <p>{this.state.customer.firstname}</p>
                                                            <p>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="1em"
                                                                    height="1em"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7L4 8v10h16V8zm0-2l8-5H4zM4 8V6v12z"
                                                                    ></path>
                                                                </svg>{" "}
                                                                {this.state.customer.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}
export default OrderHistory;
