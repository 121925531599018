import React from "react";
// import { IoMenu } from "react-icons/io5";
import "./style.css";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import BabyBoyIcon from "../../assets/icons/baby-boy-icon";
import KidDushIcon from "../../assets/icons/kid-dush-icon";
import ScissorIcon from "../../assets/icons/scissor-icon";
import BarIcon from "../../assets/icons/Bar-icon";
import RingIcon from "../../assets/icons/Ring-icon";
import BottleIcon from "../../assets/icons/bottle-icon";
import WeddingIcon from "../../assets/icons/wedding";
import FlowerIcon from "../../assets/icons/flower";
import VideoIcon from "../../assets/icons/videos";
import OtherIcon from "../../assets/icons/others";
import HeartIcon from "../../assets/icons/HeartIcon";
import OffcanvasContactIcon from "../../assets/icons/offcanvas-contact-icon";
import UserIcon from "../../assets/icons/UserIcon";

const Offcanvas = ({ authenticated,wishlist }) => {
    const menuItems = [
        {
            icon: <BabyBoyIcon />,
            text: "BabyBoy",
            slug: "/baby-boy",
        },
        {
            icon: <KidDushIcon />,
            text: "Kidush",
            slug: "/kiddush",
        },
        {
            icon: <ScissorIcon />,
            text: "Upsherin",
            slug: "/upsherin",
        },
        {
            icon: <BarIcon />,
            text: "Bar Mitzwah",
            slug: "/bar-mitzvah",
        },
        {
            icon: <RingIcon />,
            text: "Tenoyem",
            slug: "/tenoyem",
        },
        {
            icon: <BottleIcon />,
            text: "Bavarfen",
            slug: "/bavarfen",
        },
        {
            icon: <WeddingIcon />,
            text: "Wedding",
            slug: "/wedding",
        },
        {
            icon: <FlowerIcon />,
            text: "Shiva Bruches",
            slug: "/sheva-bruches",
        },
        {
            icon: <VideoIcon />,
            text: "Videos",
            slug: "/videos",
        },
        {
            icon: <OtherIcon />,
            text: "Others",
            slug: "/others",
        },
    ];

    return (
        <div>
            <div className="offcanvas-container">
                <div
                    className="offcanvas-button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasWithBackdrop"
                    aria-controls="offcanvasWithBackdrop"
                >
                    {/* <IoMenu fontSize={20} /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"></path>
                    </svg>
                </div>
                <div
                    class="offcanvas offcanvas-start"
                    tabindex="-1"
                    id="offcanvasWithBackdrop"
                    aria-labelledby="offcanvasWithBackdropLabel"
                >
                    <div class="offcanvas-header">
                        <div className="offcanvas-links-wrap">
                            <div className="offcanvas-header-links heart-wrap">
                                <HeartIcon />
                                <Link to="/wishlist" className="offcanvas-link">Wishlist</Link>
                                {wishlist ? <span className="wishlist-length">{wishlist}</span> : <span></span>}
                            </div>
                            <div className="offcanvas-header-links">
                                <OffcanvasContactIcon />
                                <Link className="offcanvas-link">Contact Us</Link>
                            </div>
                            <div className="offcanvas-header-links">
                                <UserIcon />
                                <Link
                                    className="offcanvas-link"
                                    to={authenticated ? `/account/accountprofile` : `/login`}
                                >
                                    Account
                                </Link>
                            </div>
                        </div>
                        <button
                            type="button"
                            class="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="offcanvas-body">
                        <div className="offcanvas-menu-wrap">
                            {menuItems.map((item, index) => (
                                <Link to={`categories${item?.slug}`} key={index} className="offcanvas-menu">
                                    <span>{item.icon}</span>
                                    {item.text}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Offcanvas;
