import { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner/dist/loader/Oval';
import { useCategories } from '../context/categoriesContext';
import { useParams } from 'react-router-dom';

const IconLoading = () => {
    const { categories } = useCategories();
    const { categoryFriendlyName } = useParams();
    const [colors, setColors] = useState({ color: '#AF40F9', secondaryColor: '#fcf7ff' });

    useEffect(() => {
        const categorySelected = categories?.find( c => c.friendlyUrlName === categoryFriendlyName );
        if(categorySelected){
            setColors({
                color: categorySelected.color,
                secondaryColor: categorySelected.backgroundColor
            })
        }
    }, [categories, categoryFriendlyName]);

    return (
        <Oval
            height={21}
            width={21}
            color={colors.color}
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor={colors.secondaryColor}
            strokeWidth={1}
            strokeWidthSecondary={1}
        />
    )
}

export default IconLoading;