import ajax from './ajax';
import { API_BASE_URL } from '../config';

const productUtilsRequests = {};

productUtilsRequests.getWSBase64QRCode = async function ({ phoneNumber, size }) {
    const url = `${API_BASE_URL}/products-utils/GetWSQRCode`;

    const base64Image = await ajax.post(url, { phoneNumber, size });
    return base64Image;
}


productUtilsRequests.createVideo = async function ({base64Image, imageName, songName, originalImageWidth}) {
    const url = `${API_BASE_URL}/products-utils/CreateVideoV2`;

    const generatedVideoUrl = await ajax.post(url, { base64Image, imageName, songName, originalImageWidth });
    return generatedVideoUrl;
}


export default productUtilsRequests;
