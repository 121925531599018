import React from "react";

const UserIcon = () => {
    return (
        <div>
            {" "}
            <svg id="Account" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17 17">
                <g id="Ellipse_15" data-name="Ellipse 15" fill="none" stroke="#000" stroke-width="1.5">
                    <circle cx="8.5" cy="8.5" r="8.5" stroke="none"></circle>
                    <circle cx="8.5" cy="8.5" r="7.75" fill="none"></circle>
                </g>
                <g id="Intersection_1" data-name="Intersection 1" transform="translate(0 1)" fill="none">
                    <path d="M1.624,12.5a7.5,7.5,0,0,1,13.751,0,8.5,8.5,0,0,1-13.751,0Z" stroke="none"></path>
                    <path
                        d="M 8.499602317810059 14.50020027160645 C 10.42710018157959 14.50020027160645 12.26991081237793 13.69491958618164 13.58405685424805 12.3104887008667 C 12.49703693389893 10.57912445068359 10.58486175537109 9.500101089477539 8.499602317810059 9.500101089477539 C 6.414770126342773 9.500101089477539 4.502819061279297 10.57929992675781 3.415845155715942 12.31078147888184 C 4.729679107666016 13.69503688812256 6.572216033935547 14.50020027160645 8.499602317810059 14.50020027160645 M 8.499602317810059 16.00020027160645 C 5.673621654510498 16.00020027160645 3.169851779937744 14.62084102630615 1.624491930007935 12.49878025054932 C 2.78210186958313 9.850710868835449 5.424851894378662 8.000101089477539 8.499602317810059 8.000101089477539 C 11.57502174377441 8.000101089477539 14.21781158447266 9.85045051574707 15.37547206878662 12.49852085113525 C 13.82985210418701 14.62058067321777 11.32558155059814 16.00020027160645 8.499602317810059 16.00020027160645 Z"
                        stroke="none"
                        fill="#000"
                    ></path>
                </g>
                <g
                    id="Ellipse_17"
                    data-name="Ellipse 17"
                    transform="translate(5 3)"
                    fill="none"
                    stroke="#000"
                    stroke-width="1.5"
                >
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none"></circle>
                    <circle cx="3.5" cy="3.5" r="2.75" fill="none"></circle>
                </g>
            </svg>
        </div>
    );
};

export default UserIcon;
