import { API_BASE_URL } from "../config";
// utils
import axios from "axios";
import { setDefaultOptions } from "../utils/common";

const cartRequests = {};

cartRequests.saveInCart = async function (body) {
    const url = `${API_BASE_URL}/cart/addItemToCart`;

    try {
        const response = await axios.post(url, { body }, setDefaultOptions({ token: true }));
        return response.data;
    } catch (error) {
        console.error("Failed  ", error);
        throw error;
    }
};

cartRequests.getFromCart = async function (customerId) {
    const url = `${API_BASE_URL}/cart/getCartItems?customerId=${customerId}`;

    try {
        const response = await axios.get(url, setDefaultOptions({ token: true }));
        return response.data;
    } catch (error) {
        console.error("Failed to add item to Cart: ", error);
        throw error;
    }
};

cartRequests.deleteFromCart = async function (customerId, wooCartItemKey) {
    const url = `${API_BASE_URL}/cart/deleteFromCart`;

    try {
        const response = await axios.post(url, { customerId, wooCartItemKey }, setDefaultOptions({ token: true }));
        return response.data;
    } catch (error) {
        console.error("Failed to delete from Cart: ", error);
        throw error;
    }
};

export default cartRequests;
