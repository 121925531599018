import React from "react";

const VideoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 76.222 76.222">
            <g id="Component_27_1" data-name="Component 27 – 1" transform="translate(2.2 2.2)">
                <ellipse
                    id="Ellipse_13"
                    data-name="Ellipse 13"
                    cx="35.911"
                    cy="35.911"
                    rx="35.911"
                    ry="35.911"
                    fill="none"
                    stroke="#000"
                    stroke-miterlimit="10"
                    stroke-width="4.4"
                />
                <path
                    id="Path_10462"
                    data-name="Path 10462"
                    d="M1593.485,790.134l-12.933-7.467a2.526,2.526,0,0,0-3.789,2.188v14.934a2.526,2.526,0,0,0,3.789,2.187l12.933-7.467a2.526,2.526,0,0,0,0-4.375"
                    transform="translate(-1548.507 -755.635)"
                />
            </g>
        </svg>
    );
};

export default VideoIcon;
