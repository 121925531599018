import React from "react";

const Attachment = () => {
    return (
        <div>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.00046255 11.8306C0.0243954 10.1633 0.614739 8.71936 1.79543 7.53868C3.83769 5.48843 5.87198 3.43021 7.94616 1.40389C9.15876 0.207253 10.6346 -0.175673 12.27 0.310962C13.9134 0.805573 14.9346 1.94637 15.2935 3.62167C15.6047 5.08955 15.2217 6.41383 14.1767 7.48284C12.1264 9.58095 10.0443 11.6471 7.96212 13.7054C7.22818 14.4313 6.31873 14.6308 5.32951 14.3436C4.37219 14.0644 3.75792 13.4102 3.53454 12.429C3.32713 11.5195 3.5505 10.6978 4.19668 10.0357C5.82412 8.38431 7.46751 6.74889 9.11089 5.11348C9.49382 4.73056 10.0523 4.73854 10.4112 5.10551C10.7702 5.47248 10.7623 6.02293 10.3793 6.40586C8.78381 8.00936 7.18031 9.60488 5.57681 11.2084C5.3694 11.4158 5.24175 11.6631 5.28164 11.9583C5.32951 12.2854 5.51299 12.5167 5.82412 12.6204C6.1512 12.7321 6.45435 12.6523 6.69368 12.413C7.29998 11.8067 7.90627 11.2004 8.51257 10.5941C9.90068 9.206 11.2968 7.80992 12.6928 6.42181C13.2672 5.84742 13.5544 5.16933 13.4986 4.35561C13.4108 3.00739 12.3019 1.98626 10.9537 1.98626C10.2437 1.98626 9.63742 2.24154 9.13483 2.73616C7.10852 4.75449 5.0822 6.77283 3.06387 8.80712C1.38857 10.4904 1.35665 13.1549 3.00004 14.8222C3.9414 15.7796 5.12209 16.1625 6.45435 16.0588C7.57919 15.971 8.51257 15.4844 9.31033 14.6866C11.6717 12.3173 14.0411 9.9559 16.4104 7.58654C16.8492 7.14778 17.4794 7.17171 17.8224 7.63441C18.0538 7.94554 18.0618 8.37633 17.8304 8.69543C17.7746 8.77521 17.7028 8.84701 17.631 8.91881C15.2935 11.2562 12.9561 13.5857 10.6266 15.9311C9.54169 17.0161 8.24133 17.6782 6.71761 17.8537C3.76589 18.1888 1.11733 16.4576 0.247767 13.6336C0.0882143 13.0353 -0.00751506 12.4449 0.00046255 11.8306Z"
                    fill="black"
                />
            </svg>
        </div>
    );
};

export default Attachment;
