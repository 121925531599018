import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        
        window.scrollTo(0, 0);
      }, [location]); 

    useEffect(() => {
         // set page zoom to 100% (if console isn't opened)
        if(window.innerWidth === window.outerWidth && window.innerHeight === window.outerHeight){
            document.body.style.zoom = (window.innerWidth / window.outerWidth);
        }
    }, [])

    return (null)
}

export default ScrollToTop;