import ajax from './ajax';
import { API_BASE_URL } from '../config';

const filesRequests = {};

// images
filesRequests.uploadImage = async function (imageFile) {
    const url = `${API_BASE_URL}/files/SaveImage`;

    const formData = new FormData();
    formData.append('image', imageFile);

    const savedImage = await ajax.post(url, formData, { token: true });
    return savedImage;
}


filesRequests.uploadProductImage = async function (imageFile) {
    const url = `${API_BASE_URL}/files/SaveProductFile`;

    const formData = new FormData();
    formData.append('image', imageFile);

    const imageSavedData = await ajax.post(url, formData, { token: true });
    return imageSavedData;
}


filesRequests.getOriginalImage = async function(fileId) {
    const url = `${API_BASE_URL}/files/OriginalImage/${fileId}`;

    const imageUrl = await ajax.get(url);
    return imageUrl;
}

// songs
filesRequests.uploadSongs = async function (songsFiles, songsNames) {
    const url = `${API_BASE_URL}/files/SaveSongs`;

    const formData = new FormData();
    for (let file of songsFiles) {
        formData.append('songs', file);
    }
    formData.append('songsNames', JSON.stringify(songsNames));

    const savedSongs = await ajax.post(url, formData, { token: true });
    return savedSongs;
}

// fonts
filesRequests.getAllFonts = async function () {
    const url = `${API_BASE_URL}/files/GetAllFonts`;

    const fonts = await ajax.get(url);
    return fonts;
}

filesRequests.getFontByNameAndWeight = async function (name, weight) {
    const url = `${API_BASE_URL}/files/getFontByNameAndWeight?name=${name}&weight=${weight}`;

    const fonts = await ajax.get(url);
    return fonts;
}

export default filesRequests;