import React from "react";

const EmailIcon = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.492" height="15.594" viewBox="0 0 19.492 15.594">
                <g id="email-icon" transform="translate(-2 -4)">
                    <path
                        id="email"
                        d="M18.568,4H4.924A2.924,2.924,0,0,0,2,6.924V16.67a2.924,2.924,0,0,0,2.924,2.924H18.568a2.924,2.924,0,0,0,2.924-2.924V6.924A2.924,2.924,0,0,0,18.568,4Zm-.653,1.949-6.169,4.629L5.577,5.949Zm.653,11.7H4.924a.975.975,0,0,1-.975-.975v-9.5l7.212,5.409a.975.975,0,0,0,1.17,0l7.212-5.409v9.5A.975.975,0,0,1,18.568,17.645Z"
                        transform="translate(0)"
                        fill="#af40f7"
                    ></path>
                </g>
            </svg>
        </div>
    );
};

export default EmailIcon;
