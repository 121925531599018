import React from "react";

const BottleIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 55.333 79.289">
                <g id="Component_24_1" data-name="Component 24 – 1" transform="translate(1.523 1.5)">
                    <path
                        id="Path_10468"
                        data-name="Path 10468"
                        d="M61.279,747.095l8.447-8.83c.017-.018.028-.038.044-.057a1.607,1.607,0,0,0,.107-.137,1.453,1.453,0,0,0,.079-.125c.025-.045.048-.09.069-.138a1.387,1.387,0,0,0,.06-.158c.008-.024.02-.045.027-.07s.006-.045.011-.068a1.563,1.563,0,0,0,.027-.166c.006-.05.01-.1.011-.149s0-.1-.006-.152a1.454,1.454,0,0,0-.019-.154c-.009-.05-.021-.1-.035-.149s-.027-.095-.045-.141a2.026,2.026,0,0,0-.137-.273,1.447,1.447,0,0,0-.09-.127,1.557,1.557,0,0,0-.1-.121c-.017-.018-.029-.038-.047-.056s-.043-.032-.063-.05a1.541,1.541,0,0,0-.124-.1,1.6,1.6,0,0,0-.14-.089c-.039-.022-.079-.041-.121-.06a1.472,1.472,0,0,0-.177-.067c-.021-.007-.039-.017-.06-.023s-.059-.011-.09-.019-.054-.012-.082-.016a4.634,4.634,0,0,0-3.451.594c-.639.337-.845.429-1.322.3s-.61-.31-1-.922a4.542,4.542,0,0,0-6.479-1.736c-.639.338-.844.429-1.321.3s-.61-.31-.995-.922a4.542,4.542,0,0,0-6.477-1.736c-.639.338-.844.43-1.321.3s-.609-.31-.993-.921a4.63,4.63,0,0,0-2.69-2.241c-.026-.01-.053-.018-.079-.027s-.056-.02-.086-.029-.042-.005-.063-.01a1.522,1.522,0,0,0-.186-.03c-.045,0-.09-.008-.136-.008a1.611,1.611,0,0,0-.322.029c-.026,0-.053,0-.079.011s-.045.017-.069.025a1.472,1.472,0,0,0-.151.053c-.048.02-.1.041-.142.064s-.085.049-.126.076-.088.059-.13.092-.074.066-.11.1-.072.072-.105.111-.063.081-.093.124-.056.084-.081.129a1.5,1.5,0,0,0-.066.135,1.544,1.544,0,0,0-.059.157c-.007.022-.019.042-.024.065s-.007.049-.012.074a1.52,1.52,0,0,0-.027.165,1.494,1.494,0,0,0-.01.154,1.364,1.364,0,0,0,.006.148,1.483,1.483,0,0,0,.024.172c0,.024,0,.048.01.072l2.842,11.63"
                        transform="translate(-17.851 -728.523)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                    />
                    <path
                        id="Path_10469"
                        data-name="Path 10469"
                        d="M37.8,754.068a46.876,46.876,0,0,0-9.808-1.836c-.494-.011-.984-.041-1.472-.028a15.985,15.985,0,0,0-14.989,11.84L2.8,796.635a8.775,8.775,0,0,0,6.2,10.746l24.782,6.641a8.775,8.775,0,0,0,10.747-6.205l8.733-32.591a15.833,15.833,0,0,0-7.5-18.006c-.79-.432-2.69-1.4-3.207-1.645"
                        transform="translate(-2.5 -738.057)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                    />
                </g>
            </svg>
        </>
    );
};

export default BottleIcon;
