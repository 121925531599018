import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./checkout.css";
import cartRequests from "../../requests/cart";
import { isCustomerLogin } from "../../utils/common";
import EyeIcon from "../../assets/icons/EyeIcon";
import CouponBtnIcon from "../../assets/icons/CouponBtnIcon";
import Footer from "../../layouts/footer/Footer";
import CustomLoader from "../Loader/customLoader";
import CardPreview from "../shop/preview";
import { fabric } from "fabric";
import payment from "../../requests/payment";

import AppForm from "./form";

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [canvasData, setCanvasData] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [cartProducts, setCartProducts] = useState([]);

    const fetchCartItems = async () => {
        try {
            const customerId = isCustomerLogin();
            const cartResponse = await cartRequests.getFromCart(customerId);
            setCartItems(cartResponse.data);

            let total = 0;
            for(let i = 0; i < cartResponse.data.length; i++){
                total += cartResponse.data[i].productDetails.price;
            }

            const result = cartResponse.data.map(item => {
                return {
                    productId: item.product_id,
                    price: item.productDetails.price,
                    quantity: 1,
                }
            });

            setCartProducts(result);
            
            setTotalPrice(total);
            
        } catch (error) {
            console.error("Error fetching cart items:", error);
            setError("Failed to fetch cart items");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCartItems();
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.cardknox.com/ifields/2.15.2408.0801/ifields.min.js";
        script.async = true;

        script.onload = () => {
            console.log("Cardknox script loaded");
            console.log(window.setAccount);

            if (window.setAccount) {
                window.setAccount("ifields_developmentdev08073b8572e34fa89346776", "developmentdev", "0.1.2");
            } else {
                console.error("setAccount function not available");
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleDelete = async (wooCartItemKey) => {
        try {
            const customerId = isCustomerLogin();
            await cartRequests.deleteFromCart(customerId, wooCartItemKey);
            setCartItems(cartItems.filter((item) => item.wooCartItemKey !== wooCartItemKey));
        } catch (error) {
            console.error("Error deleting cart item:", error);
        }
    };

    const handlePreview = (customizationData) => {
        const canvasData = JSON.parse(customizationData);
        const _canvas = new fabric.Canvas(null);
        _canvas.loadFromJSON(canvasData, () => {
            _canvas.renderAll();
            const base64Url = _canvas.toDataURL({
                format: "jpeg",
            });
            setCanvasData(base64Url);
        });
    };

    if (loading) {
        return (
            <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center" }}>
                <CustomLoader />{" "}
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const handleEdit = (item) => {
        navigate(`/card/${item.product_id}/?cart_item_key=${item._id}`);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitBtn = document.getElementById("submit-btn");
        submitBtn.disabled = true;

        // Tokenization process
        window.getTokens(
            async () => {
                // On success, gather the tokenized data
                const cardToken = document.querySelector('input[data-ifields-id="card-number-token"]').value;
                const cvvToken = document.querySelector('input[data-ifields-id="cvv-token"]').value;
                const expiryToken = document.querySelector('input[data-ifields-id="expiry-date-token"]').value;

                // Prepare the payment data payload
                const paymentData = {
                    cardToken,
                    cvvToken,
                    expiryToken,
                    amount: totalPrice, // Replace with the actual amount
                    customerName: "John Doe", // Replace with actual customer info
                };

                try {
                    // Send the payment data to your backend for processing
                    const processPayment = await payment.processPayment(paymentData);

                    if (processPayment?.success) {
                        alert("Payment processed successfully!");
                    } else {
                        alert("Payment failed: " + (processPayment?.message || "Unknown error"));
                    }
                } catch (error) {
                    console.error("Payment processing error:", error);
                    alert("An error occurred during payment processing. Please try again.");
                } finally {
                    // Re-enable the button after processing
                    submitBtn.disabled = false;
                }
            },
            () => {
                // Re-enable the button if tokenization fails
                alert("Tokenization failed. Please check your card details.");
                submitBtn.disabled = false;
            },
            30000 // Timeout for tokenization
        );
    };

    return (
        <>
            <div className="checkout-container">
                <div className="checkout-sub-container">
                    <div className="order-summary-container">
                        <p className="order-summary-hd">Order Summary</p>
                        <div className="cart-items-container">
                            {cartItems.map((item) => (
                                <div key={item.wooCartItemKey} className="cart-item">
                                    <img
                                        style={{ width: "100px" }}
                                        src={item.customizeItemPreview}
                                        alt="Item preview"
                                        className="cart-item-img"
                                    />
                                    <div className="cart-data-container">
                                        <p className="cart-item-name">{item.productDetails.name}</p>
                                        <p className="cart-item-data">
                                            <span className="cart-item-hd">Music: </span>No
                                        </p>
                                        <p className="cart-item-data">
                                            <span className="cart-item-hd">QR:</span>No
                                        </p>
                                        <p className="cart-item-data">
                                            <span className="cart-item-hd">Size: </span>Vertical (8.5x11)
                                        </p>
                                        <p className="cart-item-price">${item.productDetails.price}</p>
                                    </div>
                                    <div className="cart-icon-wrap">
                                        {/* <div
                                            className="cart-eye-icon"
                                            onClick={() => handlePreview(item.customizationData)}
                                        >
                                            <EyeIcon width={"1em"} height={"1em"} />
                                        </div> */}
                                        <div
                                            className="cart-edit-icon"
                                            onClick={() => {
                                                handleEdit(item);
                                            }}
                                        >
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </div>
                                        <div
                                            className="cart-delete-icon"
                                            onClick={() => {
                                                handleDelete(item.wooCartItemKey);
                                            }}
                                        >
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="cart-payment-container">
                        <p className="payment-hd">Payment Details</p>
                        <div className="cart-payment-form">
                            <div className="cart-input-wrap">
                                <AppForm total={totalPrice} products={cartProducts} />
                            </div>
                        </div>
                        {/* Coupon code and other components */}
                    </div>
                </div>
                {canvasData && <CardPreview canvasData={canvasData} enabled={!!canvasData} />}
            </div>
            <Footer />
        </>
    );
};

export default Cart;
