import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem("token-jwt");

    return isAuthenticated ? children : <Navigate to="/account-signup" />;
};

export default PrivateRoute;
