import { fabric } from "fabric";
import BGImageMini from '../assets/images/bg-mini-crop.png';
import BGImage from '../assets/images/png-qr-big.png';
// request
import productUtilsRequests from "../requests/productsUtils";
// utils
import { getUrlQuery } from "./url";


const fabricDefaultsOpts = {
    hasControls: false,
    hasBorders: false,
    hoverCursor: 'default',
    lockMovementX: true,
    lockMovementY: true,
    lockScalingY: true,
}

/**
 * 
 * @param {number} currentLeft 
 * @param {number} qrSize 
 * @param {number} actualPosterWidth 
 * @param {bool} isDownloadPage 
 * @returns {number}
 * function to determine the top and left (canvas object properties) adjustment based on
 * qr size and poster width, it only affects the qr on right side of poster 
 */
function _determineTopAndLeftAdjustment(currentTop, currentLeft, qrSize, currentPosterWidth, isDownloadPage) {
    let result = { adjustedTop: currentTop, adjustedLeft: currentLeft };
    if(!qrSize) return result;

    // adjust left
    if(currentLeft > (currentPosterWidth/2)){
        result.adjustedLeft = currentLeft - (qrSize * (isDownloadPage ? -0.15 : 0.25));
    }
    // adjust top
    if(currentTop > (currentPosterWidth/2)){
        result.adjustedTop = currentTop * (isDownloadPage ? 1.02 : 0.95);
    }

    return result;
}


/**
 * 
 * @param {fabric} currentTop
 * @param {bool} isDownloadPage
 */
function _adjustInsideQRTop(fabricQRImage, isDownloadPage) {
    if(isDownloadPage) fabricQRImage.top += 50;
    else fabricQRImage.top += 8;
}



/**
 * 
 * @param {string} imageUrl 
 * @param {number | null} size 
 * @returns {HTMLImageElement}
 */
function _getImageElmt(imageUrl, size = null) {
    return new Promise((resolve) => {
      const imgElmt = size ? new Image(size, size) : new Image();
      imgElmt.src = imageUrl;
      imgElmt.onload = () => {
        resolve(imgElmt);
      };
    });
}

async function _createQRGroup(fabricQRImage, size = null, posterWidth) {
    const currentHref = getUrlQuery(true);
    const isDownloadPage = currentHref.includes('download-files');

    const bg = isDownloadPage ? BGImage : BGImageMini;

    const imgElmt = await _getImageElmt(bg);
    const { left, top } = fabricQRImage;
    
    const fabricBG_QRImg = new fabric.Image(imgElmt, {
        label: 'QR Code Image Background',
        ...fabricDefaultsOpts
    });

    if(isDownloadPage){
        const scale = size / fabricBG_QRImg.width;
        fabricBG_QRImg.scale(scale + 0.1);
    }

    // create group
    const group = new fabric.Group([fabricBG_QRImg, fabricQRImage], {
        name: 'qr group',
        ...fabricDefaultsOpts,
        width: fabricBG_QRImg.width,
        height: fabricBG_QRImg.height
    });
    const centerPoint = fabricBG_QRImg.getCenterPoint();
    fabricQRImage.setPositionByOrigin(centerPoint, 'center', 'center');
    // this method adjust group width to fit his objects
    group.addWithUpdate();
    
    const { adjustedTop, adjustedLeft } = _determineTopAndLeftAdjustment(top, left, size, posterWidth, isDownloadPage)
    group.top = adjustedTop;
    group.left = adjustedLeft;

    _adjustInsideQRTop(fabricQRImage, isDownloadPage);

    return group;
}


async function createFabricQRImage({size, phoneNumber, left, top, pageName = 'detail', posterWidth = 800 }) {
    console.log('qr size is', size);
    let _size = size;
    if(_size){
        _size = pageName === 'detail' ? (_size * 0.9) : _size;
    }

    try {
        // get base64 qr code image with whatsapp logo
        const base64Image = await productUtilsRequests.getWSBase64QRCode({
            phoneNumber,
            size: _size
        });

        const qrImageElmt = await _getImageElmt(base64Image, _size + (_size * 0.08));

        const fabricQRImage = new fabric.Image(qrImageElmt, {
            left, 
            top,
            label: 'QR Code Image',
            ...fabricDefaultsOpts
        });

        // return fabricQRImage;
        const qrGroup = await _createQRGroup(fabricQRImage, _size, posterWidth);
        return qrGroup;
        
    } catch (err) {
        console.log("can't create QR Code");
        console.error(err);
        return false;
    }
}


export {
    createFabricQRImage
}