import React from 'react';
import './notFound.css';

const NotFound = () => {
    return (
        <div className='not-found-items-text'>
            <h1>It's not here <span><i>(yet!)</i></span> </h1>
            <p>We've got a lot in the pipeline and hope to make <br />this feature available in the very near future.</p>
        </div>
    );
}
 
export default NotFound;